import React from "react";
import type { ComponentProps } from "widgets/BaseComponent";
declare let Z: any;
declare let Zuora: any;

interface ZPaymentComponentProps extends ComponentProps {
  rsaData: Record<string, string>;
  pageId: string;
  accountId?: string;
  pageUrl: string;
  paymentGateway?: string;
  updateResponseData: (data: object) => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
  onLoadError?: () => void;
  onLoad?: () => void;
  loaded: boolean;
  hpmStyle: string;
  prepopulateFields?: Record<string, string>;
  hpmVersion?: string;
  publishableKey?: string;
  currency?: string;
  amount?: number;
  submitEnabled?: boolean;
}

function ZPaymentComponent(props: ZPaymentComponentProps) {
  const {
    accountId,
    amount,
    currency,
    hpmStyle,
    hpmVersion,
    loaded,
    pageId,
    pageUrl,
    paymentGateway,
    prepopulateFields,
    publishableKey,
    rsaData,
    submitEnabled,
    updateResponseData,
  } = props;

  const callback = (response: Record<string, string>) => {
    updateResponseData(response);
    if (response.success) {
      // Handle the response after the Payment Page is
      // successfully submitted.
      handleOnSubmitSuccess();
    } else {
      if (response.responseFrom == "Response_From_Submit_Page") {
        // Handle the error response when the Payment Page
        // submission failed.
        handleOnSubmitError();
      } else {
        // Handle the error response when the Payment Page
        // request failed.
        handleOnLoadError();
      }
    }
  };

  const handleOnSubmitSuccess = () => {
    if (props.onSubmitSuccess) {
      props.onSubmitSuccess();
    }
  };

  const handleOnSubmitError = () => {
    if (props.onSubmitError) {
      props.onSubmitError();
    }
  };

  const handleOnLoadError = () => {
    if (props.onLoadError) {
      props.onLoadError();
    }
  };

  const handleOnLoad = async () => {
    if (props.onLoad) {
      props.onLoad();
    }
  };

  const loadHpmIFrame = async () => {
    if (hpmVersion && hpmVersion === "3.0") {
      const zuora = Zuora(publishableKey);

      // Step 2 - Populate HPF configuration
      const configuration = {
        currency: currency,
        amount: amount,
        createPaymentSession: async () => {
          try {
            const response = await fetch("/api/v1/create-payment-session", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                currency: currency || "USD",
                amount: amount || 1,
              }),
            });

            if (response.ok) {
              const paymentSessionToken = await response.json();
              return paymentSessionToken;
            } else {
              throw new Error("Failed to create payment session.");
            }
          } catch (error) {
            throw error;
          }
        },
        onComplete: (result: any) => {
          updateResponseData(result);
          if (result.success) {
            handleOnSubmitSuccess();
          } else {
            handleOnSubmitError();
          }
        },
      };

      try {
        const form = await zuora.createPaymentForm(configuration);
        form.mount("#zuora-payment-form");
      } catch (error) {
        handleOnLoadError();
      }
    } else {
      const params = {
        tenantId: rsaData?.tenantId,
        id: pageId,
        token: rsaData?.token,
        signature: rsaData?.signature,
        key: rsaData?.key,
        field_accountId: accountId || "",
        url: pageUrl,
        paymentGateway: paymentGateway || "",
        style: hpmStyle || "inline",
        submitEnabled:
          submitEnabled === undefined || submitEnabled === true
            ? "true"
            : "false",
        callbackFunctionEnabled: submitEnabled === false,
      };

      Z.render(params, prepopulateFields, callback);
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      await handleOnLoad();
    }

    if (!props.isDisabled) {
      fetchData();
    }
  }, []);

  React.useEffect(() => {
    if (loaded) {
      const scriptElem = document.getElementById("zuora-hpm-script");
      const scriptElem3 = document.getElementById("zuora-hpm-script-3");
      if (hpmVersion === "3.0" && !scriptElem3) {
        const script = document.createElement("script");
        script.src = "https://js.zuora.com/payment/v3/zuora.js";
        script.id = "zuora-hpm-script-3";
        script.onload = async () => loadHpmIFrame();
        document.body.appendChild(script);
      } else if (!scriptElem) {
        const script = document.createElement("script");
        script.src =
          "https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js";
        script.id = "zuora-hpm-script";
        script.onload = async () => loadHpmIFrame();
        document.body.appendChild(script);
      } else {
        loadHpmIFrame();
      }
    }
  }, [loaded]);

  // return <div>{loaded && <div id="zuora_payment" />}</div>;
  return (
    <div>
      {loaded && (
        <>
          <div id="zuora-payment-form" />
          <div id="zuora_payment" />
        </>
      )}
    </div>
  );
}

export default ZPaymentComponent;
