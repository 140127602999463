import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useController } from "react-hook-form";

import Field from "widgets/ZJsonFormWidget/component/Field";
import FormContext from "../FormContext";
import useEvents from "./useBlurAndFocusEvents";
import useRegisterFieldValidity from "./useRegisterFieldValidity";
import type {
  BaseFieldComponentProps,
  ComponentDefaultValuesFnProps,
  FieldComponentBaseProps,
  FieldEventProps,
} from "../constants";
import { ActionUpdateDependency } from "../constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { lxDateFormatOptions } from "WidgetProvider/constants";
import { TimePrecision } from "widgets/DatePickerWidget2/constants";
import { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import { DateTimePicker } from "zds";
import { DateTime } from "luxon";

type DateComponentProps = FieldComponentBaseProps &
  FieldEventProps & {
    accentColor?: string;
    borderRadius?: string;
    boxShadow?: string;
    closeOnSelection: boolean;
    convertToISO: boolean;
    dateFormat: string;
    maxDate: string;
    minDate: string;
    onDateChange?: string;
    onDateSelected?: string;
    shortcuts: boolean;
    timePrecision: TimePrecision;
  };

type DateFieldProps = BaseFieldComponentProps<DateComponentProps>;

// const DEFAULT_PRIMARY_COLOR = Colors.GREEN;
const DEFAULT_BORDER_RADIUS = "0";

const COMPONENT_DEFAULT_VALUES = {
  closeOnSelection: false,
  convertToISO: false,
  dateFormat: "yyyy-mm-dd HH:mm",
  isDisabled: false,
  isRequired: false,
  isVisible: true,
  label: "",
  maxDate: "2121-12-31T18:29:00.000Z",
  minDate: "1920-12-31T18:30:00.000Z",
  shortcuts: false,
  timePrecision: TimePrecision.MINUTE,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
};

const componentDefaultValues = ({
  bindingTemplate,
  isCustomField,
  skipDefaultValueProcessing,
  sourceData,
  sourceDataPath,
}: ComponentDefaultValuesFnProps<string>): DateComponentProps => {
  let defaultValue;
  let dateFormat = COMPONENT_DEFAULT_VALUES.dateFormat;

  if (!isCustomField) {
    const format = lxDateFormatOptions.find(({ value: format }) => {
      return DateTime.fromFormat(sourceData, format).isValid;
    });

    if (format) {
      dateFormat = format.value;
    }

    if (sourceDataPath && !skipDefaultValueProcessing) {
      const { prefixTemplate, suffixTemplate } = bindingTemplate;
      const defaultValueString = `DateTime.fromFormat(${sourceDataPath}, "${dateFormat}").toISO()`;
      defaultValue = `${prefixTemplate}${defaultValueString}${suffixTemplate}`;
    }
  }

  return {
    ...COMPONENT_DEFAULT_VALUES,
    defaultValue,
    dateFormat,
  };
};

export const isValidType = (dateValue: string) =>
  lxDateFormatOptions.some(({ value: format }) => {
    return DateTime.fromFormat(dateValue, format).isValid;
  });

const isValid = (schemaItem: DateFieldProps["schemaItem"], value?: unknown) =>
  !schemaItem.isRequired ||
  (typeof value === "string" && Boolean(value?.trim()));

function DateField({
  fieldClassName,
  name,
  passedDefaultValue,
  schemaItem,
}: DateFieldProps) {
  const {
    fieldType,
    onBlur: onBlurDynamicString,
    onFocus: onFocusDynamicString,
  } = schemaItem;
  const { executeAction } = useContext(FormContext);

  const {
    field: { onBlur, onChange, value },
  } = useController({
    name,
  });

  const { inputRef } = useEvents<HTMLInputElement>({
    fieldBlurHandler: onBlur,
    onFocusDynamicString,
    onBlurDynamicString,
  });

  const isValueValid = isValid(schemaItem, value);
  const defaultDateValue = passedDefaultValue ?? schemaItem.defaultValue;

  useRegisterFieldValidity({
    isValid: isValueValid,
    fieldName: name,
    fieldType,
  });

  const onDateSelected = useCallback(
    (selectedValue: string) => {
      if (selectedValue) {
        // Selected value is in ISO Format.
        const selectedTs = DateTime.fromISO(selectedValue);

        if (schemaItem.convertToISO) {
          onChange(selectedTs);
        } else {
          // Parse the date using Luxon and format it according to the schemaItem.dateFormat
          const formattedDate = selectedTs.toFormat(schemaItem.dateFormat);
          onChange(formattedDate);
        }
      }

      if (schemaItem.onDateSelected && executeAction) {
        executeAction({
          triggerPropertyName: "onDateSelected",
          dynamicString: schemaItem.onDateSelected,
          event: {
            type: EventType.ON_DATE_SELECTED,
          },
          updateDependencyType: ActionUpdateDependency.ZFORM_DATA,
        });
      }
    },
    [
      executeAction,
      onChange,
      schemaItem.convertToISO,
      schemaItem.dateFormat,
      schemaItem.onDateSelected,
    ],
  );

  const valueInISOFormat = useMemo(() => {
    if (!isValueValid || typeof value !== "string") return "";

    if (DateTime.fromISO(value).isValid) {
      return value;
    }

    const valueInSelectedFormat = DateTime.fromFormat(
      value,
      schemaItem.dateFormat,
    );

    if (valueInSelectedFormat.isValid) {
      return valueInSelectedFormat.toISO();
    }

    return value;
  }, [value, schemaItem.dateFormat]);

  useEffect(() => {
    if (schemaItem.convertToISO && value !== valueInISOFormat) {
      onChange(valueInISOFormat);
    }

    if (!schemaItem.convertToISO && value && value === valueInISOFormat) {
      if (DateTime.fromISO(value).isValid) {
        onChange(DateTime.fromISO(value).toFormat(schemaItem.dateFormat));
      }
    }
  }, [schemaItem.convertToISO, value, valueInISOFormat, schemaItem.dateFormat]);

  const fieldComponent = useMemo(() => {
    return (
      <DateTimePicker
        disabled={schemaItem.isDisabled}
        dsOnBlur={onBlur}
        dsOnChange={(newValue: string | Date | DateTime | null) => {
          // Check the type of newValue and call toISO() if it's a DateTime instance
          if (newValue instanceof DateTime) {
            onDateSelected(newValue.toISO() || "");
          } else if (newValue instanceof Date) {
            onDateSelected(DateTime.fromJSDate(newValue).toISO() || "");
          } else if (typeof newValue === "string") {
            onDateSelected(DateTime.fromISO(newValue).toISO() || "");
          } else {
            // Handle null or invalid value cases by passing an empty string or default value
            onDateSelected(""); // Pass a fallback value like an empty string
          }
        }}
        dsOnClose={() => {}}
        e2e={fieldClassName}
        error={!isValueValid}
        format={schemaItem.dateFormat || COMPONENT_DEFAULT_VALUES.dateFormat}
        fullWidth
        inputSx={{
          backgroundColor: "white",
          boxShadow: schemaItem.boxShadow ?? "none",
          borderRadius: schemaItem.borderRadius ?? DEFAULT_BORDER_RADIUS,
        }}
        maxDate={schemaItem.maxDate}
        minDate={schemaItem.minDate}
        required={schemaItem.isRequired}
        value={valueInISOFormat || null}
      />
    );
  }, [
    schemaItem.accentColor,
    schemaItem.boxShadow,
    schemaItem.borderRadius,
    schemaItem.closeOnSelection,
    schemaItem.dateFormat,
    schemaItem.isDisabled,
    schemaItem.maxDate,
    onDateSelected,
    valueInISOFormat,
    schemaItem.shortcuts,
    schemaItem.timePrecision,
    inputRef,
    fieldClassName,
  ]);

  return (
    <Field
      accessor={schemaItem.accessor}
      defaultValue={defaultDateValue}
      fieldClassName={fieldClassName}
      isRequiredField={schemaItem.isRequired}
      label={schemaItem.label}
      labelStyle={schemaItem.labelStyle}
      labelTextColor={schemaItem.labelTextColor}
      labelTextSize={schemaItem.labelTextSize}
      name={name}
      tooltip={schemaItem.tooltip}
    >
      {fieldComponent}
    </Field>
  );
}

DateField.componentDefaultValues = componentDefaultValues;
DateField.isValidType = isValidType;

export default DateField;
