import React from "react";
import type { IconName } from "@blueprintjs/icons";
import type { BaseCellComponentProps } from "../Constants";
import { CellWrapper } from "../TableStyledWrappers";
import { IconButton } from "./IconButtonCell";

interface RenderIconButtonProps extends BaseCellComponentProps {
  isSelected: boolean;
  arrayItems: any[];
  rowIndex: number;
  onCommandClick: (dynamicTrigger: string, onComplete: () => void) => void;
}

export function IconArrayCell(props: RenderIconButtonProps) {
  const {
    allowCellWrapping,
    arrayItems,
    cellBackground,
    compactMode,
    fontStyle,
    horizontalAlignment,
    isCellDisabled,
    isCellVisible,
    isHidden,
    isSelected,
    onCommandClick,
    rowIndex,
    textColor,
    textSize,
    verticalAlignment,
  } = props;

  const getIsVisible = (rowIndex: number, action: any) => {
    return (
      (Array.isArray(action.isVisible) && action.isVisible[rowIndex]) ||
      (!Array.isArray(action.isVisible) && action.isVisible)
    );
  };

  const getDisabled = (rowIndex: number, action: any) => {
    return (
      (Array.isArray(action.isDisabled) && action.isDisabled[rowIndex]) ||
      (!Array.isArray(action.isDisabled) && action.isDisabled)
    );
  };

  return (
    <CellWrapper
      allowCellWrapping={allowCellWrapping}
      cellBackground={cellBackground}
      compactMode={compactMode}
      fontStyle={fontStyle}
      horizontalAlignment={horizontalAlignment}
      isCellDisabled={isCellDisabled}
      isCellVisible={isCellVisible}
      isHidden={isHidden}
      textColor={textColor}
      textSize={textSize}
      verticalAlignment={verticalAlignment}
    >
      {arrayItems.map((action: any, index: number) => {
        const isVisible = getIsVisible(rowIndex, action);
        const disable = getDisabled(rowIndex, action);

        return (
          isVisible && (
            <IconButton
              action={action}
              borderRadius={"15px"}
              boxShadow={"NONE"}
              buttonColor={action.iconColor}
              buttonVariant={action.buttonVariant}
              compactMode={"SHORT"}
              disabled={disable}
              iconName={action.iconName as IconName}
              isSelected={isSelected}
              key={index}
              onCommandClick={onCommandClick}
            />
          )
        );
      })}
    </CellWrapper>
  );
}
