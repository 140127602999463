import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import CodePreviewComponent from "../component";

import IconSVG from "../icon.svg";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { WIDGET_TAGS } from "../../../constants/WidgetConstants";
import ThumbnailSVG from "../zuora.svg";
import type { AutoLayoutConfig } from "../../../WidgetProvider/constants";

class CodePreviewWidget extends BaseWidget<
  CodePreviewWidgetProps,
  WidgetState
> {
  static type = "CODEPREVIEW_WIDGET";

  static getConfig() {
    return {
      name: "CodePreview",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.ZUORA],
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getDefaults() {
    return {
      widgetName: "CodePreview",
      rows: 1,
      columns: 6,
      version: 1,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "text",
            helpText: "Sets the text of the widget",
            label: "Text",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: { limitLineBreaks: true },
            },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "fontFamily",
            label: "Font family",
            helpText: "Controls the font family being used",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "System Default",
                value: "System Default",
              },
              {
                label: "Monospace",
                value: "monospace",
              },
              {
                label: "Sans Serif",
                value: "sans-serif",
              },
              {
                label: "Serif",
                value: "serif",
              },
              {
                label: "Cursive",
                value: "cursive",
              },
              {
                label: "Fantasy",
                value: "fantasy",
              },
              {
                label: "Open Sans",
                value: "Open Sans",
              },
            ],
            defaultValue: "System Default",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    return <CodePreviewComponent {...this.props} />;
  }
}

export interface CodePreviewWidgetProps extends WidgetProps {
  text?: string;
  label: string;
  fontFamily: string;
}

export default CodePreviewWidget;
