import React from "react";

import {
  Button,
  ButtonGroup,
  Toggle,
  Checkbox,
  Dropdown,
  DropdownItem,
  Icon,
  RadioGroup,
  Radio,
  Select,
  SelectItem,
  type AllIconsType,
} from "zds";
import type { GroupButton, HeaderActionItem, MenuItem } from "../constants";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem as MUIMenuItem,
  FormLabel,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { ButtonVariantTypes } from "../../ZButtonWidget/constants";
import { MenuItemsSource } from "../constants";
import { orderBy, sortBy } from "lodash";
import { FontStyleTypes } from "../../../constants/WidgetConstants";

interface PageHeaderActionsProps {
  actions?: Record<string, HeaderActionItem>;
  maxHeaderActionDisplay?: number;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
  groupButtonClickHandler?: (
    onClick: string | undefined,
    callback?: () => void,
  ) => void;
  isSmallSize?: boolean;
}

const getVisibleItems = (action: HeaderActionItem) => {
  const { configureMenuItems, menuItems, menuItemsSource, sourceData } = action;
  if (menuItemsSource === MenuItemsSource.STATIC) {
    const visibleItems = Object.keys(menuItems)
      .map((itemKey) => menuItems[itemKey])
      .filter((item) => item.isVisible === true);

    return orderBy(visibleItems, ["index"], ["asc"]);
  } else if (
    menuItemsSource === MenuItemsSource.DYNAMIC &&
    Array.isArray(sourceData) &&
    sourceData.length &&
    configureMenuItems?.config
  ) {
    const { config } = configureMenuItems;
    const getValue = (propertyName: keyof MenuItem, index: number) => {
      const value = config[propertyName];

      if (Array.isArray(value)) {
        return value[index];
      }

      return value ?? null;
    };

    const visibleItems = sourceData
      .map((item, index) => ({
        ...item,
        id: index.toString(),
        isVisible: getValue("isVisible", index),
        isDisabled: getValue("isDisabled", index),
        index: index,
        widgetId: "",
        label: getValue("label", index),
        onClick: config?.onClick,
        icon: getValue("icon", index),
        startIcon: getValue("startIcon", index),
        endIcon: getValue("endIcon", index),
      }))
      .filter((item) => item.isVisible === true);

    return visibleItems;
  }

  return [];
};

const getVisibleGroupButtonItems = (item: GroupButton) => {
  const { menuItems } = item;
  const visibleItems = Object.keys(menuItems)
    .map((itemKey) => menuItems[itemKey])
    .filter((item) => item.isVisible === true);

  return orderBy(visibleItems, ["index"], ["asc"]);
};

interface Option {
  label: string | undefined;
  value: any;
}

interface OptionProps {
  [key: string]: any;
}

const getOptions = (action: HeaderActionItem): Option[] => {
  let labels: (string | undefined)[] = [];
  let values: any[] = [];
  const sourceData = action.options || [];

  if (typeof action.optionLabel === "string") {
    labels = sourceData.map((d) => d[action.optionLabel as keyof OptionProps]);
  } else if (Array.isArray(action.optionLabel)) {
    labels = action.optionLabel;
  }

  if (typeof action.optionValue === "string") {
    values = sourceData.map((d) => d[action.optionValue as keyof OptionProps]);
  } else if (Array.isArray(action.optionValue)) {
    values = action.optionValue;
  }

  return sourceData.map((d, i) => ({
    label: labels[i],
    value: values[i],
  }));
};

const getVisibleGroupButton = (groupButtons: any) => {
  if (!groupButtons) return {};

  let items = Object.keys(groupButtons)
    .map((itemKey) => groupButtons[itemKey])
    .filter((item) => item.isVisible === true);
  // sort btns by index
  items = sortBy(items, ["index"]);
  return items;
};

const BUTTON_ACTION = (
  action: HeaderActionItem,
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void,
  isSmallSize?: boolean,
  onClickHandler?: () => void,
  label?: string,
  icon?: AllIconsType,
  buttonColor?: string,
  variant?: string,
  isDisabled?: boolean,
  startIcon?: AllIconsType,
  endIcon?: AllIconsType,
) => (
  <Button
    disabled={isDisabled ?? action.isDisabled}
    dsOnClick={onClickHandler ?? (() => buttonClickHandler(action.onClick))}
    endIcon={endIcon ?? action.endIcon}
    icon={icon ?? action.icon}
    {...(isSmallSize && { size: "small" })}
    startIcon={startIcon ?? action.startIcon}
    sx={{
      backgroundColor:
        ((variant ?? action.variant) === ButtonVariantTypes.CONTAINED ||
          (variant ?? action.variant) === undefined) &&
        !(icon ?? action.icon) &&
        !(isDisabled ?? action.isDisabled)
          ? `${buttonColor ?? action.buttonColor} !important`
          : undefined,
      color:
        (variant ?? action.variant) !== ButtonVariantTypes.CONTAINED &&
        (variant ?? action.variant) !== undefined
          ? buttonColor ?? action.buttonColor
          : undefined,
      borderColor:
        (variant ?? action.variant) !== ButtonVariantTypes.CONTAINED &&
        (variant ?? action.variant) !== undefined
          ? buttonColor ?? action.buttonColor
          : undefined,
      fontFamily: "inherit",
      fontWeight: 400,
      textTransform: "unset",
      ...(isSmallSize && {
        "& span.material-icons": {
          fontSize: "large !important",
        },
      }),
    }}
    tooltip={action.tooltip}
    variant={variant ?? action.variant}
  >
    {label ?? action.label}
  </Button>
);

const BUTTON_ACTION_WITH_DROPDOWN = (
  action: HeaderActionItem,
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void,
  isSmallSize: boolean,
  open: boolean,
  id: string,
  onClickHandler?: (event: any) => void,
  label?: string,
  icon?: AllIconsType,
  buttonColor?: string,
  variant?: string,
  isDisabled?: boolean,
  startIcon?: AllIconsType,
  endIcon?: AllIconsType,
  anchorEl?: any,
  dsOnClose?: () => void,
  visibleMenuItems?: MenuItem[],
) => (
  <>
    <Button
      a11yDescribedby={id}
      a11yHaspopup
      disabled={isDisabled ?? action.isDisabled}
      dsOnClick={onClickHandler ?? (() => buttonClickHandler(action.onClick))}
      endIcon={endIcon ?? action.endIcon}
      icon={icon ?? action.icon}
      startIcon={startIcon ?? action.startIcon}
      {...(isSmallSize && { size: "small" })}
      sx={{
        backgroundColor:
          ((variant ?? action.variant) === ButtonVariantTypes.CONTAINED ||
            (variant ?? action.variant) === undefined) &&
          !icon &&
          !(isDisabled ?? action.isDisabled)
            ? `${buttonColor ?? action.buttonColor} !important`
            : undefined,
        color:
          (variant ?? action.variant) !== ButtonVariantTypes.CONTAINED &&
          (variant ?? action.variant) !== undefined
            ? buttonColor ?? action.buttonColor
            : undefined,
        borderColor:
          (variant ?? action.variant) !== ButtonVariantTypes.CONTAINED &&
          (variant ?? action.variant) !== undefined
            ? buttonColor ?? action.buttonColor
            : undefined,
        fontFamily: "inherit",
        fontWeight: 400,
        textTransform: "unset",
        ...(isSmallSize && {
          "& span.material-icons": {
            fontSize: "large !important",
          },
        }),
      }}
      tooltip={action.tooltip}
      variant={variant ?? action.variant}
    >
      {label ?? action.label}
    </Button>
    <Dropdown
      a11yId={id}
      anchorEl={anchorEl}
      dsOnClose={dsOnClose}
      e2e="myDropdown"
      open={open}
    >
      {visibleMenuItems &&
        visibleMenuItems.map((item, index) => (
          <DropdownItem
            dsOnClick={() =>
              !item.isDisabled &&
              buttonClickHandler(item.onClick, action, index)
            }
            key={`menu-item-${action.label}-${index}`}
          >
            {item.startIcon && <Icon body={item.startIcon} />}
            {item.label}
            {item.endIcon && <Icon body={item.endIcon} />}
          </DropdownItem>
        ))}
    </Dropdown>
  </>
);

const Action = ({
  action,
  buttonClickHandler,
  groupButtonClickHandler,
  handleOnchange,
  isSmallSize = false,
}: {
  action: HeaderActionItem;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
  groupButtonClickHandler?: (
    onClick: string | undefined,
    callback?: () => void,
  ) => void;
  isSmallSize?: boolean;
}) => {
  const handleChange = (event: any, value: any) => {
    handleOnchange(action.onChange, `headerActions.${action.id}.value`, value);
  };

  const commonProps = {
    disabled: action.isDisabled,
    dsOnChange: (event: any, value: any) => handleChange(event, value),
    label: action.label,
    name: action.label,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const dsOnClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const styles = action.labelStyle?.split(",");
  const getCommonStyles = (
    action: HeaderActionItem,
    styles: string | string[] | undefined,
  ) => ({
    ".Mui-checked": {
      color: action.isDisabled ? undefined : `${action.buttonColor} !important`,
    },
    ".MuiFormControlLabel-label": {
      fontSize: action.labelTextSize
        ? `${action.labelTextSize} !important`
        : "14px",
      fontWeight: styles?.includes(FontStyleTypes.BOLD) ? "600" : "normal",
      fontStyle: styles?.includes(FontStyleTypes.ITALIC) ? "italic" : "",
    },
  });

  switch (action.actionType) {
    case "GROUP_BUTTONS":
      const groupButtons = getVisibleGroupButton(action.groupButtons);
      return (
        <Grid item>
          <ButtonGroup variant="split">
            {groupButtons &&
              groupButtonClickHandler &&
              Object.values(groupButtons).length > 0 &&
              Object.values(groupButtons).map((item) => {
                const groupButton = item as GroupButton;
                if (groupButton.buttonType === "SIMPLE") {
                  return BUTTON_ACTION(
                    action,
                    buttonClickHandler,
                    isSmallSize,
                    () => groupButtonClickHandler(groupButton.onClick),
                    groupButton.label,
                    groupButton.icon,
                    action.buttonColor,
                    action.variant,
                    groupButton.isDisabled,
                    groupButton.startIcon,
                    groupButton.endIcon,
                  );
                } else if (groupButton.buttonType === "MENU") {
                  const visibleMenuItems =
                    getVisibleGroupButtonItems(groupButton);
                  const id = open ? `menu-popover-${action.label}` : "";
                  return BUTTON_ACTION_WITH_DROPDOWN(
                    action,
                    buttonClickHandler,
                    isSmallSize,
                    open,
                    id,
                    handleClick,
                    groupButton.label,
                    groupButton.icon,
                    action.buttonColor,
                    action.variant,
                    groupButton.isDisabled,
                    groupButton.startIcon,
                    groupButton.endIcon,
                    anchorEl,
                    dsOnClose,
                    visibleMenuItems,
                  );
                }
              })}
          </ButtonGroup>
        </Grid>
      );
    case "CHECKBOX":
      return (
        <Grid item sx={getCommonStyles(action, styles)}>
          <Checkbox
            checked={action.value}
            value={action.value}
            {...commonProps}
          />
        </Grid>
      );
    case "MENU":
      const visibleMenuItems = getVisibleItems(action);
      const id = open ? `menu-popover-${action.label}` : "";
      return (
        <Grid item>
          {BUTTON_ACTION_WITH_DROPDOWN(
            action,
            buttonClickHandler,
            isSmallSize,
            open,
            id,
            handleClick,
            action.label,
            action.icon,
            action.buttonColor,
            action.variant,
            action.isDisabled,
            action.startIcon,
            action.endIcon,
            anchorEl,
            dsOnClose,
            visibleMenuItems,
          )}
        </Grid>
      );
    case "RADIO":
      return (
        <Grid
          item
          sx={{
            ".Mui-checked": {
              color: action.isDisabled
                ? undefined
                : `${action.buttonColor} !important`,
            },
          }}
        >
          <RadioGroup
            row
            value={action.value || action.defaultSelectedOption}
            {...commonProps}
          >
            {action.options &&
              action.options.length > 0 &&
              action.options.map((option) => (
                <Radio
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              ))}
          </RadioGroup>
        </Grid>
      );
    case "SELECT":
      const options = getOptions(action);
      return (
        <Grid item>
          <Grid
            alignItems="center"
            alignSelf="flex-start"
            columnSpacing={1}
            container
            justifyContent="flex-start"
          >
            <Grid item>
              <FormLabel
                sx={{
                  fontWeight: styles?.includes(FontStyleTypes.BOLD)
                    ? "600"
                    : "normal",
                  marginBottom: "unset",
                  fontStyle: styles?.includes(FontStyleTypes.ITALIC)
                    ? "italic"
                    : "",
                  fontSize: action.labelTextSize,
                }}
              >
                {action.label}:
              </FormLabel>
            </Grid>
            <Grid item sx={{ marginTop: "-5px" }}>
              <Select
                disabled={action.isDisabled}
                dsOnChange={(event) => handleChange(event, event.target.value)}
                sx={{
                  ".MuiSelect-select": {
                    padding: "8px 10px 6px",
                    width: `${action.selectWidth || 100}px`,
                    lineHeight: "24px",
                  },
                  boxShadow: action.boxShadow,
                  borderRadius: action.borderRadius,
                }}
                value={action.value || action.defaultSelectedOption || ""}
              >
                {options.length > 0 &&
                  options.map((option, index) => (
                    <SelectItem
                      key={`${option.value}-${index}`}
                      value={option.value}
                    >
                      {option.label}
                    </SelectItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      );
    case "TOGGLE":
      return (
        <Grid item sx={getCommonStyles(action, styles)}>
          <Toggle
            checked={action.value}
            {...commonProps}
            labelPlacement={action.labelPlacement}
          />
        </Grid>
      );
    case "BUTTON":
    default:
      return (
        <Grid item>
          {BUTTON_ACTION(action, buttonClickHandler, isSmallSize)}
        </Grid>
      );
  }
};

export const PageHeaderActions = ({
  actions = {},
  buttonClickHandler,
  groupButtonClickHandler,
  handleOnchange,
  isSmallSize = false,
  maxHeaderActionDisplay = 2,
}: PageHeaderActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      alignItems="center"
      alignSelf="flex-start"
      columnSpacing={isSmallSize ? 1 : 1.5}
      container
      item
      justifyContent="flex-end"
      marginTop={
        Object.values(actions).length > maxHeaderActionDisplay ? "0px" : "2px"
      }
      // sx={{ fontFamily: "inherit" }}
      xs="auto"
    >
      {Object.values(actions)
        .slice(0, maxHeaderActionDisplay)
        .map((action, index) => (
          <Action
            action={action}
            buttonClickHandler={buttonClickHandler}
            groupButtonClickHandler={groupButtonClickHandler}
            handleOnchange={handleOnchange}
            isSmallSize={isSmallSize}
            key={`headeraction-${index}`}
          />
        ))}
      {Object.values(actions).length > maxHeaderActionDisplay && (
        <Grid item sx={{ ".MuiButtonBase-root": { padding: "6px" } }}>
          <IconButton
            aria-controls="long-menu"
            aria-haspopup="true"
            {...(isSmallSize && { size: "small" })}
            aria-label="more-actions"
            // data-e2e={e2e ? `${e2e}-more-button` : undefined}
            onClick={handleClick}
            sx={{
              ...(isSmallSize && {
                "& .MuiSvgIcon-root": {
                  fontSize: "large",
                },
              }),
            }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            // data-e2e={e2e ? `${e2e}-more-menu` : undefined}
            id="long-menu"
            keepMounted
            onClose={handleClose}
            open={open}
          >
            {actions &&
              Object.values(actions).map(
                (action, index) =>
                  index >= maxHeaderActionDisplay && (
                    <MUIMenuItem
                      data-e2e={action.id}
                      disabled={action.isDisabled}
                      divider={action.divider}
                      // href={action.href}
                      key={action.id}
                      onClick={() => buttonClickHandler(action.onClick)}
                      selected={false}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      {action.actionType === "BUTTON" && action.startIcon && (
                        <Icon body={action.startIcon} />
                      )}
                      {action.actionType === "BUTTON" && action.label}
                      {action.actionType === "BUTTON" && action.endIcon && (
                        <Icon body={action.endIcon} />
                      )}
                      {action.actionType === "TOGGLE" && (
                        <div>
                          <Toggle
                            checked={action.value}
                            disabled={action.isDisabled}
                            dsOnChange={(event, value) => {
                              handleOnchange(
                                action.onChange,
                                `headerActions.${action.id}.value`,
                                value,
                              );
                            }}
                            label={action.label}
                          />
                        </div>
                      )}
                      {action.actionType === "CHECKBOX" && (
                        <div>
                          <Checkbox
                            checked={action.value}
                            disabled={action.isDisabled}
                            dsOnChange={(event, value) => {
                              handleOnchange(
                                action.onChange,
                                `headerActions.${action.id}.value`,
                                value,
                              );
                            }}
                            label={action.label}
                          />
                        </div>
                      )}
                      {action.actionType === "RADIO" && (
                        <div>
                          <RadioGroup
                            disabled={action.isDisabled}
                            dsOnChange={(event, value) => {
                              handleOnchange(
                                action.onChange,
                                `headerActions.${action.id}.value`,
                                value,
                              );
                            }}
                            label={action.label}
                            value={action.value || action.defaultSelectedOption}
                          >
                            {action.options &&
                              action.options.length > 0 &&
                              action.options.map((option) => (
                                <Radio
                                  key={option.value}
                                  label={option.label}
                                  value={option.value}
                                />
                              ))}
                          </RadioGroup>
                        </div>
                      )}
                      {action.actionType === "SELECT" && (
                        <Select
                          dsOnChange={(event) => {
                            handleOnchange(
                              action.onChange,
                              `headerActions.${action.id}.value`,
                              event.target.value,
                            );
                          }}
                          label={action.label}
                          sx={{
                            ".MuiSelect-select": {
                              padding: "6px 10px",
                              width: `${action.selectWidth || 100}px`,
                            },
                            boxShadow: action.boxShadow,
                            borderRadius: action.borderRadius,
                          }}
                          value={
                            action.value || action.defaultSelectedOption || ""
                          }
                        >
                          {action.options &&
                            getOptions(action).length > 0 &&
                            getOptions(action).map((option, index) => (
                              <SelectItem
                                key={`${option.value}-${index}`}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                        </Select>
                      )}
                    </MUIMenuItem>
                  ),
              )}
          </Menu>
        </Grid>
      )}
    </Grid>
  );
};
