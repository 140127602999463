import type { PageHeaderWidgetProps } from "../../constants";
import { MenuItemsSource } from "../../constants";
import _, { get, isArray, isPlainObject, isString, uniq } from "lodash";
import type { LoDashStatic } from "lodash";

import { getBasePropertyPath } from "../../../TableWidget/widget/propertyUtils";
import type { WidgetProps } from "../../../BaseWidget";

export const updateMenuItemsSource = (
  props: PageHeaderWidgetProps<WidgetProps>,
  propertyPath: string,
  propertyValue: unknown,
): Array<{ propertyPath: string; propertyValue: unknown }> | undefined => {
  const propertiesToUpdate: Array<{
    propertyPath: string;
    propertyValue: unknown;
  }> = [];

  const isMenuItemsSourceChangedFromStaticToDynamic =
    _.get(props, propertyPath, "") === MenuItemsSource.STATIC &&
    propertyValue === MenuItemsSource.DYNAMIC;

  if (isMenuItemsSourceChangedFromStaticToDynamic) {
    const property = getBasePropertyPath(propertyPath);
    if (!_.get(props, `${property}.sourceData`, undefined)) {
      propertiesToUpdate.push({
        propertyPath: `${property}.sourceData`,
        propertyValue: [],
      });
    }

    if (!_.get(props, `${property}.configureMenuItems`, undefined)) {
      propertiesToUpdate.push({
        propertyPath: `${property}.configureMenuItems`,
        propertyValue: {
          label: "Configure menu items",
          id: "config",
          config: {
            id: "config",
            label: "Menu Item",
            isVisible: true,
            isDisabled: false,
          },
        },
      });
    }
  }

  return propertiesToUpdate?.length ? propertiesToUpdate : undefined;
};

export const hiddenForMenuActionOnly = (
  props: PageHeaderWidgetProps<WidgetProps>,
  propertyPath: string,
) => {
  const actionType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.actionType`,
    "",
  );
  return ["BUTTON", "TOGGLE", "CHECKBOX", "RADIO", "SELECT"].includes(
    actionType,
  );
};

export const hiddenNotMenuActionDynamic = (
  props: PageHeaderWidgetProps<WidgetProps>,
  propertyPath: string,
) => {
  const actionType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.actionType`,
    "",
  );
  const menuType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.menuItemsSource`,
    "",
  );
  // const sourceData = get(
  //   props,
  //   `${propertyPath.split(".", 2).join(".")}.sourceData`,
  //   undefined,
  // );
  return (
    ["BUTTON", "TOGGLE", "CHECKBOX", "RADIO", "SELECT"].includes(actionType) ||
    menuType === MenuItemsSource.STATIC
  );
};

export const hiddenNotMenuActionStatic = (
  props: PageHeaderWidgetProps<WidgetProps>,
  propertyPath: string,
) => {
  const actionType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.actionType`,
    "",
  );
  const menuType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.menuItemsSource`,
    "",
  );
  // const sourceData = get(
  //   props,
  //   `${propertyPath.split(".", 2).join(".")}.sourceData`,
  //   undefined,
  // );
  return (
    ["BUTTON", "TOGGLE", "CHECKBOX", "RADIO", "SELECT"].includes(actionType) ||
    menuType === MenuItemsSource.DYNAMIC
  );
};

export const hiddenExceptSelect = (
  props: PageHeaderWidgetProps<WidgetProps>,
  propertyPath: string,
) => {
  const actionType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.actionType`,
    "",
  );

  return ["BUTTON", "TOGGLE", "CHECKBOX", "RADIO", "MENU"].includes(actionType);
};

export const updateHookOnActionTypeChange = (
  props: any,
  propertyPath: string,
  propertyValue: any,
) => {
  const propertiesToUpdate = [{ propertyPath, propertyValue }];
  const property = getBasePropertyPath(propertyPath);
  if (propertyValue === "TOGGLE" || propertyValue === "CHECKBOX") {
    propertiesToUpdate.push({
      propertyPath: `${property}.value`,
      propertyValue: true,
    });
  }
  if (propertyValue === "RADIO" || propertyValue === "SELECT") {
    propertiesToUpdate.push({
      propertyPath: `${property}.options`,
      propertyValue: [
        { label: "Blue", value: "BLUE" },
        { label: "Green", value: "GREEN" },
        { label: "Red", value: "RED" },
      ],
    });
    propertiesToUpdate.push({
      propertyPath: `${property}.defaultSelectedOption`,
      propertyValue: "BLUE",
    });
  }
  return propertiesToUpdate;
};

// export const getDefaultValueExpressionSuffix = (
//   widget: PageHeaderWidgetProps<WidgetProps>,
//   propertyPath: string,
// ) => {
//   const property = getBasePropertyPath(propertyPath);
//   return `))(${property}.options, ${property}.serverSideFiltering) }}`;
// };

export const getOptionLabelValueExpressionPrefix = (
  widget: PageHeaderWidgetProps<WidgetProps>,
  propertyPath: string,
) => {
  const property = getBasePropertyPath(propertyPath);
  return `{{${property}.options.map((item) => (`;
};

export const optionLabelValueExpressionSuffix = `))}}`;

export const getLabelValueKeyOptions = (
  widget: PageHeaderWidgetProps<WidgetProps>,
) => {
  const key = Object.keys(
    get(widget, "__evaluation__.evaluatedValues.headerActions", {}),
  )[0];
  const sourceData = get(widget, `headerActions.${key}.options`);

  let parsedValue: Record<string, unknown> | undefined = sourceData;

  if (isString(sourceData)) {
    try {
      parsedValue = JSON.parse(sourceData);
    } catch (e) {}
  }

  if (isArray(parsedValue)) {
    return uniq(
      parsedValue.reduce((keys, obj) => {
        if (isPlainObject(obj)) {
          Object.keys(obj).forEach((d) => keys.push(d));
        }

        return keys;
      }, []),
    ).map((d: unknown) => ({
      label: d,
      value: d,
    }));
  } else {
    return [];
  }
};

export const getLabelValueAdditionalAutocompleteData = (
  props: PageHeaderWidgetProps<WidgetProps>,
) => {
  const keys = getLabelValueKeyOptions(props);

  return {
    item: keys
      .map((d) => d.label)
      .reduce((prev: Record<string, string>, curr: unknown) => {
        prev[curr as string] = "";

        return prev;
      }, {}),
  };
};

export const labelKeyValidation = (
  value: unknown,
  props: PageHeaderWidgetProps<WidgetProps>,
  _: LoDashStatic,
) => {
  /*
   * Validation rules
   *  1. Can be a string.
   *  2. Can be an Array of string, number, boolean (only for option Value).
   */

  if (value === "" || _.isNil(value)) {
    return {
      parsed: "",
      isValid: false,
      messages: [
        {
          name: "ValidationError",
          message: `value does not evaluate to type: string | Array<string>`,
        },
      ],
    };
  }

  if (_.isString(value)) {
    return {
      parsed: value,
      isValid: true,
      messages: [],
    };
  } else if (_.isArray(value)) {
    const errorIndex = value.findIndex((d) => !_.isString(d));

    return {
      parsed: errorIndex === -1 ? value : [],
      isValid: errorIndex === -1,
      messages:
        errorIndex !== -1
          ? [
              {
                name: "ValidationError",
                message: `Invalid entry at index: ${errorIndex}. This value does not evaluate to type: string`,
              },
            ]
          : [],
    };
  } else {
    return {
      parsed: "",
      isValid: false,
      messages: [
        {
          name: "ValidationError",
          message: "value does not evaluate to type: string | Array<string>",
        },
      ],
    };
  }
};

export const valueKeyValidation = (
  value: unknown,
  props: PageHeaderWidgetProps<WidgetProps>,
  _: LoDashStatic,
) => {
  /*
   * Validation rules
   *  1. Can be a string.
   *  2. Can be an Array of string, number, boolean (only for option Value).
   *  3. should be unique.
   */

  if (value === "" || _.isNil(value)) {
    return {
      parsed: "",
      isValid: false,
      messages: [
        {
          name: "ValidationError",
          message: `value does not evaluate to type: string | Array<string| number | boolean>`,
        },
      ],
    };
  }

  let options: unknown[] = [];

  if (_.isString(value)) {
    return {
      parsed: value,
      isValid: true,
      messages: [],
    };
    // console.log("my props", props);
    // const key = Object.keys(
    //   get(props, "__evaluation__.evaluatedValues.headerActions", {}),
    // )[0];
    // const actionOptions = get(props, `headerActions.${key}.options`);
    // console.log("actionOptions", actionOptions);
    // // const sourceData = _.isArray(actionOptions) ? actionOptions : [];
    // const sourceData = _.isArray(props.sourceData) ? props.sourceData : [];
    //
    // const keys = sourceData.reduce((keys, curr) => {
    //   Object.keys(curr).forEach((d) => keys.add(d));
    //
    //   return keys;
    // }, new Set());
    //
    // if (!keys.has(value)) {
    //   return {
    //     parsed: value,
    //     isValid: false,
    //     messages: [
    //       {
    //         name: "ValidationError",
    //         message: `value key should be present in the source data`,
    //       },
    //     ],
    //   };
    // }
    //
    // options = sourceData.map((d: Record<string, unknown>) => d[value]);
  } else if (_.isArray(value)) {
    const errorIndex = value.findIndex(
      (d) =>
        !(_.isString(d) || (_.isNumber(d) && !_.isNaN(d)) || _.isBoolean(d)),
    );

    if (errorIndex !== -1) {
      return {
        parsed: [],
        isValid: false,
        messages: [
          {
            name: "ValidationError",
            message: `Invalid entry at index: ${errorIndex}. This value does not evaluate to type: string | number | boolean`,
          },
        ],
      };
    } else {
      options = value;
    }
  } else {
    return {
      parsed: "",
      isValid: false,
      messages: [
        {
          name: "ValidationError",
          message:
            "value does not evaluate to type: string | Array<string | number | boolean>",
        },
      ],
    };
  }

  const isValid = options.every(
    (d: unknown, i: number, arr: unknown[]) => arr.indexOf(d) === i,
  );

  return {
    parsed: value,
    isValid: isValid,
    messages: isValid
      ? []
      : [
          {
            name: "ValidationError",
            message: "Duplicate values found, value must be unique",
          },
        ],
  };
};
