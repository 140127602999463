import React, { useEffect, useRef } from "react";
import {
  SortableList,
  draggableSortableList,
  SortableListItem,
} from "@zds3/zds-components";
import type { ComponentProps } from "../../BaseComponent";

if (window.customElements.get("z-sortable-list") === undefined) {
  window.customElements.define("z-sortable-list", SortableList);
}
if (window.customElements.get("z-sortable-list-item") === undefined) {
  window.customElements.define("z-sortable-list-item", SortableListItem);
}

function SortableListComponent(props: SortableListComponentProps) {
  const listRef = useRef<HTMLDivElement>(null);

  const handleSorted = (event: any) => {
    // eslint-disable-next-line no-console
    console.log("Received sortableListEvent:", event.detail);
  };

  useEffect(() => {
    draggableSortableList();

    if (listRef && listRef.current) {
      listRef.current.addEventListener("sortableListEvent", handleSorted);
    }

    return () => {
      if (listRef && listRef.current) {
        listRef.current.removeEventListener("sortableListEvent", handleSorted);
      }
    };
  }, []);

  const getSortableItems = (items: Item[]) => {
    return (
      <>
        {items.map((item, index) => {
          const { flagNew, group, label } = item;
          return (
            <div
              key={label + index}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <z-sortable-list-item
                data-flag-new={flagNew ? "true" : undefined}
                data-group={group ? group : undefined}
                data-label={label}
                key={index}
              />
            </div>
          );
        })}
      </>
    );
  };

  const handleMouseDown = () => {
    props.disableDrag(true);
  };

  const handleMouseUp = () => {
    props.disableDrag(false);
  };

  return (
    <z-sortable-list id="z-list-1" ref={listRef}>
      {getSortableItems(props.items)}
    </z-sortable-list>
  );
}

interface SortableListComponentProps extends ComponentProps {
  items: Item[];
  disableDrag: (disabled: boolean) => void;
}

interface Item {
  label: string;
  flagNew?: boolean;
  group?: string;
}

export default SortableListComponent;
