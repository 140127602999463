import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { Button } from "zds";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled, { createGlobalStyle } from "styled-components";
import type { AllIconsType } from "zds";
import { ButtonVariantTypes } from "../constants";

import type { ButtonProps as MuiButtonProps } from "@mui/material";
import Interweave from "interweave";
import { Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import {
  EventType,
  type ExecutionResult,
} from "constants/AppsmithActionConstants/ActionConstants";
import type {
  AutoLayoutConfig,
  PropertyUpdates,
  SnipingModeProperty,
} from "../../../WidgetProvider/constants";
import type { Stylesheet } from "../../../entities/AppTheming";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;

const ToolTipWrapper = styled.div`
  height: 100%;
  && .bp3-popover2-target {
    height: 100%;
    width: 100%;
    & > div {
      height: 100%;
    }
  }
`;

const TooltipStyles = createGlobalStyle`
  .btnTooltipContainer {
    .bp3-popover2-content {
      max-width: 350px;
      overflow-wrap: anywhere;
      padding: 10px 12px;
      border-radius: 0px;
    }
  }
`;

class ZButtonWidget extends BaseWidget<ZButtonWidgetProps, WidgetState> {
  constructor(props: ZButtonWidgetProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  static type = "ZBUTTON_WIDGET";

  static getConfig() {
    return {
      name: "ZButton", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: true, // Defines if this widget has a canvas within in which we can drop other widgets
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getMethods() {
    return {
      getSnipingModeUpdates: (
        propValueMap: SnipingModeProperty,
      ): PropertyUpdates[] => {
        return [
          {
            propertyPath: "onClick",
            propertyValue: propValueMap.run,
            isDynamicPropertyPath: true,
          },
        ];
      },
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZButton",
      rows: 2,
      columns: 15,
      version: 1,
      label: "Submit",
      variant: "contained",
      isDisabled: false,
      isVisible: true,
      disabledWhenInvalid: false,
      resetFormOnClick: false,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "startIcon",
            label: "StartIcon",
            controlType: "ZICON_SELECT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "endIcon",
            label: "EndIcon",
            controlType: "ZICON_SELECT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "icon",
            label: "Icon",
            controlType: "ZICON_SELECT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "tooltip",
            label: "Tooltip",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isDisabled",
            label: "Disabled",
            controlType: "SWITCH",
            helpText: "Disables clicks to this widget",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            propertyName: "onClick",
            label: "onClick",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },

      {
        sectionName: "Form settings",
        children: [
          {
            helpText:
              "Disabled if the form is invalid, if this widget exists directly within a Form widget.",
            propertyName: "disabledWhenInvalid",
            label: "Disabled invalid forms",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText:
              "Resets the fields of the form, on click, if this widget exists directly within a Form widget.",
            propertyName: "resetFormOnClick",
            label: "Reset form on success",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "variant",
            label: "Variant",
            controlType: "ICON_TABS",
            defaultValue: ButtonVariantTypes.CONTAINED,
            fullWidth: true,
            helpText: "Sets the variant of the icon button",
            options: [
              {
                label: "Contained",
                value: ButtonVariantTypes.CONTAINED,
              },
              // {
              //   label: "Outlined",
              //   value: ButtonVariantTypes.OUTLINED,
              // },
              {
                label: "Tertiary",
                value: ButtonVariantTypes.TERTIARY,
              },
              {
                label: "Text",
                value: ButtonVariantTypes.TEXT,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: [
                  ButtonVariantTypes.CONTAINED,
                  // ButtonVariantTypes.OUTLINED,
                  ButtonVariantTypes.TERTIARY,
                  ButtonVariantTypes.TEXT,
                ],
                default: ButtonVariantTypes.CONTAINED,
              },
            },
          },
        ],
      },
      {
        sectionName: "Color",
        children: [
          {
            propertyName: "buttonColor",
            helpText: "Changes the color of the button",
            label: "Button color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "Border and shadow",
        children: [
          {
            propertyName: "borderRadius",
            label: "Border radius",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "boxShadow",
            label: "Box shadow",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      buttonColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  onButtonClick = () => {
    if (this.props.onClick) {
      this.setState({
        isLoading: true,
      });
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onClick,
        event: {
          type: EventType.ON_CLICK,
          callback: this.handleActionComplete,
        },
      });
    } else if (this.props.resetFormOnClick && this.props.onReset) {
      this.props.onReset();
    }
  };

  handleActionComplete = (result: ExecutionResult) => {
    this.setState({
      isLoading: false,
    });
    if (result.success) {
      if (this.props.resetFormOnClick && this.props.onReset)
        this.props.onReset();
    }
  };

  hasOnClickAction = () => {
    const { isDisabled, onClick, onReset, resetFormOnClick } = this.props;
    return Boolean((onClick || onReset || resetFormOnClick) && !isDisabled);
  };

  getWidgetView() {
    const ZButtonWidgetProps = {
      variant: this.props.variant,
      startIcon: this.props.startIcon,
      endIcon: this.props.endIcon,
      icon: this.props.icon,
      dsOnClick: this.hasOnClickAction() ? this.onButtonClick : undefined,
      sx: {
        backgroundColor:
          this.props.variant === ButtonVariantTypes.CONTAINED &&
          !this.props.icon
            ? this.props.buttonColor
            : undefined,

        color:
          this.props.variant !== ButtonVariantTypes.CONTAINED
            ? this.props.buttonColor
            : undefined,

        borderColor:
          this.props.variant !== ButtonVariantTypes.CONTAINED
            ? this.props.buttonColor
            : undefined,

        borderRadius: this.props.icon ? undefined : this.props.borderRadius,

        boxShadow: this.props.boxShadow,

        fontFamily: "inherit",
        fontWeight: 400,
        textTransform: "unset",
      },
    };

    const btnWrapper = (
      <ContainerWrapper>
        <Button {...ZButtonWidgetProps}>{this.props.label}</Button>
      </ContainerWrapper>
    );

    if (this.props.tooltip) {
      return (
        <ToolTipWrapper>
          <TooltipStyles />
          <Popover2
            autoFocus={false}
            content={<Interweave content={this.props.tooltip} />}
            hoverOpenDelay={200}
            interactionKind="hover"
            portalClassName="btnTooltipContainer"
            position={Position.TOP}
          >
            {btnWrapper}
          </Popover2>
        </ToolTipWrapper>
      );
    } else {
      return btnWrapper;
    }
  }
}

export interface ZButtonWidgetProps extends WidgetProps {
  dsOnClick?: string;
  startIcon: AllIconsType;
  endIcon: AllIconsType;
  icon: AllIconsType;
  tooltip: string;
  variant: MuiButtonProps["variant"] | "tertiary";
  autoFocus: boolean;
  label: string;
}

export default ZButtonWidget;
