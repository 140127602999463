import * as React from "react";
import { Typography, Chip, type ChipProps, Tab, Tabs } from "zds";
import Logo from "./Logo";
import { Divider, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { PageHeaderActions } from "widgets/PageHeaderWidget/component/PageHeaderActions";
import type { HeaderActionItem } from "widgets/PageHeaderWidget/constants";
import styled from "styled-components";
import { scrollCSS } from "../../WidgetUtils";
import { generateClassName, getCanvasClassName } from "utils/generators";
import type { ReactNode } from "react";

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;

const Container = styled.div`
  margin-left: -6px;
`;
export const BUILDER_HEADER_ID = "zds-builder-header";
const BUILDER_HEADER_HEIGHT = "55px";

export interface BuilderHeaderProps {
  closeAction?: Record<string, HeaderActionItem>;
  actions?: Record<string, HeaderActionItem>;
  title: string;
  description?: string | React.ReactNode;
  logo?: React.ReactNode;
  position?: React.CSSProperties["position"];
  href?: string;
  e2e?: string;
  /** This slots directly next to the "Zuora" logo to the left of the "title" and "description" */
  appName?: string;
  statusChip?: ChipProps;
  handleCloseClick: (onClick: string | undefined) => void;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
  children?: ReactNode;
  shouldScrollContents?: boolean;
  $noScroll: boolean;
  widgetId: string;
  tabs?: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
  }>;
  onTabChange: (activeTab: string) => void;
  activeTab: string;
}

const BuilderHeader: React.FC<BuilderHeaderProps> = ({
  $noScroll,
  actions,
  activeTab,
  appName,
  buttonClickHandler,
  children,
  closeAction,
  description,
  handleCloseClick,
  handleOnchange,
  href,
  logo,
  onTabChange,
  position = "fixed",
  shouldScrollContents,
  statusChip,
  tabs,
  title,
  widgetId,
}: BuilderHeaderProps) => {
  const AppNameSection = () => {
    if (!appName) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      <>
        <Typography body={appName} color={"#ffffff"} variant="subtitle2" />
        <Divider
          flexItem
          orientation="vertical"
          sx={{ borderColor: "rgba(255, 255, 255, 0.46)" }}
          variant="middle"
        />
      </>
    );
  };

  return (
    <Container>
      <Box
        id={BUILDER_HEADER_ID}
        sx={{
          position: position,
          zIndex: (theme) => theme.zIndex.modal - 1,
          backgroundColor: "#041B2E",
          minHeight: `${BUILDER_HEADER_HEIGHT} !important`,
          width: "100%",
        }}
      >
        <Grid alignItems="center" container spacing={3} whiteSpace="nowrap">
          <Grid item xs zeroMinWidth>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                height: BUILDER_HEADER_HEIGHT,
                paddingLeft: 1,
              }}
            >
              {closeAction && (
                <PageHeaderActions
                  actions={closeAction}
                  buttonClickHandler={handleCloseClick}
                  handleOnchange={() => {}}
                />
              )}

              <a href={href} style={{ display: "flex" }}>
                {logo ?? <Logo />}
              </a>

              <AppNameSection />
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {title && (
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        body={title}
                        color={"#ffffff"}
                        noWrap
                        variant="subtitle2"
                      />
                    </Box>
                  )}
                </Box>
                {description && (
                  <Box sx={{ width: "100%", display: "flex" }}>
                    <Typography
                      body={description}
                      color={"#ffffff"}
                      noWrap
                      variant="caption"
                    />
                  </Box>
                )}
              </Box>
              {statusChip && <Chip {...statusChip} />}
            </Box>
          </Grid>

          {!!tabs && tabs.length > 0 && (
            <Grid
              item
              sx={{
                "& .MuiButtonBase-root": { color: "#ffffff" },
                "& .Mui-selected": {
                  color: "rgb(133, 251, 237) !important",
                },
                "& .MuiButtonBase-root:hover": {
                  color: "rgb(133, 251, 237) !important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "rgb(133, 251, 237)",
                },
              }}
              xs="auto"
            >
              <Tabs
                a11yLabel="ZTabs"
                dsOnChange={(event, value) => onTabChange(value)}
                e2e="ZTabs"
                value={activeTab}
              >
                {tabs.map((tab) => (
                  <Tab
                    aria-controls={tab.widgetId}
                    e2e={tab.widgetId}
                    id={tab.widgetId}
                    key={tab.widgetId}
                    label={tab.label}
                    value={tab.label}
                  />
                ))}
              </Tabs>
            </Grid>
          )}

          {!!actions && (
            <Grid
              item
              sx={{
                paddingRight: 2,
                "& [aria-label='more-actions']": { color: "#ffffff" },
              }}
              xs
            >
              <PageHeaderActions
                actions={actions}
                buttonClickHandler={buttonClickHandler}
                handleOnchange={handleOnchange}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <ScrollCanvas
        $shouldScrollContents={!!shouldScrollContents && !$noScroll}
        className={`${
          shouldScrollContents ? getCanvasClassName() : ""
        } ${generateClassName(widgetId)}`}
      >
        {children}
      </ScrollCanvas>
    </Container>
  );
};

export default BuilderHeader;
