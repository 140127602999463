import type { AllIconsType, ChipProps as MuiChipProps } from "zds";
import { Chip } from "zds";
import type { BaseCellComponentProps } from "../Constants";
import { CellWrapper } from "../TableStyledWrappers";
import React from "react";
import type { ColumnAction } from "../../../../components/propertyControls/ColumnActionSelectorControl";

interface ChipCellProps
  extends BaseCellComponentProps,
    Pick<MuiChipProps, "label" | "variant" | "size" | "disabled"> {
  label?: string;
  disabled: boolean;
  variant?: MuiChipProps["variant"];
  dsOnClick?: (dynamicTrigger: string, onComplete: () => void) => void;
  state?: MuiChipProps["state"];
  action: ColumnAction;
  icon?: AllIconsType;
}

export function ChipCell(props: ChipCellProps) {
  const {
    allowCellWrapping,
    cellBackground,
    compactMode,
    disabled,
    dsOnClick,
    fontStyle,
    horizontalAlignment,
    icon,
    isCellDisabled,
    isCellVisible,
    isHidden,
    label,
    state,
    textColor,
    textSize,
    variant,
    verticalAlignment,
  } = props;

  const handleClick = () => {
    if (props.action.dynamicTrigger) {
      if (dsOnClick) {
        dsOnClick(props.action.dynamicTrigger, () => {});
      }
    }
  };

  return (
    <CellWrapper
      allowCellWrapping={allowCellWrapping}
      cellBackground={cellBackground}
      compactMode={compactMode}
      fontStyle={fontStyle}
      horizontalAlignment={horizontalAlignment}
      isCellDisabled={isCellDisabled}
      isCellVisible={isCellVisible}
      isHidden={isHidden}
      textColor={textColor}
      textSize={textSize}
      verticalAlignment={verticalAlignment}
    >
      <Chip
        completable={false}
        disabled={disabled}
        dismissible={false}
        dsOnClick={handleClick}
        dsOnDelete={() => {}}
        e2e={undefined}
        icon={icon}
        label={label}
        size={"small"}
        state={state}
        uppercase
        variant={variant}
      />
    </CellWrapper>
  );
}
