import React from "react";
import styled from "styled-components";
import TreeStructure from "components/utils/TreeStructure";
import { Button, Checkbox, Option, Select, Text, Tooltip } from "@appsmith/ads";
import { Box, Stack } from "@mui/material";
import type { WFRunParameter } from "entities/WFCollection";
// import { text } from "msw/lib/types/context";

interface TWFActionRunFormProps {
  datatypeOptions: any;
  parameters: WFRunParameter[];
  saveWFActionParams: () => void;
  updateWFActionParam: (
    paramIndex: string,
    value: string | number | boolean,
  ) => void;
  useLegacyBooleanInput: boolean;
  wfName: string;
}

const WorkflowParameterContainer = styled.div`
  border: 1px solid var(--ads-v2-color-border);
  border-bottom-color: var(--ads-v2-color-border);
  border-radius: var(--ads-v2-border-radius);
`;

const FieldLabel = ({
  children,
  fieldName,
  required,
}: {
  required: boolean;
  fieldName: string;
  children: React.ReactElement;
}) => {
  return required ? (
    <Tooltip content={`${fieldName} is a required field.`}>{children}</Tooltip>
  ) : (
    children
  );
};

const TextField = styled.input<{ disabled?: boolean }>`
  width: 100%;
  padding: 8px;
  border: 1px solid #cdd5df;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "auto")};
  background: ${(props) =>
    props.disabled
      ? "var(--ads-v2-color-bg-subtle)"
      : "var(--ads-v2-color-bg)"};
`;

function WFActionRunForm(props: TWFActionRunFormProps) {
  const {
    datatypeOptions,
    parameters,
    saveWFActionParams,
    updateWFActionParam,
    useLegacyBooleanInput,
    wfName,
  } = props;
  // const fileInputRef = useRef<HTMLInputElement>(null);

  // const onFileInputClick = () => {
  //   fileInputRef.current?.click();
  // };

  const renderField = (field: WFRunParameter) => {
    // console.log(field);
    switch (field.datatype) {
      case "Boolean":
        if (useLegacyBooleanInput) {
          return (
            <Box sx={{ ".ads-v2-checkbox": { height: "16px" } }}>
              <Checkbox
                isSelected={field.value === "true" || false}
                onChange={(isSelected) =>
                  updateWFActionParam(field.index, isSelected)
                }
              />
            </Box>
          );
        }
        return (
          <Select
            onSelect={(value) => updateWFActionParam(field.index, value)}
            showSearch
            value={field.value || ""}
          >
            <Option value={"true"}>true</Option>
            <Option value={"false"}>false</Option>
          </Select>
        );
      // case "Date":
      //   const locale =
      //     ("; " + document.cookie)
      //       .split(`; Zuora-User-Locale=`)
      //       .pop()
      //       .split(";")[0] || "en-US";
      //   return (
      //     <DatePicker
      //       value={field.value ? field.value + "T00:00:00" : ""}
      //       dsOnChange={(value) => {
      //         const dateFields =
      //           value && value.toISODate("yyyy-mm-dd")
      //             ? value.toISODate("yyyy-mm-dd")
      //             : "";
      //         this.props.updateField(field.index, dateFields);
      //       }}
      //       locale={locale}
      //       required={field.required}
      //       disabled={this.props.loading}
      //       error={
      //         (field.errors && field.errors.length > 0) ||
      //         field.value === "Invalid date"
      //       }
      //       fullWidth
      //     />
      //   );
      case "Date":
      case "Decimal":
      case "Integer":
      case "Text":
        if (
          field.object_name !== "Workflow" &&
          datatypeOptions[field.object_name][field.field_name].options
        ) {
          return (
            <Select
              onSelect={(value) => updateWFActionParam(field.index, value)}
              showSearch
              value={field.value || ""}
            >
              {datatypeOptions[field.object_name][field.field_name].options.map(
                (opt: string, index: number) => (
                  <Option key={`${field.field_name}-opt-${index}`} value={opt}>
                    {opt}
                  </Option>
                ),
              )}
            </Select>
          );
        }
        return (
          <TextField
            autoComplete="off"
            onChange={(e) => updateWFActionParam(field.index, e.target.value)}
            type={
              field.datatype === "Text"
                ? "text"
                : field.datatype === "Date"
                  ? "date"
                  : "number"
            }
            value={(field.value as string) || ""}
          />
        );
      // case "File-Field":
      //   return (
      //     <>
      //       <Button
      //         UNSAFE_height="36px"
      //         UNSAFE_width="100%"
      //         kind="secondary"
      //         onClick={onFileInputClick}
      //         size="sm"
      //         startIcon="upload-line"
      //       >
      //         Upload
      //         <input
      //           className="hidden"
      //           id={`${field.field_name}-file`}
      //           onChange={(e) => {
      //             if (e.target.files) console.log(e.target.files[0]);
      //           }}
      //           ref={fileInputRef}
      //           type="file"
      //         />
      //       </Button>
      //       {field.isFilePicked && (
      //         <Box
      //           sx={{
      //             marginTop: "2px",
      //             overflow: "hidden",
      //             textOverflow: "ellipsis",
      //             whiteSpace: "nowrap",
      //           }}
      //         >
      //           <Text>ASDFGASDFGASDFGASDFGASDFG.jpg</Text>
      //         </Box>
      //       )}
      //     </>
      //   );
      case "Picklist":
        return (
          <Select
            onSelect={(value) => updateWFActionParam(field.index, value)}
            value={field.value || ""}
          >
            {datatypeOptions[field.object_name][field.field_name].options.map(
              (opt: string, index: number) => (
                <Option key={`${field.field_name}-opt-${index}`} value={opt}>
                  {opt}
                </Option>
              ),
            )}
          </Select>
        );
      default:
        return (
          <TextField
            disabled
            type="text"
            value={`Not Implemented: ${field.datatype}`}
          />
        );
    }
  };

  return (
    <TreeStructure>
      <ul className={"tree flex flex-col gap-0"}>
        {parameters.map((param, index) => {
          return (
            <li key={`${wfName}-param-${index}`}>
              <div className="flex flex-col pl-1">
                <WorkflowParameterContainer>
                  <Stack sx={{ padding: "4px 8px" }}>
                    <Box sx={{ display: "flex" }}>
                      <FieldLabel
                        fieldName={param.field_name}
                        required={param.required}
                      >
                        <Text
                          className={param.required ? "cursor-help" : ""}
                          color="var(--ads-v2-colors-content-label-default-fg)"
                          style={{ fontSize: "12px" }}
                        >
                          {param.field_name}
                        </Text>
                      </FieldLabel>
                      {param.required && (
                        <Box sx={{ marginLeft: "2px" }}>
                          <Text color="red">*</Text>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ marginBottom: "4px" }}>{renderField(param)}</Box>
                  </Stack>
                </WorkflowParameterContainer>
              </div>
            </li>
          );
        })}
        <li>
          <div className="flex flex-col pl-1">
            <Button
              UNSAFE_height="32px"
              onClick={saveWFActionParams}
              startIcon="save"
            >
              Save
            </Button>
          </div>
        </li>
      </ul>
    </TreeStructure>
  );
}

export default WFActionRunForm;
