import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled from "styled-components";
import SortableListComponent from "../component";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { EvaluationSubstitutionType } from "../../../ce/entities/DataTree/types";
import type { AutoLayoutConfig } from "../../../WidgetProvider/constants";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;

class ZSortableListWidget extends BaseWidget<
  ZSortableListWidgetProps,
  WidgetState
> {
  static type = "ZSORTABLELIST_WIDGET";

  static getConfig() {
    return {
      name: "ZSortableList", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }
  static getDefaults() {
    return {
      widgetName: "ZSortableList",
      rows: 2,
      columns: 15,
      version: 1,
      items: [
        {
          label: "Card",
        },
        {
          label: "Apple Pay",
        },
        {
          label: "Google Pay",
        },
        {
          label: "PayPal",
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "items",
            label: "Sortable List",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                default: [],
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const ZSortableListWidgetProps = {
      ...this.props,
      disableDrag: (disable: boolean) => {
        this.disableDrag(disable);
      },
    };

    return (
      <ContainerWrapper>
        <SortableListComponent {...ZSortableListWidgetProps} />
      </ContainerWrapper>
    );
  }
}

export interface ZSortableListWidgetProps extends WidgetProps {
  items: {
    label: string;
    flagNew?: boolean;
    group?: string;
  }[];
}

export default ZSortableListWidget;
