import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BaseWidget from "widgets/BaseWidget";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import type { ButtonProps as MuiButtonProps } from "@mui/material";
import { WIDGET_TAGS } from "../../../constants/WidgetConstants";
import IconSVG from "../../ZEditButtonWidget/icon.svg";
import ThumbnailSVG from "../../ZEditButtonWidget/zuora.svg";
import styled from "styled-components";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;

class ZEditButtonWidget extends BaseWidget<
  ZEditButtonWidgetProps,
  WidgetState
> {
  constructor(props: ZEditButtonWidgetProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  static type = "EDIT_PARENT_WIDGET";

  static getConfig() {
    return {
      name: "ZEditButton", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: true, // Defines if this widget has a canvas within in which we can drop other widgets
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZEditButton",
      rows: 5,
      columns: 6,
      version: 1,
      variant: "contained",
      isDisabled: false,
      isVisible: true,
      disabledWhenInvalid: false,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "parentWidgetId",
            label: "Parent Widget ID",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  handleEditClick = () => {
    const { parentWidgetId } = this.props;
    if (parentWidgetId) {
      const currentUrl = window.location.href;
      window.location.href = `${currentUrl}/edit/widgets/${parentWidgetId}`;
    }
  };

  getWidgetView() {
    return (
      <ContainerWrapper>
        <IconButton onClick={this.handleEditClick}>
          <EditIcon />
        </IconButton>
      </ContainerWrapper>
    );
  }
}

export interface ZEditButtonWidgetProps extends WidgetProps {
  dsOnClick?: string;
  // startIcon: AllIconsType;
  // endIcon: AllIconsType;
  // icon: AllIconsType;
  // tooltip: string;
  variant: MuiButtonProps["variant"] | "tertiary";
  autoFocus: boolean;
  // label: string;
  parentWidgetId: string;
}

export default ZEditButtonWidget;
