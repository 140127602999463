import * as React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Card, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography, Link } from "zds";
import type { CrumbActionItem } from "../constants";
export interface BreadcrumbsProps {
  label: string;
  crumbs: Record<string, CrumbActionItem>;
  e2e?: string;
  handleClick: (onClick: string | undefined) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  crumbs,
  e2e,
  handleClick,
}: BreadcrumbsProps) => {
  const theme = useTheme();

  return (
    <Card
      {...(e2e && { "data-e2e": e2e })}
      style={{
        borderRadius: 0,
        padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      }}
    >
      <MuiBreadcrumbs>
        {Object.values(crumbs).map(({ id, label, onClick }, index) => {
          const isLastCrumb = index === Object.values(crumbs).length - 1;
          const crumb = (
            <Typography
              variant="caption"
              {...(e2e && { e2e: `${e2e}-${id}-label` })}
              color="inherit"
            >
              <span>{label}</span>
            </Typography>
          );
          return (
            <Box component="span" key={id}>
              {onClick ? (
                <Link
                  {...(isLastCrumb && { color: "#036ed1" })}
                  {...(e2e && { e2e: `${e2e}-${id}-url` })}
                  dsOnClick={() => handleClick(onClick)}
                >
                  {crumb}
                </Link>
              ) : (
                crumb
              )}
            </Box>
          );
        })}
      </MuiBreadcrumbs>
    </Card>
  );
};

export default Breadcrumbs;
