import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import ZPaymentComponent from "../component";

import IconSVG from "../icon.svg";

import { WIDGET_TAGS } from "constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import type {
  AutocompletionDefinitions,
  AutoLayoutConfig,
} from "../../../WidgetProvider/constants";
import type { SetterConfig } from "../../../entities/AppTheming";
import { DefaultAutocompleteDefinitions } from "../../WidgetUtils";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import ThumbnailSVG from "../zuora.svg";

class ZPaymentWidget extends BaseWidget<ZPaymentWidgetProps, WidgetState> {
  static type = "ZPAYMENT_WIDGET";

  constructor(props: ZPaymentWidgetProps) {
    super(props);
    this.state = {
      loaded: !props.onLoad,
    };
  }

  static getConfig() {
    return {
      name: "ZPayment", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.ZUORA],
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 2, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZPayment",
      isDisabled: false,
      isVisible: true,
      submitEnabled: true,
      hpmStyle: "inline",
      rows: 2,
      columns: 22,
      version: 1,
      hpmVersion: "2.0",
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setDisabled: {
          path: "isDisabled",
          type: "boolean",
        },
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "Data",
        children: [
          {
            propertyName: "hpmVersion",
            label: "HPM Version",
            controlType: "DROP_DOWN",
            initialValue: "2.0",
            isBindProperty: true,
            isTriggerProperty: true,
            isJSConvertible: true,
            options: [
              {
                label: "HPM 2.0",
                value: "2.0",
              },
              {
                label: "HPM 3.0",
                value: "3.0",
              },
            ],
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "2.0";
            },
            propertyName: "publishableKey",
            label: "Publishable Key",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: true,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "2.0";
            },
            propertyName: "currency",
            label: "Currency",
            controlType: "INPUT_TEXT",
            defaultValue: "USD",
            isBindProperty: true,
            isTriggerProperty: true,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "2.0";
            },
            propertyName: "amount",
            label: "Amount",
            controlType: "INPUT_TEXT",
            defaultValue: 1,
            tooltip: "Authorization amount",
            isBindProperty: true,
            isTriggerProperty: true,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "3.0";
            },
            propertyName: "rsaData",
            label: "RSA Data",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            validation: {
              type: ValidationTypes.OBJECT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "3.0";
            },
            propertyName: "pageId",
            label: "Page Id",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "accountId",
            label: "Account Id",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "3.0";
            },
            propertyName: "pageUrl",
            label: "Page URL",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "3.0";
            },
            propertyName: "paymentGateway",
            label: "Payment Gateway",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "3.0";
            },
            propertyName: "hpmStyle",
            label: "HPM Style",
            controlType: "DROP_DOWN",
            isBindProperty: false,
            isTriggerProperty: false,
            initialValue: "inline",
            options: [
              { label: "Overlay", value: "overlay" },
              { label: "Inline", value: "inline" },
            ],
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.hpmVersion === "3.0";
            },
            propertyName: "prepopulateFields",
            label: "Prepopulate Fields",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: false,
            validation: {
              type: ValidationTypes.OBJECT,
            },
          },
          {
            hidden: (props: ZPaymentWidgetProps) => {
              return props.submitEnabled === undefined;
            },
            propertyName: "submitEnabled",
            label: "Submit Button",
            controlType: "SWITCH",
            helpText: "Internal Submit Button",
            value: true,
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            propertyName: "onSubmitSuccess",
            label: "onSubmitSuccess",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            propertyName: "onSubmitError",
            label: "onSubmitError",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            propertyName: "onLoadError",
            label: "onLoadError",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            propertyName: "onLoad",
            label: "onLoad",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isDisabled",
            label: "Disabled",
            controlType: "SWITCH",
            helpText: "Disables input to this widget",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.BOOLEAN,
            },
          },
        ],
      },
    ];
  }

  updateResponseData = (data: object) => {
    this.updateWidgetProperty("responseData", data);
  };

  componentDidMount() {
    this.updateResponseData({});
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return () => ({
      "!doc":
        "Switch is a simple UI widget you can use when you want users to make a binary choice",
      "!url": "https://docs.appsmith.com/widget-reference/switch",
      responseData: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      isDisabled: "bool",
    });
  }

  getWidgetView() {
    const zPaymentWidgetProps = {
      ...this.props,
      updateResponseData: this.updateResponseData,
      onSubmitSuccess: this.onSubmitSuccess,
      onSubmitError: this.onSubmitError,
      onLoadError: this.onLoadError,
      onLoad: this.onLoad,
      loaded: Boolean(this.state.loaded),
    };
    return <ZPaymentComponent {...zPaymentWidgetProps} />;
  }

  onSubmitSuccess = () => {
    if (this.props.onSubmitSuccess) {
      super.executeAction({
        triggerPropertyName: "onSubmitSuccess",
        dynamicString: this.props.onSubmitSuccess,
        event: {
          type: EventType.ON_DROPDOWN_OPEN,
        },
      });
    }
  };

  onSubmitError = () => {
    if (this.props.onSubmitError) {
      super.executeAction({
        triggerPropertyName: "onSubmitError",
        dynamicString: this.props.onSubmitError,
        event: {
          type: EventType.ON_DROPDOWN_OPEN,
        },
      });
    }
  };

  onLoadError = () => {
    if (this.props.onLoadError) {
      super.executeAction({
        triggerPropertyName: "onLoadError",
        dynamicString: this.props.onLoadError,
        event: {
          type: EventType.ON_DROPDOWN_OPEN,
        },
      });
    }
  };

  onLoad = () => {
    if (this.props.onLoad) {
      super.executeAction({
        triggerPropertyName: "onLoad",
        dynamicString: this.props.onLoad,
        event: {
          type: EventType.ON_LOAD,
          callback: () => {
            this.setState({ loaded: true });
          },
        },
      });
    }
  };
}

export interface ZPaymentWidgetProps extends WidgetProps {
  rsaData: Record<string, string>;
  pageId: string;
  accountId?: string;
  pageUrl: string;
  paymentGateway?: string;
  responseData?: string;
  onSubmitSuccess?: string;
  onSubmitError?: string;
  onLoadError?: string;
  onLoad?: string;
  loaded: boolean;
  hpmStyle: string;
  prepopulateFields?: Record<string, string>;
  isDisabled?: boolean;
  isVisible?: boolean;
  hpmVersion?: string;
  publishableKey?: string;
  currency?: string;
  amount?: number;
  submitEnabled?: boolean;
}

export default ZPaymentWidget;
