import React from "react";
import type { ComponentProps } from "../../BaseComponent";
import { Classes, Label } from "@blueprintjs/core";

function CodePreviewComponent(props: CodePreviewComponentProps) {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Label className={Classes.TEXT_OVERFLOW_ELLIPSIS}>{props.label}</Label>
      <textarea
        style={{
          resize: "none",
          flex: "1",
          height: "100%",
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "10px",
          boxSizing: "border-box",
          fontFamily: props.fontFamily,
        }}
        value={props.text}
      />
      {/*<CopyToClipboard text={code}>*/}
      {/*  <button>Copy Code</button>*/}
      {/*</CopyToClipboard>*/}
    </div>
  );
}

export interface CodePreviewComponentProps extends ComponentProps {
  text?: string;
  label: string;
  fontFamily: string;
}

export default CodePreviewComponent;
