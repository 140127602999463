import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { RenderMode } from "constants/WidgetConstants";
import { GridDefaults, WIDGET_TAGS } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import {
  Positioning,
  type Alignment,
  type Spacing,
} from "layoutSystems/common/utils/constants";
import { get } from "lodash";
import React from "react";
import { SelectionRequestType } from "sagas/WidgetSelectUtils";
import { generateClassName } from "utils/generators";
import { getWidgetBluePrintUpdates } from "utils/WidgetBlueprintUtils";
import { DynamicHeight } from "utils/WidgetFeatures";
import {
  BlueprintOperationTypes,
  type AutocompletionDefinitions,
  type FlattenedWidgetProps,
} from "WidgetProvider/constants";
import WidgetFactory from "WidgetProvider/factory";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import ZModalComponent from "../component";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../thumbnail.svg";

export class ZModalWidget extends BaseWidget<ZModalWidgetProps, WidgetState> {
  static type = "ZMODAL_WIDGET";

  static getConfig() {
    return {
      name: "ZModal",
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.ZUORA],
      needsMeta: true, // holds user input
      isCanvas: true, // other widgets can be nested inside
      searchTags: ["dialog", "popup", "notification", "zuora"],
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0,
        active: true,
      },
    };
  }
  static getDefaults() {
    return {
      rows: 24,
      columns: 24,
      width: 456,
      height: GridDefaults.DEFAULT_GRID_ROW_HEIGHT * 24,
      minDynamicHeight: 24,
      canEscapeKeyClose: true,
      animateLoading: true,
      // detachFromLayout is set true for widgets that are not bound to the widgets within the layout.
      // setting it to true will only render the widgets(from sidebar) on the main container without any collision check.
      detachFromLayout: true,
      canOutsideClickClose: true,
      shouldScrollContents: true,
      confirmButtonVisible: true,
      closeButtonVisible: true,
      confirmButtonDisabled: false,
      closeButtonDisabled: false,
      widgetName: "ZModal",
      children: [],
      version: 2,
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              children: [],
              version: 1,
              blueprint: {
                view: [],
                operations: [],
              },
            },
          },
        ],
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: FlattenedWidgetProps) => {
              //get Canvas Widget
              const canvasWidget: FlattenedWidgetProps = get(
                widget,
                "children.0",
              );

              //Add widget specific property Defaults, for autoLayout widget
              const { disabledPropsDefaults } =
                WidgetFactory.getWidgetAutoLayoutConfig("ZMODAL_WIDGET") || {};

              //create properties to be updated
              return getWidgetBluePrintUpdates({
                [widget.widgetId]: {
                  dynamicHeight: DynamicHeight.FIXED,
                  ...disabledPropsDefaults,
                },
                [canvasWidget.widgetId]: {
                  useAutoLayout: true,
                  positioning: Positioning.Vertical,
                  bottomRow: 100,
                },
              });
            },
          },
        ],
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      disabledPropsDefaults: {
        minDynamicHeight: 8,
      },
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      name: {
        "!type": "string",
        "!doc": "Returns the modal name",
      },
    };
  }

  static getDerivedPropertiesMap() {
    return {
      name: "{{this.widgetName}}",
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {},
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Title of modal",
            propertyName: "modalTitle",
            label: "Enter title",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Enables scrolling for content inside the widget",
            propertyName: "shouldScrollContents",
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "animateLoading",
            label: "Animate loading",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "canOutsideClickClose",
            label: "Quick Dismiss",
            helpText: "Allows dismissing the modal when user taps outside",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          // modal footer (actions)
          {
            propertyName: "modalCloseLabel",
            label: "Close Button Label",
            helpText: "Label for the close button",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "modalConfirmLabel",
            label: "Confirm Button Label",
            helpText: "Label for the confirm button",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "confirmButtonVisible",
            label: "Confirm Button Visible?",
            helpText: "Sets visibility for the confirm button",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "closeButtonVisible",
            label: "Close Button isVisible?",
            helpText: "Sets visibility for the close button",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "confirmButtonDisabled",
            label: "Confirm Button isDisabled?",
            helpText: "Sets disabled state for the confirm button",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "closeButtonDisabled",
            label: "Close Button isDisabled?",
            helpText: "Sets disabled state for the close button",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          // { ...generatePositioningConfig(Positioning.Fixed) },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            helpText: "when the modal is closed",
            propertyName: "onClose",
            label: "onClose",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "when the close modal button is clicked",
            propertyName: "closeButtonOnClick",
            label: "onClick (Close Button)",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "when the confirm modal button is clicked",
            propertyName: "confirmButtonOnClick",
            label: "onClick (Confirm Button)",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static defaultProps = {
    isOpen: true,
    canEscapeKeyClose: false,
  };

  getModalVisibility() {
    if (this.props.selectedWidgetAncestry) {
      return (
        this.props.selectedWidgetAncestry.includes(this.props.widgetId) ||
        !!this.props.isVisible
      );
    }
    return !!this.props.isVisible;
  }

  onModalClose = () => {
    this.props.updateWidgetMetaProperty("isVisible", false);
    if (this.props.onClose) {
      super.executeAction({
        triggerPropertyName: "onClose",
        dynamicString: this.props.onClose,
        event: {
          type: EventType.ON_MODAL_CLOSE,
        },
      });
    }
  };

  closeButtonOnClick = () => {
    if (this.props.closeButtonOnClick) {
      super.executeAction({
        triggerPropertyName: "closeButtonOnClick",
        dynamicString: this.props.closeButtonOnClick,
        event: {
          type: EventType.CUSTOM_WIDGET_EVENT,
        },
      });
    }
  };

  confirmButtonOnClick = () => {
    if (this.props.confirmButtonOnClick) {
      super.executeAction({
        triggerPropertyName: "confirmButtonOnClick",
        dynamicString: this.props.confirmButtonOnClick,
        event: {
          type: EventType.CUSTOM_WIDGET_EVENT,
        },
      });
    }
  };

  closeModal = (e: any) => {
    this.props.updateWidgetMetaProperty("isVisible", false);
    this.selectWidgetRequest(SelectionRequestType.Empty);
    // TODO(abhinav): Create a static property with is a map of widget properties
    // Populate the map on widget load
    e.stopPropagation();
    e.preventDefault();
  };

  makeModalComponent() {
    return (
      <ZModalComponent
        alignment={this.props.alignment}
        background={this.props.backgroundColor}
        borderRadius={this.props.borderRadius}
        canEscapeKeyClose={!!this.props.canEscapeKeyClose}
        className={`t--modal-widget ${generateClassName(this.props.widgetId)}`}
        closeButtonDisabled={this.props.closeButtonDisabled}
        closeButtonOnClick={this.closeButtonOnClick}
        closeButtonVisible={this.props.closeButtonVisible}
        confirmButtonDisabled={this.props.confirmButtonDisabled}
        confirmButtonOnClick={this.confirmButtonOnClick}
        confirmButtonVisible={this.props.confirmButtonVisible}
        height={this.props.height}
        isOpen={this.getModalVisibility()}
        modalChildrenProps={this.props.children || []}
        modalCloseLabel={this.props.modalCloseLabel}
        modalConfirmLabel={this.props.modalConfirmLabel}
        modalTitle={this.props.modalTitle}
        onClose={this.closeModal}
        onModalClose={this.onModalClose}
        positioning={this.props.positioning}
        renderMode={this.props.renderMode}
        scrollContents={!!this.props.shouldScrollContents}
        shouldScrollContents={!!this.props.shouldScrollContents}
        spacing={this.props.spacing}
        widgetId={this.props.widgetId}
        width={this.props.width}
      />
    );
  }

  getWidgetView() {
    return this.makeModalComponent();
  }
}
export interface ZModalWidgetProps extends WidgetProps {
  renderMode: RenderMode;
  isOpen?: boolean;
  children?: WidgetProps[];
  canOutsideClickClose?: boolean;
  width: number;
  height: number;
  deselectAllWidgets: () => void;
  canEscapeKeyClose?: boolean;
  shouldScrollContents?: boolean;
  size: string;
  onClose: string;
  mainContainer: WidgetProps;
  backgroundColor: string;
  borderRadius: string;
  mainCanvasWidth: number;
  positioning?: Positioning;
  alignment: Alignment;
  spacing: Spacing;
}

export default ZModalWidget;
