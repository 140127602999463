import equal from "fast-deep-equal/es6";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import type { ControllerProps } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { klona } from "klona";

import type { FieldLabelProps } from "./FieldLabel";
import FieldLabel from "./FieldLabel";
import useUpdateAccessor from "../fields/useObserveAccessor";

type FieldProps<TValue> = React.PropsWithChildren<
  {
    accessor: string;
    defaultValue: TValue;
    fieldClassName: string;
    hideLabel?: boolean;
    inlineLabel?: boolean;
    isRequiredField?: boolean;
    name: ControllerProps["name"];
  } & FieldLabelProps
>;

interface StyledWrapperProps {
  direction: "row" | "column";
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

function Field<TValue>({
  accessor,
  alignment,
  children,
  defaultValue,
  fieldClassName,
  inlineLabel = false,
  isRequiredField,
  label,
  labelStyle,
  labelTextColor,
  labelTextSize,
  name,
  tooltip,
  width,
}: FieldProps<TValue>) {
  const refDefaultValue = useRef<TValue>();
  const { setValue } = useFormContext();

  useUpdateAccessor({ accessor });

  useEffect(() => {
    if (!equal(refDefaultValue.current, defaultValue)) {
      refDefaultValue.current = defaultValue;

      // Follow the comment in Form component above reset(convertedFormData);
      setTimeout(() => {
        setValue(name, klona(defaultValue));
      }, 0);
    }
  }, [defaultValue, setValue]);

  const direction = inlineLabel ? "row" : "column";

  return (
    <StyledWrapper
      className={`t--jsonformfield-${fieldClassName}`}
      direction={direction}
    >
      <FieldLabel
        alignment={alignment}
        direction={direction}
        isRequiredField={isRequiredField}
        label={label}
        labelStyle={labelStyle}
        labelTextColor={labelTextColor}
        labelTextSize={labelTextSize}
        tooltip={tooltip}
        width={width}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          // flexGrow: 0,
        }}
      >
        {children}
      </div>
    </StyledWrapper>
  );
}

export default Field;
