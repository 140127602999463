import React, { useEffect, useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

interface ColorPickerComponentProps {
  color: string;
  handleColorChange: (newColor: string) => void;
}

function ColorPickerComponent({
  color,
  handleColorChange,
}: ColorPickerComponentProps) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click occurred outside the ColorPickerComponent
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setShowColorPicker(false); // Close the color picker
      }
    };

    // Attach event listener when the color picker is shown
    if (showColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Clean up event listener when the color picker is hidden
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorPicker]);

  const handleColorBoxClick = () => {
    setShowColorPicker(true);
  };

  const getContrastingTextColor = (hex: string) => {
    // Convert hex to RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Calculate brightness (standard luminance formula)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return white or black based on brightness
    return brightness > 128 ? "#000000" : "#FFFFFF";
  };

  return (
    <div
      onClick={handleColorBoxClick}
      ref={colorPickerRef}
      style={{
        width: "100%",
        minWidth: "100px",
        padding: "2px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#fff",
        cursor: "pointer",
        position: "relative", // Ensure the position is relative for absolute positioning of the color picker
      }}
    >
      <div
        style={{
          width: "100%",
          height: "30px",
          backgroundColor: color,
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: getContrastingTextColor(color),
          fontWeight: "bold",
        }}
      >
        {color}
      </div>
      {showColorPicker && (
        <div style={{ position: "absolute", zIndex: 1, marginTop: "10px" }}>
          <HexColorPicker color={color} onChange={handleColorChange} />
          <HexColorInput color={color} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
}

export default ColorPickerComponent;
