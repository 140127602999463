import type { AllIconsType } from "zds";

export const APPBAR_WIDGET_CONSTANT = "";

export enum MenuItemsSource {
  STATIC = "STATIC",
  DYNAMIC = "DYNAMIC",
}

export interface ActionItem {
  widgetId: "string";
  id: "string";
  index: 0;
  isVisible: true;
  isDisabled: false;
  divider: false;
  label: string;
  icon: AllIconsType;
  startIcon: AllIconsType;
  endIcon: AllIconsType;
  onClick: string;
  href: string;
  type: string;
  value: string;
  color: string;

  // Menu-specific properties
  menuItems: Record<string, MenuItem>;
  menuItemsSource?: MenuItemsSource;
  configureMenuItems?: ConfigureMenuItems;
  sourceData?: Array<Record<string, unknown>>;

  // Typography-specific properties
  fontFamily: string;
  fontSize: string;
  fontStyle: string;
  fontWeight: string;
  surTitle: string;
  surTitleColor: string;
  surTitleFontSize: string;
  surTitleFontWeight: string;

  // Button-specific properties
  variant: string;
  badgeContent: number;
  menuWidth: number;

  // Image-specific properties
  imageWidth: number;
  imageHeight: number;
}

export interface MenuItem {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  divider?: boolean;
  label?: string;
  icon?: AllIconsType;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  onClick?: string;
  href?: string;
}

export interface ConfigureMenuItems {
  label: string;
  id: string;
  config: MenuItem;
}
