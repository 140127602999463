import { MenuItemsSource } from "../../constants";
import _, { get } from "lodash";
import { getBasePropertyPath } from "../../../TableWidget/widget/propertyUtils";
import type { AppBarWidgetProps } from "../index";

export const updateMenuItemsSource = (
  props: AppBarWidgetProps,
  propertyPath: string,
  propertyValue: unknown,
): Array<{ propertyPath: string; propertyValue: unknown }> | undefined => {
  const propertiesToUpdate: Array<{
    propertyPath: string;
    propertyValue: unknown;
  }> = [];

  const isMenuItemsSourceChangedFromStaticToDynamic =
    _.get(props, propertyPath, "") === MenuItemsSource.STATIC &&
    propertyValue === MenuItemsSource.DYNAMIC;

  if (isMenuItemsSourceChangedFromStaticToDynamic) {
    const property = getBasePropertyPath(propertyPath);
    if (!_.get(props, `${property}.sourceData`, undefined)) {
      propertiesToUpdate.push({
        propertyPath: `${property}.sourceData`,
        propertyValue: [],
      });
    }

    if (!_.get(props, `${property}.configureMenuItems`, undefined)) {
      propertiesToUpdate.push({
        propertyPath: `${property}.configureMenuItems`,
        propertyValue: {
          label: "Configure menu items",
          id: "config",
          config: {
            id: "config",
            label: "Menu Item",
            isVisible: true,
            isDisabled: false,
          },
        },
      });
    }
  }

  return propertiesToUpdate?.length ? propertiesToUpdate : undefined;
};

export const hiddenNotMenuActionDynamic = (
  props: AppBarWidgetProps,
  propertyPath: string,
) => {
  const actionType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.type`,
    "",
  );
  const menuType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.menuItemsSource`,
    "",
  );

  return (
    ["BUTTON", "Typography", "Image"].includes(actionType) ||
    menuType === MenuItemsSource.STATIC
  );
};

export const hiddenNotMenuActionStatic = (
  props: AppBarWidgetProps,
  propertyPath: string,
) => {
  const actionType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.type`,
    "",
  );
  const menuType = get(
    props,
    `${propertyPath.split(".", 2).join(".")}.menuItemsSource`,
    "",
  );

  return (
    ["Button", "Typography", "Image"].includes(actionType) ||
    menuType === MenuItemsSource.DYNAMIC
  );
};
