import React, { type ReactNode } from "react";
import { CardHeader, Card } from "@mui/material";
import type { ComponentProps } from "widgets/BaseComponent";
import styled from "styled-components";
import {
  generateClassName,
  getCanvasClassName,
} from "../../../utils/generators";
import { scrollCSS } from "../../WidgetUtils";
import type { Positioning } from "../../../layoutSystems/common/utils/constants";
import type { HeaderActionItem } from "../constants";
import { PageHeaderActions } from "../../PageHeaderWidget/component/PageHeaderActions";
import { sortBy } from "lodash";
import { Typography } from "zds";
import { Box } from "@mui/system";
import { Stepper, Button } from "zds";

const ScrollCanvas = styled.div<{
  $shouldScrollContents: boolean;
}>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;

interface StepperComponentProps extends ComponentProps {
  activeStep: number;
  children?: ReactNode;
  steps: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
    positioning: Positioning;
    description: string;
  }>;
  isAlternativeLabel: boolean;
  selectedStepWidgetId: string;
  activeColor: string;
  completedColor: string;
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  shouldScrollContents?: boolean;
  $noScroll: boolean;
  stepButton: boolean;
  onStepChange: (activeStep: number) => void;
  onLoad?: () => void;
  nonLinear?: boolean;
  headerActions?: Record<string, HeaderActionItem>;
  maxHeaderActionDisplay: number;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
  groupButtonHandleClick?: (
    onClick: string | undefined,
    callback?: () => void,
  ) => void;
  title: string;
  subtitle: string;
  titleBar: boolean;
  onToggleChange: () => void;
  showStepper: boolean;
  hideCardHeader: boolean;
}

function ZStepperRunCardComponent(props: StepperComponentProps) {
  const {
    activeColor,
    activeStep,
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    boxShadow,
    buttonClickHandler,
    completedColor,
    handleOnchange,
    headerActions,
    hideCardHeader,
    maxHeaderActionDisplay,
    nonLinear,
    onStepChange,
    onToggleChange,
    selectedStepWidgetId,
    showStepper,
    steps,
  } = props;

  const handleOnLoad = () => {
    if (props.onLoad) {
      props.onLoad();
    }
  };

  React.useEffect(() => {
    handleOnLoad();
  }, [selectedStepWidgetId]);

  const getVisibleActions = () => {
    const actions = headerActions;
    if (!actions) return {};

    let items = Object.keys(actions)
      .map((itemKey) => actions[itemKey])
      .filter((item) => item.isVisible === true);
    // sort btns by index
    items = sortBy(items, ["index"]);
    return items;
  };

  const toggleStepper = () => {
    onToggleChange();
  };

  interface Step {
    label: string;
    complete: boolean;
    description: string;
  }
  const [mySteps, setSteps] = React.useState<Step[]>([]);

  React.useEffect(() => {
    const stepsElements = steps.map((step, index) => ({
      label: step.label,
      complete: index < activeStep,
      description: step.description,
    }));
    setSteps(stepsElements);
  }, [steps, activeStep]);

  const onClick = (index: number) => {
    if (!nonLinear) return;
    setSteps(
      mySteps.map((step, i) => {
        if (i <= index) {
          step.complete = true;
        }
        return step;
      }),
    );
    onStepChange(index);
  };

  return (
    <Card
      sx={{
        margin: "auto",
        position: "relative",
        width: "100%",
        height: "auto",
        borderRadius: borderRadius,
        boxShadow: boxShadow,
        borderWidth: borderWidth,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        borderStyle: borderWidth ? "solid" : "none",
      }}
    >
      {!hideCardHeader && (
        <CardHeader
          action={
            headerActions && (
              <PageHeaderActions
                actions={getVisibleActions()}
                buttonClickHandler={buttonClickHandler}
                handleOnchange={handleOnchange}
                maxHeaderActionDisplay={maxHeaderActionDisplay}
              />
            )
          }
          subheader={
            <Typography variant="subtitle1">{props.subtitle}</Typography>
          }
          sx={{ flexGrow: 1, borderBottom: "1px solid #ddd" }}
          title={
            <Typography titleBar={props.titleBar} variant="h5">
              {props.title}
            </Typography>
          }
        />
      )}
      <Box sx={{ display: "flex" }}>
        {showStepper && (
          <Box
            sx={{
              width: "25%",
              transition: "0.3s",
              paddingTop: "10px",
              paddingLeft: "6px",
              "& .MuiStepIcon-root.Mui-active": {
                color: activeColor,
              },
              "& .MuiStepIcon-root.Mui-completed": {
                color: completedColor,
              },
            }}
          >
            <Stepper
              activeStep={activeStep}
              dsOnClick={async (index) => onClick(index)}
              steps={mySteps}
              variant="vertical"
            />
          </Box>
        )}
        <Button
          dsOnClick={toggleStepper}
          icon={showStepper ? "keyboard_arrow_left" : "keyboard_arrow_right"}
          sx={{
            position: "absolute",
            top: hideCardHeader ? "30px" : "120px", // Vertically centered
            left: showStepper ? "25%" : "3px", // Adjust based on stepper state
            transform: "translate(-50%, -50%)", // Center align the button
            transition: "0.3s ease-in-out",
            backgroundColor: "#e0e0e0", // Solid background
            border: "2px solid #bbb", // Border for visibility
            borderRadius: "50%", // Circular button
            // boxShadow: 2,
            zIndex: 999, // Ensure visibility above content
            width: 24, // Size adjustments
            height: 24,
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#f0f0f0", // Light hover effect
            },
          }}
        />

        <Box
          sx={{
            width: showStepper ? "75%" : "100%",
            transition: "width 0.3s ease",
            borderLeft: showStepper ? "1px solid #ddd" : "none",
          }}
        >
          <ScrollCanvas
            $shouldScrollContents={
              !!props.shouldScrollContents && !props.$noScroll
            }
            className={`${
              props.shouldScrollContents ? getCanvasClassName() : ""
            } ${generateClassName(props.widgetId)}`}
          >
            {props.children}
          </ScrollCanvas>
        </Box>
      </Box>
    </Card>
  );
}

export default ZStepperRunCardComponent;
