import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import ColorPickerWrapper from "../component";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled from "styled-components";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import type {
  AutocompletionDefinitions,
  AutoLayoutConfig,
} from "../../../WidgetProvider/constants";
import type { SetterConfig } from "../../../entities/AppTheming";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
`;

class ZColorPickerWidget extends BaseWidget<
  ZColorPickerWidgetProps,
  WidgetState
> {
  static type = "ZCOLORPICKER_WIDGET";

  static getConfig() {
    return {
      name: "ZColorPicker", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZColorPicker",
      rows: 3,
      columns: 6,
      version: 1,
      label: "Background",
      colorSuggestions: [
        "#ffffff",
        "#121e3e",
        "#37398d",
        "#cbd8e1",
        "#d40001",
        "#00d2b9",
        "#8609b8",
        "#d48600",
      ],
      hasTint: false,
      defaultColor: "#121e3e",
      pickerColor: "#121e3e",
      // tintColor: "#1d3063",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "defaultColor",
            label: "Default Color",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "hasTint",
            label: "HasTint",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "colorSuggestions",
            label: "ColorSuggestions",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            inputType: "ARRAY",
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                default: [],
              },
            },
          },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            propertyName: "onColorsReceived",
            label: "onColorsReceived",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "The Button group widget represents a set of buttons in a group. Group can have simple buttons or menu buttons with drop-down items.",
      "!url": "https://docs.appsmith.com/widget-reference/button-group",
      pickerColor: "string",
      tintColor: "string",
    };
  }

  onColorsReceived = (color: string, tint: string) => {
    this.updateWidgetProperty("pickerColor", color);
    this.updateWidgetProperty("tintColor", tint);
    if (this.props.onColorsReceived) {
      super.executeAction({
        triggerPropertyName: "onColorsReceived",
        dynamicString: this.props.onColorsReceived,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }
  };

  componentDidUpdate(prevProps: ZColorPickerWidgetProps): void {
    //on adding a new column last column should get focused
    if (prevProps.hasTint && !this.props.hasTint) {
      this.updateWidgetProperty("tintColor", undefined);
    }
    if (this.props.defaultColor !== prevProps.defaultColor) {
      this.updateWidgetProperty("pickerColor", this.props.defaultColor);
    }
    if (this.props.colorSuggestions !== prevProps.colorSuggestions) {
      this.updateWidgetProperty(
        "colorSuggestions",
        this.props.colorSuggestions,
      );
    }
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setPickerColor: {
          path: "pickerColor",
          type: "string",
        },
      },
    };
  }

  getWidgetView() {
    const ZColorPickerWidgetProps = {
      ...this.props,
      hasTint: this.props.hasTint ? "true" : "false",
    };

    return (
      <ContainerWrapper>
        <ColorPickerWrapper
          {...ZColorPickerWidgetProps}
          onColorsReceived={this.onColorsReceived}
        />
      </ContainerWrapper>
    );
  }

  componentDidMount() {
    if (this.props.defaultColor) {
      this.updateWidgetProperty("pickerColor", this.props.defaultColor);
    }
  }
}

export interface ZColorPickerWidgetProps extends WidgetProps {
  label: string;
  hasTint: boolean;
  tintColor: string;
  colorSuggestions: any;
  pickerColor: string;
  onColorsReceived: string;
  defaultColor: string;
}

export default ZColorPickerWidget;
