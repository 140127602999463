import {
  SUBSCRIPTION_STATUS_ACTIVE_BADGE,
  type TitleActionItem,
} from "../constants";
import React, { useMemo, useState } from "react";
import { Button as MuiButton, Tooltip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StatusHistory from "./StatusHistory";
import { convertToTenantTZ, formatDateByLocale } from "./Utils";
import moment from "moment";
import "./Custom.css";

const active_badge_tooltip = SUBSCRIPTION_STATUS_ACTIVE_BADGE;

const StatusAction = ({
  action,
  identity,
  onClickEvent,
}: {
  action: TitleActionItem;
  identity?: Record<any, any>;
  onClickEvent: (onClick: string | undefined) => void;
}) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const details = action.details;

  const toggleDropDown = (e: any) => {
    if (!dropDownVisible) {
      onClickEvent(action.onClick);
    }
    setDropDownVisible(!dropDownVisible);
    setAnchorEl(dropDownVisible ? null : e.currentTarget);
  };
  const statusBadge = useMemo(() => {
    if (!action?.subscriptionData || !action.subscriptionData?.status) {
      return { status: "Active", className: "active" };
    }
    const subscriptionData = action.subscriptionData;
    switch (subscriptionData.status) {
      case "Active":
        return convertToTenantTZ(moment(), identity?.tenantTimeZone)?.isAfter(
          moment(subscriptionData.termEndDate),
        )
          ? {
              status: `${subscriptionData.status} ('OUT_OF_TERM')`,
              className: "expired",
            }
          : { status: subscriptionData.status, className: "active" };
      case "Cancelled":
        return convertToTenantTZ(moment(), identity?.tenantTimeZone)?.isBefore(
          moment(subscriptionData.cancelledDate),
        )
          ? {
              status: `${subscriptionData.status} (on ${formatDateByLocale(subscriptionData.cancelledDate, identity?.locale)})`,
              className: "cancelled",
            }
          : { status: subscriptionData.status, className: "cancelled" };
      default:
        return {
          status: subscriptionData.status,
          className: subscriptionData.status?.toLowerCase(),
        };
    }
  }, [action.subscriptionData, identity?.tenantTimeZone, identity?.locale]);

  return (
    <div className="badge-wrapper">
      {statusBadge.status === "Expired" ? (
        <MuiButton
          className={`badge ${statusBadge.className}`}
          id="basic-button"
          style={{ cursor: "auto" }}
        >
          {statusBadge.status}
        </MuiButton>
      ) : (
        <Tooltip arrow placement="top" title={active_badge_tooltip}>
          <MuiButton
            aria-controls="subscription-time-line"
            aria-expanded={dropDownVisible ? "true" : undefined}
            aria-haspopup="true"
            className={`badge ${statusBadge.className}`}
            endIcon={dropDownVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            id="basic-button"
            onClick={toggleDropDown}
          >
            {statusBadge.status?.toString().length < 26 ? (
              <span className="badge-text">
                {statusBadge.status.toUpperCase()}
              </span>
            ) : (
              <span
                className="badge-text"
                title={statusBadge.status.toUpperCase()}
              >{`${statusBadge.status
                .toUpperCase()
                ?.substring(0, 23)}...`}</span>
            )}
          </MuiButton>
        </Tooltip>
      )}
      <StatusHistory
        anchorEl={anchorEl}
        details={details}
        dropDownVisible={dropDownVisible}
        identity={identity}
        subscriptionData={action.subscriptionData}
        toggleDropDown={toggleDropDown}
      />
    </div>
  );
};

export default StatusAction;
