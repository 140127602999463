import { ValidationTypes } from "constants/WidgetValidation";
import { updateHookOnActionTypeChange } from "widgets/PageHeaderWidget/widget/propertyConfig/propertyUtils";
import type { ButtonGroupWidgetProps } from "widgets/ButtonGroupWidget/widget";
import { get } from "lodash";
import { ButtonVariantTypes } from "widgets/ZButtonWidget/constants";
import { getStylesheetValue } from "widgets/ButtonGroupWidget/widget/helpers";
import type { PageHeaderWidgetProps } from "widgets/PageHeaderWidget/constants";
import type { WidgetProps } from "widgets/BaseWidget";
import { getBasePropertyPath } from "widgets/TableWidget/widget/propertyUtils";
import { ICON_NAMES } from "widgets/ZAccordionWidget/constants";

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  updateHook: (props: any, propertyPath: string, propertyValue: string) => {
    return [
      {
        propertyPath,
        propertyValue,
      },
    ];
  },
  contentChildren: [
    {
      sectionName: "Data",
      children: [
        {
          propertyName: "actionType",
          label: "Action type",
          controlType: "DROP_DOWN",
          fullWidth: true,
          options: [
            {
              label: "Button",
              value: "BUTTON",
            },
            {
              label: "Group Buttons",
              value: "GROUP_BUTTONS",
            },
            // {
            //   label: "Checkbox",
            //   value: "CHECKBOX",
            // },
          ],
          defaultValue: "BUTTON",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: ["BUTTON", "GROUP_BUTTONS"],
            },
          },
          updateHook: updateHookOnActionTypeChange,
        },
      ],
    },
    {
      sectionName: "Group Buttons Config",
      hidden: (
        props: PageHeaderWidgetProps<WidgetProps>,
        propertyPath: string,
      ) => {
        const actionType = get(props, `${propertyPath}.actionType`, "");
        return ["BUTTON"].includes(actionType);
      },
      children: [
        {
          helpText: "Group Buttons",
          propertyName: "groupButtons",
          controlType: "GROUP_BUTTONS",
          label: "Buttons",
          isBindProperty: false,
          isTriggerProperty: false,
          dependencies: ["childStylesheet"],
          panelConfig: {
            editableTitle: true,
            titlePropertyName: "label",
            panelIdPropertyName: "id",
            updateHook: (
              props: any,
              propertyPath: string,
              propertyValue: string,
            ) => {
              return [
                {
                  propertyPath,
                  propertyValue,
                },
              ];
            },
            contentChildren: [
              {
                sectionName: "Data",
                children: [
                  {
                    propertyName: "buttonType",
                    label: "Button type",
                    controlType: "ICON_TABS",
                    fullWidth: true,
                    helpText: "Sets button type",
                    options: [
                      {
                        label: "Simple",
                        value: "SIMPLE",
                      },
                      {
                        label: "Menu",
                        value: "MENU",
                      },
                    ],
                    defaultValue: "SIMPLE",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: {
                      type: ValidationTypes.TEXT,
                      params: {
                        allowedValues: ["SIMPLE", "MENU"],
                      },
                    },
                  },
                  {
                    hidden: (
                      props: PageHeaderWidgetProps<WidgetProps>,
                      propertyPath: string,
                    ) => {
                      const property = getBasePropertyPath(propertyPath);
                      const buttonType = get(
                        props,
                        `${property}.buttonType`,
                        "",
                      );
                      return buttonType === "SIMPLE";
                    },
                    dependencies: ["groupButtons"],
                    helpText: "Menu items",
                    propertyName: "menuItems",
                    controlType: "MENU_ITEMS",
                    label: "Menu items",
                    isBindProperty: false,
                    isTriggerProperty: false,
                    panelConfig: {
                      editableTitle: true,
                      titlePropertyName: "label",
                      panelIdPropertyName: "id",
                      updateHook: (
                        props: any,
                        propertyPath: string,
                        propertyValue: string,
                      ) => {
                        return [
                          {
                            propertyPath,
                            propertyValue,
                          },
                        ];
                      },
                      contentChildren: [
                        {
                          sectionName: "Label",
                          children: [
                            {
                              propertyName: "label",
                              helpText: "Sets the label of a menu item",
                              label: "Text",
                              controlType: "INPUT_TEXT",
                              placeholderText: "Enter label",
                              isBindProperty: true,
                              isTriggerProperty: false,
                              validation: { type: ValidationTypes.TEXT },
                            },
                          ],
                        },
                        {
                          sectionName: "General",
                          children: [
                            {
                              propertyName: "isVisible",
                              helpText: "Controls the visibility of menu item",
                              label: "Visible",
                              controlType: "SWITCH",
                              isJSConvertible: true,
                              isBindProperty: true,
                              isTriggerProperty: false,
                              validation: {
                                type: ValidationTypes.BOOLEAN,
                              },
                            },
                            {
                              propertyName: "isDisabled",
                              helpText: "Disables menu item",
                              label: "Disabled",
                              controlType: "SWITCH",
                              isJSConvertible: true,
                              isBindProperty: true,
                              isTriggerProperty: false,
                              validation: {
                                type: ValidationTypes.BOOLEAN,
                              },
                            },
                          ],
                        },
                        {
                          sectionName: "Events",
                          children: [
                            {
                              helpText: "when the menu item is clicked",
                              propertyName: "onClick",
                              label: "onClick",
                              controlType: "ACTION_SELECTOR",
                              isJSConvertible: true,
                              isBindProperty: true,
                              isTriggerProperty: true,
                            },
                          ],
                        },
                      ],
                      styleChildren: [
                        {
                          sectionName: "Icon",
                          children: [
                            {
                              propertyName: "iconName",
                              label: "Icon",
                              helpText:
                                "Sets the icon to be used for a menu item",
                              controlType: "ICON_SELECT",
                              isJSConvertible: true,
                              isBindProperty: true,
                              isTriggerProperty: false,
                              validation: { type: ValidationTypes.TEXT },
                            },
                            {
                              propertyName: "iconAlign",
                              label: "Position",
                              helpText:
                                "Sets the icon alignment of a menu item",
                              controlType: "ICON_TABS",
                              fullWidth: false,
                              options: [
                                {
                                  startIcon: "skip-left-line",
                                  value: "left",
                                },
                                {
                                  startIcon: "skip-right-line",
                                  value: "right",
                                },
                              ],
                              defaultValue: "left",
                              isBindProperty: false,
                              isTriggerProperty: false,
                              validation: { type: ValidationTypes.TEXT },
                            },
                          ],
                        },
                        {
                          sectionName: "Color",
                          children: [
                            {
                              propertyName: "backgroundColor",
                              helpText:
                                "Sets the background color of a menu item",
                              label: "Background color",
                              controlType: "COLOR_PICKER",
                              isJSConvertible: true,
                              isBindProperty: true,
                              isTriggerProperty: false,
                              validation: { type: ValidationTypes.TEXT },
                            },
                            {
                              propertyName: "iconColor",
                              helpText: "Sets the icon color of a menu item",
                              label: "Icon Color",
                              controlType: "COLOR_PICKER",
                              isBindProperty: false,
                              isTriggerProperty: false,
                            },
                            {
                              propertyName: "textColor",
                              helpText: "Sets the text color of a menu item",
                              label: "Text color",
                              controlType: "COLOR_PICKER",
                              isBindProperty: false,
                              isTriggerProperty: false,
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
              {
                sectionName: "Label",
                children: [
                  {
                    propertyName: "label",
                    helpText: "Sets the label of a menu item",
                    label: "Text",
                    controlType: "INPUT_TEXT",
                    placeholderText: "Enter label",
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.TEXT },
                  },
                ],
              },
              {
                sectionName: "General",
                children: [
                  {
                    propertyName: "isVisible",
                    helpText: "Controls the visibility of the widget",
                    label: "Visible",
                    controlType: "SWITCH",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.BOOLEAN },
                  },
                  {
                    propertyName: "isDisabled",
                    helpText: "Disables input to the widget",
                    label: "Disabled",
                    controlType: "SWITCH",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.BOOLEAN },
                  },
                ],
              },
              {
                sectionName: "Events",
                hidden: (
                  props: ButtonGroupWidgetProps,
                  propertyPath: string,
                ) => {
                  const buttonType = get(
                    props,
                    `${propertyPath}.buttonType`,
                    "",
                  );
                  return buttonType === "MENU";
                },
                children: [
                  {
                    helpText: "when the button is clicked",
                    propertyName: "onClick",
                    label: "onClick",
                    controlType: "ACTION_SELECTOR",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: true,
                  },
                ],
              },
            ],
            styleChildren: [
              {
                sectionName: "Icon",
                children: [
                  {
                    propertyName: "icon",
                    label: "Icon",
                    helpText:
                      "Sets the icon to be used for a menu item. Can also be configured the using {{currentItem}} binding.",
                    controlType: "ZICON_SELECT",
                    isBindProperty: true,
                    isTriggerProperty: false,
                    isJSConvertible: true,
                    validation: {
                      type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                      params: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: ICON_NAMES,
                        },
                      },
                    },
                    customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
                    evaluatedDependencies: ["sourceData"],
                  },
                  {
                    propertyName: "startIcon",
                    label: "StartIcon",
                    helpText:
                      "Sets the StartIcon to be used for a menu item. Can also be configured the using {{currentItem}} binding.",
                    controlType: "ZICON_SELECT",
                    isBindProperty: true,
                    isTriggerProperty: false,
                    isJSConvertible: true,
                    validation: {
                      type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                      params: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: ICON_NAMES,
                        },
                      },
                    },
                    customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
                    evaluatedDependencies: ["sourceData"],
                  },
                  {
                    propertyName: "endIcon",
                    label: "endIcon",
                    helpText:
                      "Sets the StartIcon to be used for a menu item. Can also be configured the using {{currentItem}} binding.",
                    controlType: "ZICON_SELECT",
                    isBindProperty: true,
                    isTriggerProperty: false,
                    isJSConvertible: true,
                    validation: {
                      type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
                      params: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: ICON_NAMES,
                        },
                      },
                    },
                    customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
                    evaluatedDependencies: ["sourceData"],
                  },
                ],
              },
              {
                sectionName: "Color",
                children: [
                  {
                    getStylesheetValue,
                    propertyName: "buttonColor",
                    helpText: "Changes the color of the button",
                    label: "Button color",
                    controlType: "COLOR_PICKER",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.TEXT },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      sectionName: "Label",
      children: [
        {
          propertyName: "label",
          helpText: "Sets the label of a menu item",
          label: "Text",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter label",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "icon",
          label: "Icon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "startIcon",
          label: "StartIcon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "endIcon",
          label: "EndIcon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
      ],
    },
    {
      sectionName: "General",
      children: [
        {
          propertyName: "selectWidth",
          helpText: "Sets the fix width to display select action",
          label: "Width",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.NUMBER },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["BUTTON"].includes(actionType);
          },
        },
        {
          propertyName: "isVisible",
          helpText: "Controls the visibility of the widget",
          label: "Visible",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "isDisabled",
          helpText: "Disables input to the widget",
          label: "Disabled",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "divider",
          helpText: "Divider with next button in menu dropdown",
          label: "Divider",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.BOOLEAN,
          },
        },
      ],
    },
    {
      sectionName: "Events",
      children: [
        {
          propertyName: "onClick",
          label: "onClick",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
          dependencies: ["actionType"],
        },
        {
          propertyName: "onChange",
          label: "onChange",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return (
              actionType === "BUTTON" ||
              actionType === "MENU" ||
              actionType === "GROUP_BUTTONS"
            );
          },
          dependencies: ["actionType"],
        },
      ],
    },
  ],
  styleChildren: [
    {
      sectionName: "General",
      children: [
        {
          propertyName: "variant",
          label: "Button variant",
          controlType: "ICON_TABS",
          fullWidth: true,
          helpText: "Sets the variant of the button",
          options: [
            {
              label: "Contained",
              value: ButtonVariantTypes.CONTAINED,
            },
            {
              label: "Tertiary",
              value: ButtonVariantTypes.TERTIARY,
            },
            {
              label: "Text",
              value: ButtonVariantTypes.TEXT,
            },
          ],
          defaultValue: ButtonVariantTypes.CONTAINED,
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: [
                ButtonVariantTypes.TEXT,
                ButtonVariantTypes.TERTIARY,
                ButtonVariantTypes.CONTAINED,
              ],
              default: ButtonVariantTypes.CONTAINED,
            },
          },
        },
        {
          propertyName: "buttonColor",
          helpText: "Sets the buttonColor",
          label: "buttonColor",
          controlType: "COLOR_PICKER",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          helpText: "Controls widget orientation",
          propertyName: "orientation",
          label: "Orientation",
          controlType: "ICON_TABS",
          fullWidth: true,
          options: [
            {
              label: "Horizontal",
              value: "horizontal",
            },
            {
              label: "Vertical",
              value: "vertical",
            },
          ],
          defaultValue: "horizontal",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    {
      sectionName: "Border and shadow",
      children: [
        {
          propertyName: "borderRadius",
          label: "Border radius",
          helpText: "Rounds the corners of the icon button's outer border edge",
          controlType: "BORDER_RADIUS_OPTIONS",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          defaultValue: "none",
        },
        {
          propertyName: "boxShadow",
          label: "Box shadow",
          helpText:
            "Enables you to cast a drop shadow from the frame of the widget",
          controlType: "BOX_SHADOW_OPTIONS",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          defaultValue: "none",
        },
      ],
    },
  ],
};
