import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem as MUIMenuItem,
  Badge,
  IconButton,
} from "@mui/material";
import { Button, Icon } from "zds";
import { type ActionItem, MenuItemsSource, type MenuItem } from "../constants";
import { orderBy } from "lodash";
import { ButtonVariantTypes } from "../../ZButtonWidget/constants";

const getVisibleItems = (action: ActionItem) => {
  const { configureMenuItems, menuItems, menuItemsSource, sourceData } = action;
  if (menuItemsSource === MenuItemsSource.STATIC) {
    const visibleItems = Object.keys(menuItems)
      .map((itemKey) => menuItems[itemKey])
      .filter((item) => item.isVisible === true);

    return orderBy(visibleItems, ["index"], ["asc"]);
  } else if (
    menuItemsSource === MenuItemsSource.DYNAMIC &&
    Array.isArray(sourceData) &&
    sourceData.length &&
    configureMenuItems?.config
  ) {
    const { config } = configureMenuItems;
    const getValue = (propertyName: keyof MenuItem, index: number) => {
      const value = config[propertyName];

      if (Array.isArray(value)) {
        return value[index];
      }

      return value ?? null;
    };

    const visibleItems = sourceData
      .map((item, index) => ({
        ...item,
        id: index.toString(),
        isVisible: getValue("isVisible", index),
        isDisabled: getValue("isDisabled", index),
        index: index,
        widgetId: "",
        label: getValue("label", index),
        onClick: config?.onClick,
        icon: getValue("icon", index),
        startIcon: getValue("startIcon", index),
        endIcon: getValue("endIcon", index),
      }))
      .filter((item) => item.isVisible === true);

    return visibleItems;
  }

  return [];
};

const mapButtonVariant = (variant: string) => {
  switch (variant) {
    case "PRIMARY":
      return ButtonVariantTypes.CONTAINED;
    case "SECONDARY":
      return ButtonVariantTypes.TERTIARY;
    case "TERTIARY":
      return ButtonVariantTypes.TEXT;
    default:
      return ButtonVariantTypes.CONTAINED;
  }
};

const RenderAction = ({
  action,
  onButtonClick,
}: {
  action: ActionItem;
  onButtonClick: (onClick: string | undefined) => void;
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const { color, label, onClick, type, value } = action;
  // Typography
  const {
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    surTitle,
    surTitleColor,
    surTitleFontSize,
    surTitleFontWeight,
  } = action;
  // Button
  const {
    badgeContent,
    endIcon,
    icon,
    isDisabled,
    menuWidth,
    startIcon,
    variant,
  } = action;
  // Image
  const { imageHeight = 40, imageWidth = 40 } = action;

  switch (type) {
    case "Typography":
    default:
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {surTitle && (
            <Typography
              sx={{
                fontFamily: fontFamily,
                fontSize:
                  surTitleFontSize && surTitleFontSize !== ""
                    ? surTitleFontSize
                    : fontSize
                      ? `${parseFloat(fontSize) * 0.8}${fontSize.replace(/[0-9.]/g, "") || "px"}` // Extract and retain the unit
                      : "12px", // Smaller size than main text
                fontWeight: surTitleFontWeight,
                color: surTitleColor,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "normal",
                marginBottom: "4px",
              }}
            >
              {surTitle}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            {startIcon && (
              <Box
                sx={{
                  marginRight: "8px",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  "& .MuiIcon-root": { color: color },
                }}
              >
                <Icon body={startIcon} />
              </Box>
            )}
            <Typography
              sx={{
                fontFamily: fontFamily,
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontStyle: fontStyle,
                color: color, // Use mainTextColor if provided, fallback to color
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "normal",
                flexShrink: 1,
                maxWidth: "100%",
              }}
              title={value} // Tooltip for main value
            >
              {value}
            </Typography>
            {endIcon && (
              <Box
                sx={{
                  marginLeft: "8px",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  "& .MuiIcon-root": { color: color },
                }}
              >
                <Icon body={endIcon} />
              </Box>
            )}
          </Box>
        </Box>
      );

    case "Image":
      return (
        <img
          alt="Action"
          src={value}
          style={{ width: imageWidth, height: imageHeight }}
        />
      );
    case "Button":
      if (icon) {
        return (
          <IconButton
            onClick={() => onButtonClick(onClick)}
            size="large"
            sx={{
              "& .MuiIcon-root": { color: color },
            }}
          >
            <Badge badgeContent={badgeContent} color="error" max={99}>
              <Icon body={icon} />
            </Badge>
          </IconButton>
        );
      } else {
        return (
          <Button
            disabled={isDisabled}
            dsOnClick={() => onButtonClick(onClick)}
            endIcon={endIcon}
            icon={icon}
            startIcon={startIcon}
            sx={{
              backgroundColor:
                mapButtonVariant(variant) === ButtonVariantTypes.CONTAINED &&
                !icon &&
                !isDisabled
                  ? `${color} !important`
                  : undefined,
              color:
                mapButtonVariant(variant) !== ButtonVariantTypes.CONTAINED ||
                icon
                  ? color ?? "inherit"
                  : undefined,
              borderColor:
                mapButtonVariant(variant) !== ButtonVariantTypes.CONTAINED
                  ? color ?? "inherit"
                  : undefined,
              fontFamily: "inherit",
              fontWeight: 400,
              textTransform: "unset",
            }}
            variant={mapButtonVariant(variant)}
          >
            {label}
          </Button>
        );
      }

    case "Menu":
      const visibleMenuItems = getVisibleItems(action);
      return (
        <>
          {icon ? (
            <IconButton
              onClick={handleMenuOpen}
              size="large"
              sx={{
                "& .MuiIcon-root": { color: color },
              }}
            >
              <Badge badgeContent={badgeContent} color="error" max={99}>
                <Icon body={icon} />
              </Badge>
            </IconButton>
          ) : (
            <Button
              disabled={isDisabled}
              dsOnClick={handleMenuOpen}
              endIcon={endIcon}
              icon={icon}
              startIcon={startIcon}
              sx={{
                backgroundColor:
                  mapButtonVariant(variant) === ButtonVariantTypes.CONTAINED &&
                  !icon &&
                  !isDisabled
                    ? `${color} !important`
                    : undefined,
                color:
                  mapButtonVariant(variant) !== ButtonVariantTypes.CONTAINED ||
                  icon
                    ? color ?? "inherit"
                    : undefined,
                borderColor:
                  mapButtonVariant(variant) !== ButtonVariantTypes.CONTAINED
                    ? color ?? "inherit"
                    : undefined,
                fontFamily: "inherit",
                fontWeight: 400,
                textTransform: "unset",
              }}
              variant={mapButtonVariant(variant)}
            >
              {label}
            </Button>
          )}
          <Menu
            MenuListProps={{ "aria-labelledby": "basic-button" }}
            anchorEl={menuAnchorEl}
            onClose={handleMenuClose}
            open={Boolean(menuAnchorEl)}
          >
            {visibleMenuItems &&
              visibleMenuItems.map((item, index) => (
                <MUIMenuItem
                  key={index}
                  onClick={() => {
                    onButtonClick(item.onClick);
                    handleMenuClose();
                  }}
                  sx={{ width: menuWidth || "auto" }}
                >
                  {item.startIcon && (
                    <Box
                      sx={{
                        marginRight: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon body={item.startIcon} />
                    </Box>
                  )}
                  {item.label}
                  {item.endIcon && (
                    <Box
                      sx={{
                        marginLeft: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon body={item.endIcon} />
                    </Box>
                  )}
                </MUIMenuItem>
              ))}
          </Menu>
        </>
      );
  }
};
function AppBarComponent(props: AppBarComponentProps) {
  const {
    backgroundColor = "primary",
    height = 64,
    leftActions = {},
    onButtonClick,
    rightActions = {},
  } = props;

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor,
        borderRadius: props.borderRadius,
        boxShadow: props.boxShadow,
        overflow: "hidden",
      }}
    >
      <Toolbar
        sx={{
          "&.MuiToolbar-root": { minHeight: height },
          display: "flex",
          justifyContent: "space-between", // Ensure proper spacing between left and right actions
          overflow: "hidden",
        }}
      >
        {/* Left Actions */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            maxWidth: "50%",
          }}
        >
          {leftActions &&
            Object.values(leftActions).map((action, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden", // Prevent items from overflowing
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  mr: 1,
                }}
              >
                <RenderAction action={action} onButtonClick={onButtonClick} />
              </Box>
            ))}
        </Box>

        {/* Spacer */}
        <Box flexGrow={1} />

        {/* Right Actions */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            maxWidth: "50%",
          }}
        >
          {rightActions &&
            Object.values(rightActions).map((action, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  ml: 1,
                }}
              >
                <RenderAction action={action} onButtonClick={onButtonClick} />
              </Box>
            ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export interface AppBarComponentProps {
  leftActions?: Record<string, ActionItem>;
  rightActions?: Record<string, ActionItem>;
  onButtonClick: (onClick: string | undefined) => void;
  backgroundColor: string;
  height: number;
  borderRadius?: string;
  boxShadow?: string;
}
export default AppBarComponent;
