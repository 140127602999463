import React from "react";

import { ColorPicker } from "@zds3/zds-components";

if (window.customElements.get("z-color-picker") === undefined) {
  window.customElements.define("z-color-picker", ColorPicker);
}

interface ZCard extends HTMLElement {
  close: () => void;
}

interface ColorPickerWrapperProps {
  label: string;
  pickerColor: string;
  hasTint: string;
  onColorsReceived: (color: string, tint: string) => void;
  colorSuggestions: string;
}

const ColorPickerWrapper: React.FC<ColorPickerWrapperProps> = (props) => {
  const { colorSuggestions, hasTint, label, onColorsReceived, pickerColor } =
    props;
  const colorPickerRef = React.useRef<HTMLElement>(null);
  const colorSuggestionsJSON = JSON.stringify(colorSuggestions);

  const handleGetColorsEvent = (event: any) => {
    const { color, tint } = event.detail;
    if (color !== pickerColor) {
      onColorsReceived(color, tint);
    }
  };

  React.useEffect(() => {
    const colorPickerElement = colorPickerRef.current;

    if (colorPickerElement) {
      colorPickerElement.addEventListener(
        "get-colors-event",
        handleGetColorsEvent,
      );

      const handleMouseMove = (event: MouseEvent) => {
        const shadowRoot = colorPickerElement.shadowRoot;
        if (shadowRoot) {
          const card = shadowRoot.querySelector("z-card[data-open]") as ZCard;
          if (card) {
            const rect = card.getBoundingClientRect();
            const isOutside =
              event.clientX < rect.left ||
              event.clientX > rect.right ||
              event.clientY < rect.top ||
              event.clientY > rect.bottom;

            if (isOutside) {
              card.close();
            }
          }
        }
      };

      // Attach mousemove event listener to the document
      document.addEventListener("mousemove", handleMouseMove);

      // Clean up event listeners on unmount
      return () => {
        colorPickerElement.removeEventListener(
          "get-colors-event",
          handleGetColorsEvent,
        );
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [pickerColor, onColorsReceived]);

  return (
    <z-color-picker
      data-color={pickerColor}
      data-colorsuggestions={colorSuggestionsJSON}
      data-hastint={hasTint}
      data-label={label}
      ref={colorPickerRef}
    />
  );
};

export default ColorPickerWrapper;
