import * as React from "react";
import type { AccordionProps as MuiAccordionProps } from "@mui/material";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  useTheme,
  Box,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const TitleBar = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "#0D4AC3",
        width: "2px",
        marginRight: `${theme.spacing(1)}`,
        marginTop: `${theme.spacing(0.5)}`,
        marginBottom: `${theme.spacing(0.5)}`,
      }}
    />
  );
};

export interface AccordionProps
  extends Pick<
    MuiAccordionProps,
    "defaultExpanded" | "expanded" | "elevation" | "disableGutters"
  > {
  dsOnChange?: MuiAccordionProps["onChange"];
  e2e?: string;
  variant?: "fullWidth" | "default";
  header?: React.ReactNode;
  headerAction?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  hideDivider?: boolean;
  hideExpandIcon?: boolean;
  titleBar?: boolean;
  borderedExpandIcon?: boolean;
  outlined?: boolean;
  marginBetweenItems?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  body,
  borderedExpandIcon,
  defaultExpanded,
  disableGutters = false,
  dsOnChange,
  e2e,
  footer,
  header,
  headerAction,
  hideDivider = true,
  hideExpandIcon = false,
  outlined,
  titleBar,
  variant = "fullWidth",
}: AccordionProps) => {
  const isFullWidth = variant === "fullWidth";
  const theme = useTheme();
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <MuiAccordion
      // elevation={elevation}
      defaultExpanded={defaultExpanded}
      expanded={hideExpandIcon ? true : undefined}
      // expanded={expanded}
      onChange={hideExpandIcon ? undefined : dsOnChange}
      // disableGutters={disableGutters}
      {...(e2e && { "data-e2e": e2e })}
      {...(outlined && { variant: "outlined" })}
      sx={{
        ...(!disableGutters && {
          margin: `${theme.spacing(2)} 0`,
          "&:first-child": {
            marginTop: "0",
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "&::before": {
            display: "none",
          },
        }),
      }}
    >
      <AccordionSummary
        expandIcon={
          !hideExpandIcon && (
            <ExpandMore
              {...(e2e && {
                "data-e2e": `${e2e}-expand`,
              })}
            />
          )
        }
        sx={{
          ...(borderedExpandIcon && {
            "& .MuiAccordionSummary-expandIconWrapper": {
              border: `1px solid #b2b2b3`,
              borderRadius: "4px",
              transition: "none",
              width: "1.75rem",
              height: "1.75rem",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "content-box",
            },
          }),
          // ...(isFullWidth && {
          //   minHeight: "48px !important",
          //   height: "48px !important",
          // }),
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "18px 0",
          },
          "& .MuiAccordionSummary-content": {
            margin: "16px 0",
          },
        }}
      >
        <Box
          {...(e2e && { "data-e2e": `${e2e}-header` })}
          sx={{
            display: "flex",
            // width: '100%',
            padding: "4px 0",
          }}
        >
          {titleBar && <TitleBar />}
          <Box sx={{ flexGrow: 1 }}>{header}</Box>
        </Box>
        {headerAction && (
          <Box
            {...(e2e && { "data-e2e": `${e2e}-headerAction` })}
            onClick={stopPropagation} // Add this line
            style={{ paddingRight: theme.spacing(1.5), whiteSpace: "nowrap" }}
          >
            {headerAction}
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ...(isFullWidth && {
            padding: "0 !important",
          }),
          ...(hideDivider && {
            borderTop: "none",
          }),
          marginTop: "-20px",
        }}
        {...(e2e && { "data-e2e": `${e2e}-body` })}
      >
        {body}
      </AccordionDetails>
      <AccordionActions {...(e2e && { "data-e2e": `${e2e}-footer` })}>
        {footer}
      </AccordionActions>
    </MuiAccordion>
  );
};

export default Accordion;
