import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import {
  WidgetHeightLimits,
  WIDGET_TAGS,
  WIDGET_PADDING,
} from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import {
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
} from "widgets/WidgetUtils";
import {
  type AnvilConfig,
  BlueprintOperationTypes,
} from "WidgetProvider/constants";
import { LayoutSystemTypes } from "layoutSystems/types";
import { find, sortBy } from "lodash";
import closeActionConfig from "./propertyConfig/closeActionConfig";
import BuilderHeader from "../component";
import {
  EventType,
  type ExecuteTriggerPayload,
} from "constants/AppsmithActionConstants/ActionConstants";
import {
  type HeaderActionItem,
  MenuItemsSource,
} from "widgets/PageHeaderWidget/constants";
import headerActionConfig from "./propertyConfig/headerActionConfig";
import type { WidgetProperties } from "selectors/propertyPaneSelectors";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import { selectedTabValidation } from "widgets/ZTabsWidget/widget";
import {
  type Alignment,
  LayoutDirection,
  Positioning,
  type Spacing,
} from "layoutSystems/common/utils/constants";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import _ from "lodash";

class ZBuilderHeaderWidget extends BaseWidget<
  ZBuilderHeaderWidgetProps<ZTabContainerWidgetProps>,
  WidgetState
> {
  static type = "ZBUILDERHEADER_WIDGET";

  static getConfig() {
    return {
      name: "ZBuilder Header",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: true,
      isCanvas: true,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 2, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZBuilder Header",
      rows: 10,
      columns: 100,
      version: 1,
      position: "fixed",
      title:
        "New header with a really looooooooooooooooooooooooooooooooooooooooong name",
      description: "Description text",
      appName: "UI Builder",
      maxHeaderActionDisplay: 2,
      tabsObj: {
        tab1: {
          label: "Tab 1",
          id: "tab1",
          widgetId: "tab1",
          isVisible: true,
          index: 0,
          positioning: Positioning.Vertical,
        },
        tab2: {
          label: "Tab 2",
          id: "tab2",
          widgetId: "tab1",
          isVisible: true,
          index: 1,
          positioning: Positioning.Vertical,
        },
      },
      activeTab: "Tab 1",
      defaultTab: "Tab 1",
      closeAction: {
        closeAction1: {
          id: "closeAction1",
          index: 0,
          label: "Back",
          widgetId: "",
          isDisabled: false,
          isVisible: true,
          actionType: "BUTTON",
          variant: "outlined",
          buttonColor: "#ffffff",
          icon: "keyboard_arrow_left",
          onClick:
            "{{navigateTo(appsmith.zSystemVars.ZENV_URL.replace('rest-', '').replace('rest.', '') + '/platform/webapp', {}, 'SAME_WINDOW');}}",
        },
      },
      actions: {},
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              tabId: "tab1",
              tabName: "Tab 1",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              tabId: "tab2",
              tabName: "Tab 2",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
        ],
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              const tabs = Object.values({ ...widget.tabsObj });
              const tabIds: Record<string, string> = (
                widget.children || []
              ).reduce((idsObj, eachChild) => {
                idsObj = { ...idsObj, [eachChild.tabId]: eachChild.widgetId };
                return idsObj;
              }, {});
              const tabsObj = tabs.reduce((obj: any, tab: any) => {
                const newTab = { ...tab };
                newTab.widgetId = tabIds[newTab.id];
                obj[newTab.id] = newTab;
                return obj;
              }, {});
              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "tabsObj",
                  propertyValue: tabsObj,
                },
              ];
              return updatePropertyMap;
            },
          },
        ],
      },
    };
  }

  static getMethods() {
    return {
      // getCanvasHeightOffset: (props: WidgetProps): number => {
      //   const offset =
      //     props.borderWidth && props.borderWidth > 1
      //       ? Math.ceil(
      //           (2 * parseInt(props.borderWidth, 10) || 0) /
      //             GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      //         )
      //       : 0;
      //   return offset + 4;
      // },
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "500px",
            };
          },
        },
      ],
      disableResizeHandles: {
        vertical: true,
      },
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: {},
        minHeight: { base: "500px" },
        minWidth: { base: "280px" },
      },
    };
  }

  static getDependencyMap(): Record<string, string[]> {
    return {
      defaultTab: ["tabsObj", "tabs"],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "Data",
        children: [
          {
            propertyName: "closeAction",
            label: "Close Action",
            controlType: "HEADER_ACTION",
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: closeActionConfig,
          },
          {
            propertyName: "actions",
            label: "Actions",
            controlType: "HEADER_ACTION",
            isBindProperty: false,
            isTriggerProperty: false,
            inputType: "ARRAY",
            panelConfig: headerActionConfig,
          },
          {
            propertyName: "maxHeaderActionDisplay",
            label: "Max Action Display",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            propertyName: "title",
            label: "Title",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "description",
            label: "Description",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "position",
            label: "Position",
            controlType: "DROP_DOWN",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            options: [
              "-webkit-sticky",
              "absolute",
              "fixed",
              "relative",
              "static",
              "sticky",
              "inherit",
              "-moz-initial",
              "initial",
              "revert",
              "revert-layer",
              "unset",
            ].map((option) => ({
              label: option,
              value: option,
            })),
          },
          {
            propertyName: "href",
            label: "Href",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "appName",
            label: "App Name",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          // {
          //   propertyName: "leftSlot",
          //   label: "LeftSlot",
          //   controlType: "INPUT_TEXT",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.TEXT },
          // },
          {
            propertyName: "centerSlot",
            label: "CenterSlot",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },

          // {
          //   propertyName: "statusChip",
          //   label: "StatusChip",
          //   controlType: "INPUT_TEXT",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.TEXT },
          // },
        ],
      },
      {
        sectionName: "Center Slot",
        children: [
          {
            propertyName: "tabsObj",
            isJSConvertible: false,
            label: "Tabs",
            helpText: "Tabs",
            controlType: "TABS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            updateRelatedWidgetProperties: (
              propertyPath: string,
              propertyValue: string,
              props: WidgetProperties,
            ) => {
              const propertyPathSplit = propertyPath.split(".");
              const property = propertyPathSplit.pop();
              if (property === "label") {
                const itemId = propertyPathSplit.pop() || "";
                const item = props.tabsObj[itemId];
                if (item) {
                  return [
                    {
                      widgetId: item.widgetId,
                      updates: {
                        modify: {
                          tabName: propertyValue,
                        },
                      },
                    },
                  ];
                }
              }
              return [];
            },
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              children: [
                {
                  sectionName: "General",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "Visible",
                      helpText: "Controls the visibility of the tab",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                  ],
                },
              ],
            },
          },
          {
            propertyName: "defaultTab",
            helpText: "Selects a tab name specified by default",
            placeholderText: "Tab 1",
            label: "Default tab",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.FUNCTION,
              params: {
                fn: selectedTabValidation,
                expected: {
                  type: "Tab Name (string)",
                  example: "Tab 1",
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
            dependencies: ["tabsObj", "tabs"],
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "Enables scrolling for content inside the widget",
            propertyName: "shouldScrollContents",
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "animateLoading",
            label: "Animate loading",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            helpText: "when the button is clicked",
            propertyName: "onTabSelected",
            label: "onTabSelected",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedTabWidgetId: undefined,
    };
  }

  callDynamicHeightUpdates = () => {
    const { checkContainersForAutoHeight } = this.context;
    checkContainersForAutoHeight && checkContainersForAutoHeight();
  };

  callPositionUpdates = (tabWidgetId: string) => {
    const { updatePositionsOnTabChange } = this.context;
    updatePositionsOnTabChange &&
      updatePositionsOnTabChange(this.props.widgetId, tabWidgetId);
  };

  handleCloseClick = (onClick: string | undefined) => {
    if (onClick) {
      const config: ExecuteTriggerPayload = {
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      };
      super.executeAction(config);
    }
  };

  handleClick = (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ): void => {
    if (onClick) {
      const config: ExecuteTriggerPayload = {
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      };

      if (
        action &&
        action.actionType === "MENU" &&
        action.menuItemsSource === MenuItemsSource.DYNAMIC
      ) {
        config.globalContext = {
          currentItem:
            action.sourceData && index !== undefined
              ? action.sourceData[index]
              : {},
          currentIndex: index,
        };
      }

      super.executeAction(config);
    }
  };

  handleOnchange = (
    onChange: string | undefined,
    propertyPath?: string,
    value?: any,
  ): void => {
    if (propertyPath && value != undefined) {
      this.updateWidgetProperty(propertyPath, value);
      // this.forceUpdate();
    }
    if (onChange) {
      super.executeAction({
        triggerPropertyName: "onChange",
        dynamicString: onChange,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          selectedValue: value,
        },
      });
    }
  };

  renderComponent = () => {
    if (!this.props.tabsObj || _.isEmpty(this.props.tabsObj)) {
      return null;
    }
    const selectedTabWidgetId = this.getSelectedTabWidgetId();
    const childWidgetData = {
      ...this.props.children?.filter(Boolean).filter((item) => {
        return selectedTabWidgetId === item.widgetId;
      })[0],
    };
    if (!childWidgetData) {
      return null;
    }

    childWidgetData.canExtend = this.props.shouldScrollContents;
    const { componentHeight, componentWidth } = this.props;
    childWidgetData.containerStyle = "none";
    childWidgetData.rightColumn = componentWidth + WIDGET_PADDING * 3;
    childWidgetData.isVisible = this.props.isVisible;
    childWidgetData.bottomRow = this.props.shouldScrollContents
      ? childWidgetData.bottomRow
      : componentHeight - 1;
    childWidgetData.parentId = this.props.widgetId;
    childWidgetData.minHeight = componentHeight;
    const selectedTabProps = Object.values(this.props.tabsObj)?.filter(
      (item) => item.widgetId === selectedTabWidgetId,
    )[0];
    const positioning: Positioning =
      this.props.layoutSystemType == LayoutSystemTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childWidgetData.positioning = positioning;
    childWidgetData.useAutoLayout = positioning !== Positioning.Fixed;
    childWidgetData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    childWidgetData.alignment = selectedTabProps?.alignment;
    childWidgetData.spacing = selectedTabProps?.spacing;
    return renderAppsmithCanvas(childWidgetData as WidgetProps);
  };

  private getSelectedTabWidgetId() {
    let selectedTabWidgetId = this.props.selectedTabWidgetId;
    if (this.props.children) {
      selectedTabWidgetId =
        this.props.children.find((tab) =>
          this.props.selectedWidgetAncestry?.includes(tab.widgetId),
        )?.widgetId ?? this.props.selectedTabWidgetId;
    }
    return selectedTabWidgetId;
  }

  getVisibleTabs = () => {
    const tabs = Object.values(this.props.tabsObj || {});
    if (tabs.length) {
      return tabs
        .filter((tab) => !!tab.isVisible === true)
        .sort((tab1, tab2) => tab1.index - tab2.index);
    }
    return [];
  };

  onTabChange = (activeTabLabel: string) => {
    if (activeTabLabel) {
      this.updateWidgetProperty("activeTab", activeTabLabel);
    }
    const visibleTabs = this.getVisibleTabs();
    const activeTab = find(visibleTabs, {
      label: activeTabLabel,
    });
    if (activeTab) {
      this.props.updateWidgetMetaProperty(
        "selectedTabWidgetId",
        activeTab.widgetId,
        {
          triggerPropertyName: "onTabSelected",
          dynamicString: this.props.onTabSelected,
          event: {
            type: EventType.ON_TAB_CHANGE,
          },
        },
      );
      setTimeout(this.callDynamicHeightUpdates, 0);
      setTimeout(() => this.callPositionUpdates(activeTab.widgetId), 0);
    }
  };

  getVisibleActions = () => {
    const actions = this.props.actions;
    if (!actions) return {};

    let items = Object.keys(actions)
      .map((itemKey) => actions[itemKey])
      .filter((item) => item.isVisible === true);
    items = sortBy(items, ["index"]);
    return items;
  };

  getWidgetView() {
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);

    const ZBuilderHeaderWidgetProps = {
      ...this.props,
      handleCloseClick: this.handleCloseClick,
      buttonClickHandler: this.handleClick,
      handleOnchange: this.handleOnchange,
      $noScroll: isAutoHeightEnabled,
      shouldScrollContents:
        this.props.shouldScrollContents &&
        this.props.layoutSystemType === LayoutSystemTypes.FIXED,
      activeTab: this.props.activeTab,
      tabs: this.getVisibleTabs(),
      onTabChange: this.onTabChange,
      actions: this.getVisibleActions(),
    };

    return (
      <BuilderHeader {...ZBuilderHeaderWidgetProps}>
        {this.renderComponent()}
      </BuilderHeader>
    );
  }

  setDefaultSelectedTabWidgetId = () => {
    const visibleTabs = this.getVisibleTabs();
    // Find the default Tab object
    const defaultTab = find(visibleTabs, {
      label: this.props.defaultTab,
    });
    // Find the default Tab id
    const defaultTabWidgetId =
      defaultTab?.widgetId ?? visibleTabs?.[0]?.widgetId; // in case the default tab is deleted

    const defaultTabLabel = defaultTab?.label ?? visibleTabs?.[0]?.label;
    // If we have a legitimate default tab Id and it is not already the selected Tab
    if (
      defaultTabWidgetId &&
      defaultTabWidgetId !== this.props.selectedTabWidgetId
    ) {
      // Select the default tab
      this.props.updateWidgetMetaProperty(
        "selectedTabWidgetId",
        defaultTabWidgetId,
      );
      //change the active tab to defaultTab
      this.updateWidgetProperty("activeTab", defaultTabLabel);
      setTimeout(this.callDynamicHeightUpdates, 0);
    }
  };

  componentDidUpdate(
    prevProps: ZBuilderHeaderWidgetProps<ZTabContainerWidgetProps>,
  ) {
    if (prevProps.activeTab !== this.props.activeTab) {
      const visibleTabs = this.getVisibleTabs();
      const activeTab = find(visibleTabs, {
        label: this.props.activeTab,
      });
      if (activeTab) {
        this.props.updateWidgetMetaProperty(
          "selectedTabWidgetId",
          activeTab.widgetId,
        );
        setTimeout(this.callDynamicHeightUpdates, 0);
        setTimeout(() => this.callPositionUpdates(activeTab.widgetId), 0);
      }
    }
  }

  componentDidMount() {
    Object.keys(this.props.tabsObj || {}).length &&
      this.setDefaultSelectedTabWidgetId();
  }
}

export interface ZTabContainerWidgetProps extends WidgetProps {
  tabId: string;
}

export interface ZBuilderHeaderWidgetProps<T extends ZTabContainerWidgetProps>
  extends WidgetProps {
  closeAction: Record<string, HeaderActionItem>;
  actions: Record<string, HeaderActionItem>;
  title: string;
  description: string | React.ReactNode;
  position: React.CSSProperties["position"];
  href: string;
  leftSlot: React.ReactNode;
  centerSlot: React.ReactNode;
  appName: string;
  // statusChip: ChipProps;
  shouldScrollContents: boolean;
  tabs: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
    positioning: Positioning;
  }>;
  tabsObj: Record<
    string,
    {
      id: string;
      label: string;
      widgetId: string;
      isVisible?: boolean;
      index: number;
      positioning: Positioning;
      alignment: Alignment;
      spacing: Spacing;
    }
  >;
  children: T[];
}

export default ZBuilderHeaderWidget;
