import React, { useContext, useState } from "react";
import DOMPurify from "dompurify";

import type { BaseFieldComponentProps } from "../constants";
import FieldLabel, { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import LinesEllipsis from "react-lines-ellipsis";
import moment from "moment";
import { Popover, Typography, Icon, type AllIconsType } from "zds";
import { FontStyleTypes } from "../../../constants/WidgetConstants";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import FormContext from "../FormContext";

interface TextFieldComponentProps {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: string;
  cellBackgroundColor?: string;
  cellBorderColor?: string;
  cellBorderWidth?: number;
  cellBorderRadius?: string;
  cellBoxShadow?: string;
  accentColor?: string;
  defaultValue?: any[];
  isCollapsible: boolean;
  detailName?: string;
  detailTitle?: string;
  detailContent?: string;
  startIcon?: AllIconsType;
  iconColor?: string;
  onClick?: string;
}

export type TextFieldProps = BaseFieldComponentProps<TextFieldComponentProps>;

const COMPONENT_DEFAULT_VALUES = {
  isDisabled: true,
  isRequired: false,
  isSpellCheck: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
  isChecked: true,
};

function TextField({
  inlineLabelParent,
  labelAlignmentParent,
  labelStyleParent,
  labelTextColorParent,
  labelTextSizeParent,
  labelWidthParent,
  schemaItem,
}: TextFieldProps) {
  const { executeAction } = useContext(FormContext);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const sanitizeHtml = (htmlContent: string | undefined): string => {
    return DOMPurify.sanitize(htmlContent || "");
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setIsHovered(false);
  };

  const open = Boolean(anchorEl ?? undefined);
  const validPopoverName =
    schemaItem.detailName && schemaItem.detailName.trim().length > 0;
  const showPopover =
    (schemaItem.detailTitle && schemaItem.detailTitle.trim().length > 0) ||
    (schemaItem.detailContent && schemaItem.detailContent.trim().length > 0);

  let defaultValue =
    typeof schemaItem.defaultValue === "string"
      ? schemaItem.defaultValue
      : String(schemaItem.defaultValue);
  if (
    !schemaItem.enableNullValues &&
    (defaultValue === "" ||
      defaultValue === "null" ||
      defaultValue === "undefined")
  ) {
    defaultValue = "--";
  }

  if (schemaItem.dataType === "boolean" && !schemaItem.toggleBooleans) {
    defaultValue = schemaItem.defaultValue ? "Yes" : "No";
  }

  if (moment(defaultValue, moment.ISO_8601, true).isValid()) {
    defaultValue = moment(defaultValue).format("YYYY-MM-DD");
  }

  const {
    inlineLabel,
    label,
    labelAlignment,
    labelStyle,
    labelTextColor,
    labelTextSize,
    labelWidth,
    tooltip,
    useDefaultStyles,
  } = schemaItem;

  // Define the onClick event callback
  const onClickHandler = () => {
    if (schemaItem.onClick) {
      executeAction({
        triggerPropertyName: "onClick",
        dynamicString: schemaItem.onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }
  };

  // Define the style object conditionally outside the JSX
  const ellipsisContainerStyle = {
    flex: "0 1 auto",
    minWidth: 0,
    overflow: "hidden",
    wordWrap: "break-word" as const,
    width: "100%",
    display: "flex",
    cursor: schemaItem.onClick ? "pointer" : "auto",
  };

  const renderStartIcon = () => (
    <div
      style={{
        color: schemaItem.iconColor,
        marginRight: "8px",
        fontSize: schemaItem.valueTextSize || BASE_LABEL_TEXT_SIZE,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        body={schemaItem.startIcon as AllIconsType}
        color={"inherit"}
        fontSize={"inherit"}
      />
    </div>
  );

  const renderPopoverName = () => (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        color: "blue",
        cursor: "pointer",
        textDecoration: isHovered ? "underline" : "none",
        whiteSpace: "nowrap",
        marginLeft: "5px",
        flexShrink: 0,
      }}
    >
      [{schemaItem.detailName}]
    </span>
  );

  const renderPopover = () => (
    <Popover
      a11yId="mouse-over-popover"
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      arrow
      body={
        <div>
          <Typography variant="body1">{schemaItem.detailTitle}</Typography>
          <div style={{ marginBottom: "16px" }} />
          <Typography variant="body2">
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(schemaItem.detailContent),
              }}
            />
          </Typography>
        </div>
      }
      disableRestoreFocus
      dsOnClose={handleMouseLeave}
      open={open}
      paperProps={{
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }}
      pointerEventsNone
      transformOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
    />
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: (useDefaultStyles ? inlineLabelParent : inlineLabel)
            ? "row"
            : "column",
        }}
      >
        <FieldLabel
          alignment={useDefaultStyles ? labelAlignmentParent : labelAlignment}
          direction={
            (useDefaultStyles ? inlineLabelParent : inlineLabel)
              ? "row"
              : "column"
          }
          label={label}
          labelStyle={useDefaultStyles ? labelStyleParent : labelStyle}
          labelTextColor={
            useDefaultStyles ? labelTextColorParent : labelTextColor
          }
          labelTextSize={useDefaultStyles ? labelTextSizeParent : labelTextSize}
          tooltip={tooltip}
          width={useDefaultStyles ? labelWidthParent : labelWidth}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flex: 1,
            overflowX: "hidden",
            // flexGrow: 0,
          }}
        >
          <div
            onClick={schemaItem.onClick ? onClickHandler : undefined}
            onMouseEnter={!validPopoverName ? handleMouseEnter : undefined}
            onMouseLeave={!validPopoverName ? handleMouseLeave : undefined}
            style={ellipsisContainerStyle}
          >
            {schemaItem.startIcon && renderStartIcon()}
            <LinesEllipsis
              basedOn="words"
              maxLine={schemaItem.linesDisplayed || 1}
              style={{
                flexGrow: 1,
                width: "100%",
                display: "-webkit-box",
                WebkitLineClamp: schemaItem.linesDisplayed || 1,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: schemaItem.valueTextColor,
                fontSize: schemaItem.valueTextSize || BASE_LABEL_TEXT_SIZE,
                fontWeight: schemaItem.valueStyle?.includes(FontStyleTypes.BOLD)
                  ? "600"
                  : "normal",
                fontStyle: schemaItem.valueStyle?.includes(
                  FontStyleTypes.ITALIC,
                )
                  ? "italic"
                  : "",
              }}
              text={defaultValue}
              trimRight
            />
          </div>
          {validPopoverName && renderPopoverName()}
          {showPopover && renderPopover()}
        </div>
      </div>
    </div>
  );
}

TextField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default TextField;
