export const objectArrayValidation = (value: unknown) => {
  /*
   * Validation rules
   *  1. Can be a string.
   *  2. Can be an Array of string, number, boolean (only for option Value).
   */

  // if (value === "" || _.isNil(value)) {
  //   return {
  //     parsed: "",
  //     isValid: false,
  //     messages: [
  //       {
  //         name: "ValidationError",
  //         message: `value does not evaluate to type:  Object | Array`,
  //       },
  //     ],
  //   };
  // }

  // if (_.isObject(value) || _.isArray(value)) {
  //   return {
  //     parsed: value,
  //     isValid: true,
  //     messages: [],
  //   };
  // } else {
  //   return {
  //     parsed: "",
  //     isValid: false,
  //     messages: [
  //       {
  //         name: "ValidationError",
  //         message: "value does not evaluate to type: Object | Array",
  //       },
  //     ],
  //   };
  // }

  return {
    parsed: value,
    isValid: true,
    messages: [],
  };
};
