import { ValidationTypes } from "constants/WidgetValidation";
import { get } from "lodash";
import type { PageHeaderWidgetProps } from "widgets/PageHeaderWidget/constants";
import type { WidgetProps } from "widgets/BaseWidget";
import { getBasePropertyPath } from "widgets/TableWidget/widget/propertyUtils";

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  updateHook: (props: any, propertyPath: string, propertyValue: string) => {
    return [
      {
        propertyPath,
        propertyValue,
      },
    ];
  },
  contentChildren: [
    {
      sectionName: "Label",
      children: [
        {
          propertyName: "label",
          helpText: "Sets the label of a menu item",
          label: "Label",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter label",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "icon",
          label: "Icon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
      ],
    },
    {
      sectionName: "General",
      children: [
        {
          propertyName: "isVisible",
          helpText: "Controls the visibility of the widget",
          label: "Visible",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "isDisabled",
          helpText: "Disables input to the widget",
          label: "Disabled",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
      ],
    },
    {
      sectionName: "Events",
      children: [
        {
          propertyName: "onClick",
          label: "onClick",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
        },
      ],
    },
  ],
  styleChildren: [],
};
