import { getSchemaItem } from "../helper";
import type { HiddenFnParams } from "../helper";
import { FieldType } from "widgets/ZJsonSummaryWidget/constants";
import { ValidationTypes } from "constants/WidgetValidation";

const PROPERTIES = {
  content: {
    detail: [
      {
        propertyName: "detailName",
        helpText: "Show additional info related to the current field",
        label: "Name",
        controlType: "INPUT_TEXT",
        placeholderText: "View Details",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "detailTitle",
        helpText: "Title to be displayed when the popover opens.",
        label: "Title",
        controlType: "INPUT_TEXT",
        placeholderText: "Popover title",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "detailContent",
        helpText: "Content to be displayed when the popover opens.",
        label: "Content",
        controlType: "INPUT_TEXT",
        placeholderText: "Popover content.",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
    ],
    events: [
      {
        helpText: "when clicked",
        propertyName: "onClick",
        label: "onClick",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
    ],
  },
};

export default PROPERTIES;
