import React from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import ColorPickerComponent from "../component";

import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
  AutoLayoutConfig,
} from "WidgetProvider/constants";
import derivedProperties from "./parseDerivedProperties";
import ThumbnailSVG from "../zuora.svg";

class ColorPickerWidget extends BaseWidget<
  ColorPickerWidgetProps,
  WidgetState
> {
  static type = "COLORPICKER_WIDGET";

  static getConfig() {
    return {
      name: "ColorPicker", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      tags: [WIDGET_TAGS.ZUORA],
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getDefaults() {
    return {
      widgetName: "ColorPicker",
      rows: 1,
      columns: 3,
      version: 1,
      color: "#526d89",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [],
      },
    ];
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return () => ({
      selectedColor: "string",
    });
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: {},
        minHeight: { base: "30px" },
        minWidth: { base: "100px" },
      },
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap() {
    return {
      selectedColor: `{{(()=>{${derivedProperties.getSelectedColor}})()}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getSelectedColor = () => {
    const selectedColor = this.props.color || "#526d89";
    return selectedColor;
  };

  handleColorChange = (newColor: string) => {
    this.updateWidgetProperty("color", newColor);
  };

  getWidgetView() {
    return (
      <ColorPickerComponent
        color={this.getSelectedColor()}
        handleColorChange={this.handleColorChange}
      />
    );
  }
}

export interface ColorPickerWidgetProps extends WidgetProps {}

export default ColorPickerWidget;
