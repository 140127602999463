// this format map is same with the map defined in billing service
export default {
  ja_JP: "YYYY/MM/DD",
  es_PE: "DD/MM/YYYY",
  es_CU: "DD/MM/YYYY",
  es_PA: "MM/DD/YYYY",
  sr_BA: "YYYY-MM-DD",
  es_GT: "DD/MM/YYYY",
  ar_AE: "DD/MM/YYYY",
  no_NO: "DD.MM.YYYY",
  sq_AL: "YYYY-MM-DD",
  ar_IQ: "DD/MM/YYYY",
  ar_YE: "DD/MM/YYYY",
  pt_PT: "DD-MM-YYYY",
  el_CY: "DD/MM/YYYY",
  ar_QA: "DD/MM/YYYY",
  mk_MK: "DD.MM.YYYY",
  de_CH: "DD.MM.YYYY",
  en_US: "MM/DD/YYYY",
  fi_FI: "DD.MM.YYYY",
  en_MT: "DD/MM/YYYY",
  sl_SI: "DD.MM.YYYY",
  sk_SK: "DD.MM.YYYY",
  tr_TR: "DD.MM.YYYY",
  ar_SA: "DD/MM/YYYY",
  en_GB: "DD/MM/YYYY",
  sr_CS: "DD.MM.YYYY.",
  en_NZ: "DD/MM/YYYY",
  es_NI: "MM-DD-YYYY",
  ga_IE: "DD/MM/YYYY",
  fr_BE: "DD/MM/YYYY",
  es_ES: "DD/MM/YYYY",
  ar_LB: "DD/MM/YYYY",
  fr_CA: "YYYY-MM-DD",
  et_EE: "DD.MM.YYYY",
  ar_KW: "DD/MM/YYYY",
  sr_RS: "DD.MM.YYYY.",
  es_US: "MM/DD/YYYY",
  es_MX: "DD/MM/YYYY",
  ar_SD: "DD/MM/YYYY",
  in_ID: "DD/MM/YYYY",
  es_UY: "DD/MM/YYYY",
  lv_LV: "YYYY.DD.MM",
  pt_BR: "DD/MM/YYYY",
  ar_SY: "DD/MM/YYYY",
  es_DO: "MM/DD/YYYY",
  fr_CH: "DD.MM.YYYY",
  es_VE: "DD/MM/YYYY",
  ar_BH: "DD/MM/YYYY",
  en_PH: "MM/DD/YYYY",
  ar_TN: "DD/MM/YYYY",
  de_AT: "DD.MM.YYYY",
  nl_NL: "DD-MM-YYYY",
  es_EC: "DD/MM/YYYY",
  zh_TW: "YYYY/MM/DD",
  ar_JO: "DD/MM/YYYY",
  is_IS: "DD.MM.YYYY",
  es_CO: "DD/MM/YYYY",
  es_CR: "DD/MM/YYYY",
  es_CL: "DD-MM-YYYY",
  ar_EG: "DD/MM/YYYY",
  en_ZA: "YYYY/MM/DD",
  th_TH: "DD/MM/YYYY",
  el_GR: "DD/MM/YYYY",
  it_IT: "DD/MM/YYYY",
  hu_HU: "YYYY.MM.DD.",
  en_IE: "DD/MM/YYYY",
  uk_UA: "DD.MM.YYYY",
  pl_PL: "DD.MM.YYYY",
  fr_LU: "DD/MM/YYYY",
  nl_BE: "DD/MM/YYYY",
  en_IN: "DD/MM/YYYY",
  ca_ES: "DD/MM/YYYY",
  ar_MA: "DD/MM/YYYY",
  es_BO: "DD-MM-YYYY",
  en_AU: "DD/MM/YYYY",
  zh_SG: "DD/MM/YYYY",
  es_SV: "MM-DD-YYYY",
  ru_RU: "DD.MM.YYYY",
  ko_KR: "YYYY. MM. DD",
  ar_DZ: "DD/MM/YYYY",
  vi_VN: "DD/MM/YYYY",
  sr_ME: "DD.MM.YYYY.",
  ar_LY: "DD/MM/YYYY",
  zh_CN: "YYYY-MM-DD",
  be_BY: "DD.MM.YYYY",
  iw_IL: "DD/MM/YYYY",
  bg_BG: "YYYY-MM-DD",
  mt_MT: "DD/MM/YYYY",
  es_PY: "DD/MM/YYYY",
  fr_FR: "DD/MM/YYYY",
  cs_CZ: "DD.MM.YYYY",
  it_CH: "DD.MM.YYYY",
  ro_RO: "DD.MM.YYYY",
  es_PR: "MM-DD-YYYY",
  en_CA: "DD/MM/YYYY",
  de_DE: "DD.MM.YYYY",
  de_GR: "DD.MM.YYYY",
  de_LU: "DD.MM.YYYY",
  es_AR: "DD/MM/YYYY",
  ms_MY: "DD/MM/YYYY",
  en_SG: "MM/DD/YYYY",
  ar_OM: "DD/MM/YYYY",
  sv_SE: "YYYY-MM-DD",
  da_DK: "DD-MM-YYYY",
  es_HN: "MM-DD-YYYY",
  lt_LT: "YYYY-MM-DD",
  hr_HR: "DD.MM.YYYY.",
};
