import React, { useMemo } from "react";
import styled from "styled-components";
import type { ControllerRenderProps } from "react-hook-form";
import { sortBy } from "lodash";

import Accordion from "../component/Accordion";
import FieldLabel, { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import FieldRenderer from "./FieldRenderer";
import NestedFormWrapper from "../component/NestedFormWrapper";
import useUpdateAccessor from "./useObserveAccessor";
import type {
  BaseFieldComponentProps,
  FieldComponent,
  FieldComponentBaseProps,
} from "../constants";
import { Collapse } from "@mui/material";
import { Button } from "zds";

type ObjectComponentProps = FieldComponentBaseProps & {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: string;
  cellBackgroundColor?: string;
  cellBorderColor?: string;
  cellBorderWidth?: number;
  cellBorderRadius?: string;
  cellBoxShadow?: string;
};

// Note: Do not use ControllerRenderProps["name"] here for name, as it causes TS stack overflow
type ObjectFieldProps = Omit<
  BaseFieldComponentProps<ObjectComponentProps>,
  "name"
> & {
  hideAccordion?: boolean;
  name: string;
};

interface StyledWrapperProps {
  withBottomMargin: boolean;
}

const COMPONENT_DEFAULT_VALUES: ObjectComponentProps = {
  isDisabled: false,
  isRequired: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
  isChecked: true,
};

const StyledFieldsWrapper = styled.div`
  padding-top: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`;

const DataSummaryItem = styled.div<{ $columns: number }>`
  flex: 0 0 100%;
  @media only screen and (min-width: 600px) {
    flex: 0 0 ${({ $columns }) => $columns}%;
  }
  overflow: hidden;
`;

const DataSummaryItemInner = styled.div<{
  margin?: string | null;
}>`
  margin-bottom: ${({ margin }) => margin || "28px"};
  margin-right: ${({ margin }) => margin || "24px"};
`;

const StyledWrapper = styled.div<StyledWrapperProps>``;

function ObjectField({
  fieldClassName,
  hideAccordion = false,
  hideLabel,
  inlineLabelParent,
  isRootField = false,
  itemInnerMargin,
  labelAlignmentParent,
  labelStyleParent,
  labelTextColorParent,
  labelTextSizeParent,
  labelWidthParent,
  maxColumns = 4,
  name,
  passedDefaultValue,
  propertyPath,
  schemaItem,
}: ObjectFieldProps) {
  const {
    accessor,
    backgroundColor,
    isVisible = true,
    label,
    tooltip,
  } = schemaItem;

  const [showAdditionalFields, setShowAdditionalFields] = React.useState(false);

  useUpdateAccessor({ accessor });

  const objectPassedDefaultValue = useMemo(() => {
    let defaultValue: Record<string, unknown> = {};
    if (passedDefaultValue && typeof passedDefaultValue === "object") {
      defaultValue = passedDefaultValue as Record<string, unknown>;
    }

    return defaultValue;
  }, [passedDefaultValue]);

  const fields = useMemo(() => {
    const children = Object.values(schemaItem.children);
    const sortedChildren = sortBy(children, ({ position }) => position);

    return sortedChildren.map((schemaItem) => {
      const fieldName = name
        ? `${name}.${schemaItem.identifier}`
        : schemaItem.identifier;
      const fieldPropertyPath = `${propertyPath}.children.${schemaItem.identifier}`;
      if (!schemaItem.isVisible) {
        return null;
      }
      if (isRootField && !schemaItem.isChecked) {
        return null;
      }

      return (
        <DataSummaryItem
          $columns={Math.min(
            100,
            ((schemaItem.columnSpan || 1) / maxColumns) * 100,
          )}
          key={`${schemaItem.identifier}-itemcontainer`}
        >
          <DataSummaryItemInner
            margin={itemInnerMargin ? `${8 * itemInnerMargin}px` : null}
          >
            <FieldRenderer
              fieldName={fieldName as ControllerRenderProps["name"]}
              inlineLabelParent={inlineLabelParent}
              key={schemaItem.identifier}
              labelAlignmentParent={labelAlignmentParent}
              labelStyleParent={labelStyleParent}
              labelTextColorParent={labelTextColorParent}
              labelTextSizeParent={labelTextSizeParent}
              labelWidthParent={labelWidthParent}
              passedDefaultValue={objectPassedDefaultValue[schemaItem.accessor]}
              propertyPath={fieldPropertyPath}
              schemaItem={schemaItem}
            />
          </DataSummaryItemInner>
        </DataSummaryItem>
      );
    });
  }, [
    schemaItem,
    name,
    schemaItem.identifier,
    propertyPath,
    objectPassedDefaultValue,
    maxColumns,
    itemInnerMargin,
    isRootField,
    inlineLabelParent,
    labelAlignmentParent,
    labelStyleParent,
    labelTextColorParent,
    labelTextSizeParent,
    labelWidthParent,
  ]);

  const additionalFields = useMemo(() => {
    if (!isRootField) {
      return null;
    }

    const children = Object.values(schemaItem.children);
    const sortedChildren = sortBy(children, ({ position }) => position);

    return sortedChildren.map((schemaItem) => {
      const fieldName = name
        ? `${name}.${schemaItem.identifier}`
        : schemaItem.identifier;
      const fieldPropertyPath = `${propertyPath}.children.${schemaItem.identifier}`;
      if (!schemaItem.isVisible) {
        return null;
      }
      if (schemaItem.isChecked) {
        return null;
      }

      return (
        <DataSummaryItem
          $columns={Math.min(
            100,
            ((schemaItem.columnSpan || 1) / maxColumns) * 100,
          )}
          key={`${schemaItem.identifier}-itemcontainer`}
        >
          <DataSummaryItemInner
            margin={itemInnerMargin ? `${8 * itemInnerMargin}px` : null}
          >
            <FieldRenderer
              fieldName={fieldName as ControllerRenderProps["name"]}
              inlineLabelParent={inlineLabelParent}
              key={schemaItem.identifier}
              labelAlignmentParent={labelAlignmentParent}
              labelStyleParent={labelStyleParent}
              labelTextColorParent={labelTextColorParent}
              labelTextSizeParent={labelTextSizeParent}
              labelWidthParent={labelWidthParent}
              passedDefaultValue={objectPassedDefaultValue[schemaItem.accessor]}
              propertyPath={fieldPropertyPath}
              schemaItem={schemaItem}
            />
          </DataSummaryItemInner>
        </DataSummaryItem>
      );
    });
  }, [
    schemaItem,
    name,
    schemaItem.identifier,
    propertyPath,
    objectPassedDefaultValue,
    maxColumns,
    itemInnerMargin,
    isRootField,
    inlineLabelParent,
    labelAlignmentParent,
    labelStyleParent,
    labelTextColorParent,
    labelTextSizeParent,
    labelWidthParent,
  ]);

  if (!isVisible) {
    return null;
  }

  const field = <StyledFieldsWrapper>{fields}</StyledFieldsWrapper>;
  const {
    inlineLabel,
    labelAlignment,
    labelStyle,
    labelTextColor,
    labelTextSize,
    labelWidth,
    useDefaultStyles,
  } = schemaItem;

  const additionalField = (
    <StyledFieldsWrapper>{additionalFields}</StyledFieldsWrapper>
  );

  return (
    <StyledWrapper
      className={`t--jsonformfield-${fieldClassName}`}
      withBottomMargin={!hideAccordion}
    >
      <NestedFormWrapper
        backgroundColor={isRootField ? "transparent" : backgroundColor}
        borderColor={schemaItem.borderColor}
        borderRadius={schemaItem.borderRadius}
        borderWidth={schemaItem.borderWidth}
        boxShadow={schemaItem.boxShadow}
        withoutPadding={isRootField}
      >
        {!hideLabel && (
          <FieldLabel
            alignment={useDefaultStyles ? labelAlignmentParent : labelAlignment}
            direction={
              (useDefaultStyles ? inlineLabelParent : inlineLabel)
                ? "row"
                : "column"
            }
            label={label}
            labelStyle={useDefaultStyles ? labelStyleParent : labelStyle}
            labelTextColor={
              useDefaultStyles ? labelTextColorParent : labelTextColor
            }
            labelTextSize={
              useDefaultStyles ? labelTextSizeParent : labelTextSize
            }
            tooltip={tooltip}
            width={useDefaultStyles ? labelWidthParent : labelWidth}
          />
        )}
        {isRootField || hideAccordion ? (
          field
        ) : (
          <Accordion
            backgroundColor={schemaItem.cellBackgroundColor}
            borderColor={schemaItem.cellBorderColor}
            borderRadius={schemaItem.cellBorderRadius}
            borderWidth={schemaItem.cellBorderWidth}
            boxShadow={schemaItem.cellBoxShadow}
            isCollapsible={false}
          >
            {field}
          </Accordion>
        )}
      </NestedFormWrapper>
      {isRootField &&
        !additionalFields?.every((element) => element === null) && (
          <Button
            dsOnClick={() => setShowAdditionalFields(!showAdditionalFields)}
            startIcon={showAdditionalFields ? "arrow_drop_down" : "arrow_right"}
            sx={{
              color: "rgb(13, 74, 195)",
              textTransform: "capitalize",
              fontSize: "13px",
              letterSpacing: "0.1px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "inherit",
            }}
            variant="text"
          >
            Additional Fields
          </Button>
        )}
      {isRootField && (
        <Collapse in={showAdditionalFields}>{additionalField}</Collapse>
      )}
    </StyledWrapper>
  );
}

const MemoedObjectField: FieldComponent = React.memo(ObjectField);
MemoedObjectField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default MemoedObjectField;
