import React, { useMemo } from "react";
import styled from "styled-components";
import type { ControllerRenderProps } from "react-hook-form";
import { sortBy } from "lodash";

import FieldLabel, { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import FieldRenderer from "./FieldRenderer";
import useUpdateAccessor from "./useObserveAccessor";
import { FORM_PADDING_X, FORM_PADDING_Y } from "../component/styleConstants";
import type {
  BaseFieldComponentProps,
  FieldComponent,
  FieldComponentBaseProps,
} from "../constants";
import { Popover, Typography } from "zds";
import { Link } from "@appsmith/ads";
import { FontStyleTypes } from "../../../constants/WidgetConstants";
import LinesEllipsis from "react-lines-ellipsis";

type ObjectComponentProps = FieldComponentBaseProps & {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: string;
  cellBackgroundColor?: string;
  cellBorderColor?: string;
  cellBorderWidth?: number;
  cellBorderRadius?: string;
  cellBoxShadow?: string;
};

// Note: Do not use ControllerRenderProps["name"] here for name, as it causes TS stack overflow
type ObjectFieldProps = Omit<
  BaseFieldComponentProps<ObjectComponentProps>,
  "name"
> & {
  hideAccordion?: boolean;
  name: string;
};

const COMPONENT_DEFAULT_VALUES: ObjectComponentProps = {
  isDisabled: false,
  isRequired: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
  isChecked: true,
};

const StyledFieldsWrapper = styled.div`
  padding-top: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`;

interface StyledFormProps {
  fixedFooter: boolean;
  scrollContents: boolean;
}

const StyledForm = styled.form<StyledFormProps>`
  overflow-y: ${({ scrollContents }) => (scrollContents ? "auto" : "hidden")};
  width: 600px;
  max-height: 50vh;
`;

interface StyledFooterProps {
  backgroundColor?: string;
}

const StyledFormFooter = styled.div<StyledFooterProps>`
  bottom: 0;
  display: flex;
  //padding: 0px ${FORM_PADDING_X}px ${FORM_PADDING_Y}px;
  width: 100%;
`;

const DataSummaryItem = styled.div<{ $columns: number }>`
  flex: 0 0 100%;
  @media only screen and (min-width: 600px) {
    flex: 0 0 ${({ $columns }) => $columns}%;
  }
  overflow: hidden;
`;

const DataSummaryItemInner = styled.div<{
  margin?: string | null;
}>`
  margin-bottom: ${({ margin }) => margin || "16px"};
  margin-right: ${({ margin }) => margin || "24px"};
`;

interface StyledFormBodyProps {
  stretchBodyVertically: boolean;
}
const StyledFormBody = styled.div<StyledFormBodyProps>`
  padding-topop: 1rem;
  padding-bottom: 1rem;
`;

function ZuoraObjectField({
  inlineLabelParent,
  itemInnerMargin,
  labelAlignmentParent,
  labelStyleParent,
  labelTextColorParent,
  labelTextSizeParent,
  labelWidthParent,
  maxColumns = 2,
  name,
  passedDefaultValue,
  propertyPath,
  schemaItem,
}: ObjectFieldProps) {
  const { accessor, isVisible = true, label } = schemaItem;

  useUpdateAccessor({ accessor });

  const objectPassedDefaultValue = useMemo(() => {
    let defaultValue: Record<string, unknown> = {};
    if (passedDefaultValue && typeof passedDefaultValue === "object") {
      defaultValue = passedDefaultValue as Record<string, unknown>;
    }

    return defaultValue;
  }, [passedDefaultValue]);
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const fields = useMemo(() => {
    const children = Object.values(schemaItem.children);
    const sortedChildren = sortBy(children, ({ position }) => position);

    return sortedChildren.map((schemaItem) => {
      const fieldName = name
        ? `${name}.${schemaItem.identifier}`
        : schemaItem.identifier;
      const fieldPropertyPath = `${propertyPath}.children.${schemaItem.identifier}`;
      if (!schemaItem.isVisible) {
        return null;
      }
      return (
        <DataSummaryItem
          $columns={Math.min(
            100,
            ((schemaItem.columnSpan || 1) / maxColumns) * 100,
          )}
          key={`${schemaItem.identifier}-itemcontainer`}
        >
          <DataSummaryItemInner
            margin={itemInnerMargin ? `${8 * itemInnerMargin}px` : null}
          >
            <FieldRenderer
              fieldName={fieldName as ControllerRenderProps["name"]}
              inlineLabelParent={inlineLabelParent}
              key={schemaItem.identifier}
              labelAlignmentParent={labelAlignmentParent}
              labelStyleParent={labelStyleParent}
              labelTextColorParent={labelTextColorParent}
              labelTextSizeParent={labelTextSizeParent}
              labelWidthParent={labelWidthParent}
              passedDefaultValue={objectPassedDefaultValue[schemaItem.accessor]}
              propertyPath={fieldPropertyPath}
              schemaItem={schemaItem}
            />
          </DataSummaryItemInner>
        </DataSummaryItem>
      );
    });
  }, [
    schemaItem,
    name,
    schemaItem.identifier,
    propertyPath,
    objectPassedDefaultValue,
    maxColumns,
    itemInnerMargin,
    inlineLabelParent,
    labelAlignmentParent,
    labelStyleParent,
    labelTextColorParent,
    labelTextSizeParent,
    labelWidthParent,
  ]);

  if (!isVisible) {
    return null;
  }

  const field = <StyledFieldsWrapper>{fields}</StyledFieldsWrapper>;

  let displayValue =
    typeof schemaItem.displayValue === "string"
      ? schemaItem.displayValue
      : schemaItem.label;

  if (
    displayValue === "" ||
    displayValue === "null" ||
    displayValue === "undefined"
  ) {
    displayValue = "--";
  }

  const {
    inlineLabel,
    labelAlignment,
    labelStyle,
    labelTextColor,
    labelTextSize,
    labelWidth,
    tooltip,
    useDefaultStyles,
  } = schemaItem;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: (useDefaultStyles ? inlineLabelParent : inlineLabel)
          ? "row"
          : "column",
      }}
    >
      <FieldLabel
        alignment={useDefaultStyles ? labelAlignmentParent : labelAlignment}
        direction={
          (useDefaultStyles ? inlineLabelParent : inlineLabel)
            ? "row"
            : "column"
        }
        label={label}
        labelStyle={useDefaultStyles ? labelStyleParent : labelStyle}
        labelTextColor={
          useDefaultStyles ? labelTextColorParent : labelTextColor
        }
        labelTextSize={useDefaultStyles ? labelTextSizeParent : labelTextSize}
        tooltip={tooltip}
        width={useDefaultStyles ? labelWidthParent : labelWidth}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          // flexGrow: 0,
        }}
      >
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={anchorEl}
          style={{
            flex: "0 1 auto",
            minWidth: 0,
            overflow: "hidden",
            wordWrap: "break-word",
            width: "100%",
          }}
        >
          <LinesEllipsis
            basedOn="letters"
            maxLine={schemaItem.linesDisplayed || 1}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: schemaItem.linesDisplayed || 1,
              WebkitBoxOrient: "vertical",
              whiteSpace: "normal",
              overflow: "hidden",
              textDecoration: open ? "underline" : "none",
              textOverflow: "ellipsis",
              color: "#0d4ac3",
              cursor: "pointer",
              fontSize: schemaItem.valueTextSize || BASE_LABEL_TEXT_SIZE,
              fontWeight: schemaItem.valueStyle?.includes(FontStyleTypes.BOLD)
                ? "600"
                : "normal",
              fontStyle: schemaItem.valueStyle?.includes(FontStyleTypes.ITALIC)
                ? "italic"
                : "",
            }}
            text={displayValue}
            trimRight
          />
        </div>
      </div>
      <Popover
        a11yId="mouse-over-popover"
        anchorEl={anchorEl.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        // arrow
        body={
          <StyledForm fixedFooter scrollContents>
            <Typography variant="subtitle1">{label}</Typography>
            <StyledFormBody stretchBodyVertically>{field}</StyledFormBody>
            {schemaItem.footerRef && (
              <StyledFormFooter backgroundColor={schemaItem.backgroundColor}>
                <div style={{ color: "#0d4ac3" }}>
                  <Link
                    href={schemaItem.footerRef}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {schemaItem.footerDisplay}
                  </Link>
                </div>
              </StyledFormFooter>
            )}
          </StyledForm>
        }
        disableRestoreFocus
        dsOnClose={handleMouseLeave}
        open={open}
        paperProps={{
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        }}
        pointerEventsNone
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      />
    </div>
  );
}

const MemoedZuoraObjectField: FieldComponent = React.memo(ZuoraObjectField);
MemoedZuoraObjectField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default MemoedZuoraObjectField;
