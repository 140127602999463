import React from "react";

import type { BaseFieldComponentProps } from "../constants";
import FieldLabel, { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import { Link } from "@appsmith/ads";
import { FontStyleTypes } from "../../../constants/WidgetConstants";

interface HyperlinkComponentProps {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: string;
  cellBackgroundColor?: string;
  cellBorderColor?: string;
  cellBorderWidth?: number;
  cellBorderRadius?: string;
  cellBoxShadow?: string;
  accentColor?: string;
  defaultValue?: any[];
  isCollapsible: boolean;
}

export type HyperlinkProps = BaseFieldComponentProps<HyperlinkComponentProps>;

const COMPONENT_DEFAULT_VALUES = {
  isDisabled: true,
  isRequired: false,
  isSpellCheck: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
  isChecked: true,
};

function HyperlinkField({
  inlineLabelParent,
  labelAlignmentParent,
  labelStyleParent,
  labelTextColorParent,
  labelTextSizeParent,
  labelWidthParent,
  schemaItem,
}: HyperlinkProps) {
  let displayValue =
    typeof schemaItem.displayValue === "string"
      ? schemaItem.displayValue
      : String(schemaItem.displayValue);

  if (
    displayValue === "" ||
    displayValue === "null" ||
    displayValue === "undefined"
  ) {
    displayValue = "--";
  }

  const defaultValue =
    typeof schemaItem.defaultValue === "string"
      ? schemaItem.defaultValue
      : String(schemaItem.defaultValue);

  const {
    inlineLabel,
    label,
    labelAlignment,
    labelStyle,
    labelTextColor,
    labelTextSize,
    labelWidth,
    tooltip,
    useDefaultStyles,
  } = schemaItem;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: (useDefaultStyles ? inlineLabelParent : inlineLabel)
            ? "row"
            : "column",
        }}
      >
        <FieldLabel
          alignment={useDefaultStyles ? labelAlignmentParent : labelAlignment}
          direction={
            (useDefaultStyles ? inlineLabelParent : inlineLabel)
              ? "row"
              : "column"
          }
          label={label}
          labelStyle={useDefaultStyles ? labelStyleParent : labelStyle}
          labelTextColor={
            useDefaultStyles ? labelTextColorParent : labelTextColor
          }
          labelTextSize={useDefaultStyles ? labelTextSizeParent : labelTextSize}
          tooltip={tooltip}
          width={useDefaultStyles ? labelWidthParent : labelWidth}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flex: 1,
            overflowX: "hidden",
            // flexGrow: 0,
          }}
        >
          <div style={{ flex: "0 1 auto", minWidth: 0, overflow: "hidden" }}>
            <Link
              href={defaultValue}
              rel="noopener noreferrer"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#0d4ac3",
                fontSize: schemaItem.valueTextSize || BASE_LABEL_TEXT_SIZE,
                fontWeight: schemaItem.valueStyle?.includes(FontStyleTypes.BOLD)
                  ? "600"
                  : "normal",
                fontStyle: schemaItem.valueStyle?.includes(
                  FontStyleTypes.ITALIC,
                )
                  ? "italic"
                  : "normal",
              }}
              target="_blank"
            >
              {displayValue}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

HyperlinkField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default HyperlinkField;
