import React, { useCallback, useContext, useMemo } from "react";
import { useController } from "react-hook-form";

import FormContext from "../FormContext";
import Field from "widgets/JSONFormWidget/component/Field";
import useEvents from "./useBlurAndFocusEvents";
import useRegisterFieldValidity from "./useRegisterFieldValidity";
import type { AlignWidget } from "WidgetProvider/constants";
import type {
  BaseFieldComponentProps,
  FieldComponentBaseProps,
  FieldEventProps,
} from "../constants";
import { ActionUpdateDependency } from "../constants";
import { StyledFieldWrapper } from "./BaseInputField";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { Colors } from "constants/Colors";
import { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import { LabelPosition } from "components/constants";
import { Toggle } from "zds";
import type { FormControlLabelProps } from "@mui/material/FormControlLabel/FormControlLabel";
import styled from "styled-components";
import { BlueprintControlTransform } from "../../../constants/DefaultTheme";

type SwitchComponentOwnProps = FieldComponentBaseProps &
  FieldEventProps & {
    alignWidget: AlignWidget;
    accentColor?: string;
    onChange?: string;
  };

type SwitchFieldProps = BaseFieldComponentProps<SwitchComponentOwnProps>;

const DEFAULT_BG_COLOR = Colors.GREEN;

const ToggleContainer = styled.div<{
  accentColor: string;
  minHeight?: number;
  width?: string;
}>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "auto")};

  ${BlueprintControlTransform}
`;

const COMPONENT_DEFAULT_VALUES: SwitchComponentOwnProps = {
  alignWidget: "LEFT",
  isDisabled: false,
  isRequired: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
};

const isValid = (value: boolean, schemaItem: SwitchFieldProps["schemaItem"]) =>
  !schemaItem.isRequired || value;

function SwitchField({
  fieldClassName,
  name,
  passedDefaultValue,
  schemaItem,
}: SwitchFieldProps) {
  const { onBlur: onBlurDynamicString, onFocus: onFocusDynamicString } =
    schemaItem;
  const { executeAction } = useContext(FormContext);

  const {
    field: { onBlur, onChange, value },
  } = useController({
    name,
  });

  const { inputRef } = useEvents<HTMLInputElement>({
    fieldBlurHandler: onBlur,
    onFocusDynamicString,
    onBlurDynamicString,
  });

  const isValueValid = isValid(value, schemaItem);

  useRegisterFieldValidity({
    fieldName: name,
    fieldType: schemaItem.fieldType,
    isValid: isValueValid,
  });

  const onSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked);

      if (schemaItem.onChange && executeAction) {
        executeAction({
          triggerPropertyName: "onChange",
          dynamicString: schemaItem.onChange,
          event: {
            type: EventType.ON_SWITCH_CHANGE,
          },
          updateDependencyType: ActionUpdateDependency.ZFORM_DATA,
        });
      }
    },
    [onChange, executeAction, schemaItem.onChange],
  );

  function getLabelPlacement(
    position: LabelPosition,
  ): FormControlLabelProps["labelPlacement"] {
    if (position === LabelPosition.Left) {
      return "end";
    }
    return "start";
  }

  const fieldComponent = useMemo(
    () => (
      <ToggleContainer accentColor={schemaItem.accentColor || DEFAULT_BG_COLOR}>
        <Toggle
          checked={value ?? false}
          disabled={schemaItem.isDisabled}
          dsOnChange={onSwitchChange}
          label=""
          labelPlacement={getLabelPlacement(LabelPosition.Left)}
          ref={inputRef}
          tooltip={schemaItem.tooltip}
        />
      </ToggleContainer>
    ),
    [
      schemaItem.alignWidget,
      schemaItem.accentColor,
      schemaItem.isDisabled,
      onSwitchChange,
      value,
    ],
  );

  return (
    <StyledFieldWrapper>
      <Field
        accessor={schemaItem.accessor}
        defaultValue={passedDefaultValue ?? schemaItem.defaultValue}
        fieldClassName={fieldClassName}
        inlineLabel
        isRequiredField={schemaItem.isRequired}
        label={schemaItem.label}
        labelStyle={schemaItem.labelStyle}
        labelTextColor={schemaItem.labelTextColor}
        labelTextSize={schemaItem.labelTextSize}
        name={name}
        tooltip={schemaItem.tooltip}
      >
        {fieldComponent}
      </Field>
    </StyledFieldWrapper>
  );
}

SwitchField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default SwitchField;
