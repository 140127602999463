import type { TitleActionItem } from "../constants";
import React from "react";
import { Button as MuiButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Popover } from "zds";
import VersionList from "./VersionList";
import "./Custom.css";

const VersionAction = ({
  action,
  identity,
  onClickEvent,
}: {
  action: TitleActionItem;
  identity?: Record<any, any>;
  onClickEvent: (onClick: string | undefined) => void;
}) => {
  const [versionListVisible, setVersionListVisible] = React.useState(false);
  const [versionListEl, setVersionListEl] = React.useState(null);
  const details = action.details;

  const toggleVersionList = (e: any) => {
    if (!versionListVisible) {
      onClickEvent?.(action.onClick);
    }
    setVersionListEl(versionListVisible ? null : e.currentTarget);
    setVersionListVisible(!versionListVisible);
  };
  const subscriptionData = action.subscriptionData;
  return (
    <div className="badge-wrapper">
      <MuiButton
        aria-controls="subscription-version-line"
        aria-expanded={versionListVisible ? "true" : undefined}
        aria-haspopup="true"
        className={`badge ${subscriptionData?.Status !== "Expired" ? "version-badge" : "version-badge-expired"}`}
        endIcon={versionListVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleVersionList}
      >
        {`${"VERSION"} ${subscriptionData?.Version || ""}`}
      </MuiButton>
      <Popover
        a11yId="version_list"
        anchorEl={versionListEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        body={
          <VersionList
            goToSubscriptionHistory={() => {
              setVersionListVisible(false);
              setVersionListEl(null);
              onClickEvent(action.goToSubscriptionHistory);
            }}
            identity={identity}
            subscriptionData={subscriptionData}
            subscriptionDetails={details}
          />
        }
        dsOnClose={() => {
          setVersionListVisible(false);
          setVersionListEl(null);
        }}
        e2e="myPopover"
        open={versionListVisible}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      />
    </div>
  );
};

export default VersionAction;
