import type { ValidationResponse } from "../../constants/WidgetValidation";
import type { ZTableWidgetProps } from "./constants";

export function sourceDataArrayValidation(
  options: unknown,
  props: ZTableWidgetProps,
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _: any,
): ValidationResponse {
  const invalidResponse = {
    isValid: false,
    parsed: [],
    messages: [
      {
        name: "TypeError",
        message: "This value does not evaluate to type Array",
      },
    ],
  };

  if (options === "" || _.isNil(options)) {
    return {
      parsed: "",
      isValid: true,
      messages: [
        {
          name: "",
          message: "",
        },
      ],
    };
  }

  try {
    if (_.isString(options)) {
      options = JSON.parse(options as string);
    }

    if (Array.isArray(options)) {
      const isValid = true;
      const message = { name: "", message: "" };

      return {
        isValid,
        parsed: isValid ? options : [],
        messages: [message],
      };
    } else {
      return invalidResponse;
    }
  } catch (e) {
    return invalidResponse;
  }
}
