import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

// import { Breadcrumbs } from "zds";
import Breadcrumbs from "../component";

import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "../../../constants/WidgetConstants";
import ThumbnailSVG from "../zuora.svg";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import type { AutoLayoutConfig } from "../../../WidgetProvider/constants";
import type { BreadcrumbsWidgetProps } from "../constants";
import type { ExecuteTriggerPayload } from "constants/AppsmithActionConstants/ActionConstants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";

class BreadcrumbsWidget extends BaseWidget<
  BreadcrumbsWidgetProps,
  WidgetState
> {
  static type = "BREADCRUMBS_WIDGET";

  static getConfig() {
    return {
      name: "Breadcrumbs",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.ZUORA],
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "Breadcrumbs",
      rows: 1,
      columns: 20,
      version: 1,
      crumbs: {
        home: {
          label: "Home",
          id: "Home",
          actionType: "BUTTON",
        },
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  handleClick = (onClick: string | undefined): void => {
    if (onClick) {
      const config: ExecuteTriggerPayload = {
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      };
      super.executeAction(config);
    }
  };

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "crumbs",
            label: "Crumbs",
            controlType: "CRUMB_ACTION",
            dependencies: ["childStylesheet"],
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              contentChildren: [
                {
                  sectionName: "Data",
                  children: [
                    {
                      propertyName: "label",
                      helpText: "Sets the label of a menu item",
                      label: "Text",
                      controlType: "INPUT_TEXT",
                      placeholderText: "Enter label",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                  ],
                },
                {
                  sectionName: "General",
                  children: [
                    {
                      propertyName: "isVisible",
                      helpText: "Controls the visibility of the widget",
                      label: "Visible",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                    {
                      propertyName: "isDisabled",
                      helpText: "Disables input to the widget",
                      label: "Disabled",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                  ],
                },
                {
                  sectionName: "Events",
                  children: [
                    {
                      propertyName: "onClick",
                      label: "onClick",
                      controlType: "ACTION_SELECTOR",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: true,
                    },
                  ],
                },
              ],
              styleChildren: [],
            },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    return <Breadcrumbs {...this.props} handleClick={this.handleClick} />;
  }
}
export default BreadcrumbsWidget;
