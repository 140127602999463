import { Menu } from "@mui/material";
import {
  StyledTimeline,
  StyledTimelineConnector,
  StyledTimelineContent,
  StyledTimeLineDot,
  StyledTimeLineItem,
} from "./StyledComponents";
import { TimelineSeparator } from "@mui/lab";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Spinner } from "zds";
import { convertToTenantTZ, formatDateByLocale } from "./Utils";
import moment from "moment-timezone";
import _ from "lodash";
import "./Custom.css";

const StatusHistoryTimeLineDot = styled(StyledTimeLineDot)<any>`
  border: 1px solid;
  border-color: ${({ statusinterval }) =>
    statusinterval?.isFuture
      ? "rgba(0, 0, 0, 0.24)"
      : statusinterval?.isCurrent
        ? "transparent"
        : "rgba(0, 0, 0, 0.4)"};
  background: ${({ statusinterval }) =>
    statusinterval?.isCurrent ? "rgba(0, 0, 0, 0.6);" : "#ffffff"};
`;

const StatusHistoryTimelineConnector = styled(StyledTimelineConnector)<any>`
  border-left-style: ${({ statusinterval }) =>
    statusinterval?.isFuture ? "dashed" : "inset"};
  border-color: ${({ statusinterval }) =>
    statusinterval?.isFuture ? "rgba(0, 0, 0, 0.24)" : "rgba(0, 0, 0, 0.12)"};
`;

const StatusHistory = ({
  anchorEl,
  details,
  dropDownVisible,
  identity,
  toggleDropDown,
}: {
  anchorEl: any;
  dropDownVisible: boolean;
  toggleDropDown: (e: any) => void;
  details: Record<any, any>;
  identity?: Record<any, any>;
  subscriptionData?: Record<any, any>;
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [statusIntervals, setStatusIntervals] = React.useState<any>(null);

  const buildTimelinePoint = (subscriptionVersions: Record<any, any>) => {
    const result = _.cloneDeep(subscriptionVersions);
    const { SubscriptionEndDate, SubscriptionStartDate } =
      subscriptionVersions[subscriptionVersions.length - 1];
    let type = "SuspendDate";
    const resolveResult = (result: any, sspVersion: any) => {
      if (sspVersion[type]) {
        sspVersion[type] === result[result.length - 1]
          ? result.pop()
          : result.push(sspVersion[type]);
        type = type === "SuspendDate" ? "ResumeDate" : "SuspendDate";
      }
      return result;
    };
    return result
      .reduce(
        (result: any, item: any) => {
          item.orderactions?.reverse().reduce(resolveResult, result);
          item.amendments?.reduce(resolveResult, result);
          return result;
        },
        [SubscriptionStartDate],
      )
      ?.concat([SubscriptionEndDate]);
  };

  const buildStatusInterval = (
    timelinePoints: any,
    subscriptionVersions: any,
  ) => {
    if (
      !timelinePoints ||
      !subscriptionVersions ||
      subscriptionVersions.length === 0
    ) {
      return null;
    }
    let status = "active";
    const statusIntervals: any = [];
    const { cancelledDate } =
      subscriptionVersions[subscriptionVersions.length - 1];
    const currentTenantDate = convertToTenantTZ(
      moment(),
      identity?.tenantTimeZone,
    );
    timelinePoints.reduce((a: moment.MomentInput, b: moment.MomentInput) => {
      const statusInterval = {
        status,
        isCurrent: currentTenantDate?.isBetween(moment(a), moment(b)),
        isFuture: currentTenantDate?.isBefore(moment(a)), // Status interval is after current date
        isPrevious: currentTenantDate?.isAfter(moment(b)), // Status interval is before current date
        interval: "",
      };
      if (b) {
        statusInterval["interval"] = `${formatDateByLocale(
          a,
          identity?.locale,
        )} - ${formatDateByLocale(moment(b), identity?.locale)}`;
      } else {
        statusInterval["interval"] =
          `${formatDateByLocale(a, identity?.locale)} - -`;
      }
      status = status === "active" ? "suspended" : "active";
      statusIntervals.push(statusInterval as any);
      return b;
    });
    if (cancelledDate) {
      statusIntervals.push({
        interval: `${formatDateByLocale(cancelledDate, identity?.locale)} - -`,
        status: "cancelled",
        isCurrent: currentTenantDate?.isSame(moment(cancelledDate)),
        isFuture: currentTenantDate?.isBefore(moment(cancelledDate)), // Status interval is after current date
        isPrevious: currentTenantDate?.isAfter(moment(cancelledDate)), // Status interval is before current date
      });
    }
    return statusIntervals.reverse();
  };

  useEffect(() => {
    if (!statusIntervals && dropDownVisible && details && details.length > 0) {
      const statusIntervals = buildStatusInterval(
        buildTimelinePoint(details),
        details,
      );

      if (statusIntervals) {
        setStatusIntervals(statusIntervals);
        setIsLoading(false);
      }
    }
  }, [dropDownVisible, details]);

  return (
    <Menu
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 30, horizontal: "left" }}
      id="subscription-timeline"
      onClose={(e) => {
        toggleDropDown(e);
      }}
      open={dropDownVisible}
    >
      {isLoading ? (
        <div
          style={{
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "8px",
          }}
        >
          <Spinner size="small" />
        </div>
      ) : (
        <div>
          <StyledTimeline>
            {statusIntervals?.map(
              (statusInterval: any, index: any) => (
                // index < statusHistoryThreshold && (
                <StyledTimeLineItem key={index}>
                  <TimelineSeparator>
                    <StatusHistoryTimeLineDot statusinterval={statusInterval} />
                    {index !== statusIntervals.length - 1 && (
                      <StatusHistoryTimelineConnector
                        statusinterval={statusInterval}
                      />
                    )}
                  </TimelineSeparator>
                  <StyledTimelineContent>
                    <p
                      className={
                        statusInterval.isFuture ? "future-interval" : "interval"
                      }
                    >
                      {statusInterval.interval}
                    </p>
                    <div
                      style={{
                        transform: "scale(0.75)",
                        transformOrigin: "left",
                      }}
                    >
                      <div
                        className={`badge status-timeline-badge ${statusInterval.status}`}
                      >
                        {statusInterval.status?.toUpperCase()}
                      </div>
                      {statusInterval.isCurrent && (
                        <div
                          className="badge status-timeline-badge current-status"
                          style={{ marginLeft: "15px" }}
                        >
                          {"CURRENT"}
                        </div>
                      )}
                    </div>
                  </StyledTimelineContent>
                </StyledTimeLineItem>
              ),
              // ),
            )}
          </StyledTimeline>
        </div>
      )}
    </Menu>
  );
};

export default StatusHistory;
