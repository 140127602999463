import React from "react";
import type { MaybeElement } from "@blueprintjs/core";
import type { Alignment } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";

import type { ComponentProps } from "widgets/BaseComponent";
import type { ButtonPlacement, ButtonVariant } from "components/constants";
import { BaseButton, type ButtonType } from "widgets/ButtonWidget/component";
import { useSelector } from "react-redux";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";

interface ButtonComponentProps extends ComponentProps {
  text?: string;
  icon?: IconName | MaybeElement;
  tooltip?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  isLoading: boolean;
  shouldFitContent: boolean;
  rightIcon?: IconName | MaybeElement;
  type: ButtonType;
  buttonColor?: string;
  buttonVariant?: ButtonVariant;
  borderRadius?: string;
  boxShadow?: string;
  boxShadowColor?: string;
  iconName?: IconName;
  iconAlign?: Alignment;
  placement?: ButtonPlacement;
  className?: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  loginType?: string;
}

function SSOLoginComponent(props: ButtonComponentProps) {
  const application = useSelector(getCurrentApplication);
  const button = <SSOBaseButton {...props} />;
  if (props.isDisabled === true) {
    return button;
  }
  const btnWrapper = (
    <a
      href={`/api/v1/zuora/users/sso_login/${props.loginType}/${application?.id}`}
      onClick={() =>
        window.parent.postMessage(
          `${window.location.origin}/api/v1/zuora/users/sso_login/${props.loginType}/${application?.id}`,
          "*",
          undefined,
        )
      }
      style={{ textDecoration: "none" }}
    >
      {button}
    </a>
  );
  return btnWrapper;
}

function SSOBaseButton(props: ButtonComponentProps) {
  return (
    <BaseButton
      borderRadius={props.borderRadius}
      boxShadow={props.boxShadow}
      boxShadowColor={props.boxShadowColor}
      buttonColor={props.buttonColor}
      buttonVariant={props.buttonVariant}
      disabled={props.isDisabled}
      icon={props.icon}
      iconAlign={props.iconAlign}
      iconName={props.iconName}
      loading={props.isLoading}
      maxWidth={props.maxWidth}
      minHeight={props.minHeight}
      minWidth={props.minWidth}
      placement={props.placement}
      rightIcon={props.rightIcon}
      shouldFitContent={props.shouldFitContent}
      text={props.text}
      type={props.type}
    />
  );
}

export interface SSOLoginComponentProps {}

export default SSOLoginComponent;
