import { ValidationTypes } from "constants/WidgetValidation";
import { FieldType } from "widgets/ZJsonFormWidget/constants";
import type { HiddenFnParams } from "../helper";
import { getSchemaItem, getAutocompleteProperties } from "../helper";
import { lxDateFormatOptions } from "WidgetProvider/constants";

const PROPERTIES = {
  content: {
    data: [
      {
        helpText: "Sets the format of the selected date",
        propertyName: "dateFormat",
        label: "Date format",
        controlType: "DROP_DOWN",
        isJSConvertible: true,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        optionWidth: "340px",
        options: lxDateFormatOptions,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hideSubText: true,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATEPICKER),
        dependencies: ["schema"],
      },
      {
        propertyName: "defaultValue",
        label: "Default Date",
        helpText:
          "Sets the default date of the widget. The date is updated if the default date changes",
        controlType: "DATE_PICKER",
        placeholderText: "Enter Default Date",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.TIME_STAMP },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATEPICKER),
        dependencies: ["schema"],
      },
    ],
    validation: [
      {
        propertyName: "minDate",
        label: "Min Date",
        helpText: "Defines the min date for the field",
        controlType: "DATE_PICKER",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.DATE_ISO_STRING },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATEPICKER),
        dependencies: ["schema"],
      },
      {
        propertyName: "maxDate",
        label: "Max Date",
        helpText: "Defines the max date for the field",
        controlType: "DATE_PICKER",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.DATE_ISO_STRING },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATEPICKER),
        dependencies: ["schema"],
      },
    ],
    general: [
      {
        propertyName: "convertToISO",
        label: "Convert to ISO format",
        helpText:
          "Enabling this always converts the value in ISO form in the formData irrespective of the 'Date format' selected",
        controlType: "SWITCH",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATEPICKER),
        dependencies: ["schema"],
      },
    ],
    events: [
      {
        propertyName: "onDateSelected",
        label: "onDateSelected",
        helpText: "when a date is selected in the calendar",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        additionalAutoComplete: getAutocompleteProperties,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATEPICKER),
        dependencies: ["schema"],
      },
    ],
  },
};

export default PROPERTIES;
