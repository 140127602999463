import React, { type ReactNode } from "react";
import { Tabs, Tab } from "zds";
import type { ComponentProps } from "../../BaseComponent";
import {
  generateClassName,
  getCanvasClassName,
} from "../../../utils/generators";
import styled from "styled-components";
import { scrollCSS } from "../../WidgetUtils";

const ScrollCanvas = styled.div<{
  $shouldScrollContents: boolean;
  orientation: "horizontal" | "vertical";
}>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: ${(props) => (props.orientation === "vertical" ? "auto" : "100%")};
  flex-grow: 1;
`;
const TabContainer = styled.div<{ orientation: "horizontal" | "vertical" }>`
  padding-left: ${(props) =>
    props.orientation === "horizontal" ? "10px" : "0"};
  margin-top: ${(props) =>
    props.orientation === "horizontal" ? "-10px" : "0"};
  && .MuiTabs-root {
    ${(props) =>
      props.orientation === "vertical"
        ? `
          max-width: 200px;
          min-width: 100px
        `
        : `
          min-height: 41px;
          height: 41px;
        `}
  }
`;
const Container = styled.div<{ orientation: "horizontal" | "vertical" }>`
  display: flex;
  flex-direction: ${(props) =>
    props.orientation === "vertical" ? "row" : "column"};
  margin-left: ${(props) =>
    props.orientation === "horizontal" ? "-6px" : "0"};
`;

function ZTabsComponent(props: ZTabsComponentProps) {
  const { onTabChange, tabs } = props;

  return (
    <Container orientation={props.orientation}>
      <TabContainer orientation={props.orientation}>
        <Tabs
          a11yLabel="ZTabs"
          dsOnChange={(event, value) => onTabChange(value)}
          e2e="ZTabs"
          orientation={props.orientation || "horizontal"}
          value={props.activeTab}
        >
          {tabs.map((tab) => (
            <Tab
              aria-controls={tab.widgetId}
              e2e={tab.widgetId}
              id={tab.widgetId}
              key={tab.widgetId}
              label={tab.label}
              value={tab.label}
            />
          ))}
        </Tabs>
      </TabContainer>
      <ScrollCanvas
        $shouldScrollContents={!!props.shouldScrollContents && !props.$noScroll}
        className={`${
          props.shouldScrollContents ? getCanvasClassName() : ""
        } ${generateClassName(props.widgetId)}`}
        orientation={props.orientation}
      >
        {props.children}
      </ScrollCanvas>
    </Container>
  );
}

export interface ZTabsComponentProps extends ComponentProps {
  tabs: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
  }>;
  children?: ReactNode;
  shouldScrollContents?: boolean;
  $noScroll: boolean;
  onTabChange: (activeTab: string) => void;
  selectedTabWidgetId: string;
  activeTab: string;
  orientation: "horizontal" | "vertical";
}

export default ZTabsComponent;
