import React, { type ReactNode } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  StepContent,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import type { ComponentProps } from "widgets/BaseComponent";
import styled from "styled-components";
import {
  generateClassName,
  getCanvasClassName,
} from "../../../utils/generators";
import { scrollCSS } from "../../WidgetUtils";
import type { Positioning } from "../../../layoutSystems/common/utils/constants";
import type { HeaderActionItem } from "../constants";
import { PageHeaderActions } from "../../PageHeaderWidget/component/PageHeaderActions";
import { sortBy } from "lodash";

const StepperContainerWrapper = styled.div<{
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => `${boxShadow}`} !important;
  border-width: ${(props) => props.borderWidth}px;
  border-color: ${(props) => props.borderColor || "transparent"};
  background-color: ${(props) =>
    props.backgroundColor || "var(--wds-color-bg)"};
  border-style: solid;
  overflow: hidden;
`;

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  align-items: flex-end;
`;
interface StepperComponentProps extends ComponentProps {
  activeStep: number;
  children?: ReactNode;
  steps: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
    positioning: Positioning;
  }>;
  isAlternativeLabel: boolean;
  selectedStepWidgetId: string;
  activeColor: string;
  completedColor: string;
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  shouldScrollContents?: boolean;
  $noScroll: boolean;
  stepButton: boolean;
  onStepChange: (activeStep: number) => void;
  onLoad?: () => void;
  nonLinear?: boolean;
  orientation?: "horizontal" | "vertical";
  leftHeaderActions?: Record<string, HeaderActionItem>;
  maxLeftHeaderActionDisplay: number;
  leftHeaderVisible: boolean;
  rightHeaderActions?: Record<string, HeaderActionItem>;
  maxRightHeaderActionDisplay: number;
  rightHeaderVisible: boolean;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
  groupButtonHandleClick?: (
    onClick: string | undefined,
    callback?: () => void,
  ) => void;
}

function StepperComponent(props: StepperComponentProps) {
  const {
    activeColor,
    activeStep,
    buttonClickHandler,
    completedColor,
    groupButtonHandleClick,
    handleOnchange,
    isAlternativeLabel,
    leftHeaderActions,
    leftHeaderVisible,
    maxLeftHeaderActionDisplay,
    maxRightHeaderActionDisplay,
    nonLinear,
    onStepChange,
    orientation,
    rightHeaderActions,
    rightHeaderVisible,
    selectedStepWidgetId,
    stepButton,
    steps,
    widgetId,
  } = props;

  const stepsElements = [];
  const stepStyle = {
    minHeight: "32px",
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: activeColor,
        fontSize: "2rem",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: completedColor,
        fontSize: "2rem",
      },
    },
    "& .MuiStep-root": {
      padding: "0 20px",
      minHeight: "32px",
      alignContent: "center",
    },
    "& .MuiStepButton-root": {
      margin: "0px -16px",
    },
  };
  for (const index in steps) {
    const step = steps[index];
    stepsElements.push(
      nonLinear ? (
        <Step key={`${widgetId}-${index}`}>
          <StepButton
            color="inherit"
            onClick={() => onStepChange(Number(index))}
          >
            {step.label}
          </StepButton>
          {orientation === "vertical" && (
            <StepContent>
              <ScrollCanvas
                $shouldScrollContents={
                  !!props.shouldScrollContents && !props.$noScroll
                }
                className={`${
                  props.shouldScrollContents ? getCanvasClassName() : ""
                } ${generateClassName(props.widgetId)}`}
              >
                {props.children}
              </ScrollCanvas>
            </StepContent>
          )}
        </Step>
      ) : (
        <Step key={`${widgetId}-${index}`}>
          <StepLabel sx={{ padding: 0, margin: 0 }}>{step.label}</StepLabel>
          {orientation === "vertical" && (
            <StepContent>
              <ScrollCanvas
                $shouldScrollContents={
                  !!props.shouldScrollContents && !props.$noScroll
                }
                className={`${
                  props.shouldScrollContents ? getCanvasClassName() : ""
                } ${generateClassName(props.widgetId)}`}
              >
                {props.children}
              </ScrollCanvas>
            </StepContent>
          )}
        </Step>
      ),
    );
  }

  const previousOnClick = () => {
    onStepChange(activeStep - 1);
  };

  const nextOnClick = () => {
    onStepChange(activeStep + 1);
  };

  const handleOnLoad = () => {
    if (props.onLoad) {
      props.onLoad();
    }
  };

  React.useEffect(() => {
    handleOnLoad();
  }, [selectedStepWidgetId]);

  const getVisibleActions = (actions: any) => {
    if (!actions) return {};

    let items = Object.keys(actions)
      .map((itemKey) => actions[itemKey])
      .filter((item) => item.isVisible === true);
    // sort btns by index
    items = sortBy(items, ["index"]);
    return items;
  };

  const leftPageHeaderActionsComponent = (
    <PageHeaderActions
      actions={getVisibleActions(leftHeaderActions)}
      buttonClickHandler={buttonClickHandler}
      groupButtonClickHandler={groupButtonHandleClick}
      handleOnchange={handleOnchange}
      maxHeaderActionDisplay={maxLeftHeaderActionDisplay}
    />
  );

  const rightPageHeaderActionsComponent = (
    <PageHeaderActions
      actions={getVisibleActions(rightHeaderActions)}
      buttonClickHandler={buttonClickHandler}
      groupButtonClickHandler={groupButtonHandleClick}
      handleOnchange={handleOnchange}
      maxHeaderActionDisplay={maxRightHeaderActionDisplay}
    />
  );

  return (
    <StepperContainerWrapper
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      borderRadius={props.borderRadius}
      borderWidth={props.borderWidth}
      boxShadow={props.boxShadow}
      style={{ padding: "10px" }}
    >
      <Container className="relative flex">
        {stepButton && (
          <div
            // className="left-0 cursor-pointer scroll-nav-left-button"
            {...(activeStep > 0 && {
              onClick: () => previousOnClick(),
            })}
            // visible={stepButton}
          >
            <ArrowLeftIcon
              sx={{
                fontSize: isAlternativeLabel ? "100px" : "60px",
                color: props.activeColor,
              }}
            />
            {/*<Icon name="left-arrow-2" size={IconSize.LARGE} />*/}
          </div>
        )}
        {orientation === "horizontal" &&
          leftHeaderVisible &&
          leftHeaderActions &&
          leftPageHeaderActionsComponent}
        <div
          style={{
            flexGrow: 1,
            paddingBottom: isAlternativeLabel ? "6px" : "0px",
          }}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel={isAlternativeLabel}
            nonLinear={nonLinear || false}
            orientation={orientation || "horizontal"}
            sx={stepStyle}
          >
            {stepsElements}
          </Stepper>
        </div>
        {orientation === "horizontal" &&
          rightHeaderVisible &&
          rightHeaderActions &&
          rightPageHeaderActionsComponent}
        {stepButton && (
          <div
            {...(activeStep < steps.length - 1 && {
              onClick: () => nextOnClick(),
            })}
          >
            <ArrowRightIcon
              sx={{
                fontSize: isAlternativeLabel ? "100px" : "60px",
                color: props.activeColor,
              }}
            />
            {/*<Icon name="right-arrow-2" size={IconSize.LARGE} />*/}
          </div>
        )}
      </Container>
      {(orientation === "horizontal" || orientation === undefined) && (
        <ScrollCanvas
          $shouldScrollContents={
            !!props.shouldScrollContents && !props.$noScroll
          }
          className={`${
            props.shouldScrollContents ? getCanvasClassName() : ""
          } ${generateClassName(props.widgetId)}`}
        >
          {props.children}
        </ScrollCanvas>
      )}
    </StepperContainerWrapper>
  );
}

export default StepperComponent;
