import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { useController } from "react-hook-form";

// import CheckboxComponent from "widgets/CheckboxWidget/component";
import FormContext from "../FormContext";
import Field from "../component/Field";
import useEvents from "./useBlurAndFocusEvents";
import useRegisterFieldValidity from "./useRegisterFieldValidity";
import type { AlignWidget } from "WidgetProvider/constants";
import type {
  BaseFieldComponentProps,
  FieldComponentBaseProps,
  FieldEventProps,
} from "../constants";
import { ActionUpdateDependency } from "../constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
// import { Colors } from "constants/Colors";
import { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
// import { LabelPosition } from "components/constants";
import { Checkbox } from "zds";
import { Alignment } from "@blueprintjs/core";

type CheckboxComponentProps = FieldComponentBaseProps &
  FieldEventProps & {
    alignWidget: AlignWidget;
    onCheckChange?: string;
    accentColor?: string;
    borderRadius?: string;
    boxShadow?: string;
  };

type CheckboxFieldProps = BaseFieldComponentProps<CheckboxComponentProps>;

// const DEFAULT_BORDER_RADIUS = "0px";

const StyledCheckboxWrapper = styled.div`
  & label {
    margin-bottom: 0;
  }
`;

const COMPONENT_DEFAULT_VALUES: CheckboxComponentProps = {
  alignWidget: "LEFT",
  isDisabled: false,
  isRequired: false,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  isVisible: true,
  label: "",
};

const isValid = (
  value: boolean,
  schemaItem: CheckboxFieldProps["schemaItem"],
) => !schemaItem.isRequired || Boolean(value);

function CheckboxField({
  fieldClassName,
  name,
  passedDefaultValue,
  schemaItem,
}: CheckboxFieldProps) {
  const { onBlur: onBlurDynamicString, onFocus: onFocusDynamicString } =
    schemaItem;
  const { executeAction } = useContext(FormContext);

  const {
    field: { onBlur, onChange, value },
    fieldState: { isDirty },
  } = useController({
    name,
  });

  const { inputRef } = useEvents<HTMLInputElement>({
    fieldBlurHandler: onBlur,
    onFocusDynamicString,
    onBlurDynamicString,
  });

  const isValueValid = isValid(value, schemaItem);

  useRegisterFieldValidity({
    fieldName: name,
    fieldType: schemaItem.fieldType,
    isValid: isValueValid,
  });

  const onCheckChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      // Call onChange with the new checked value
      onChange(checked);

      // Execute the action if specified
      if (schemaItem.onCheckChange && executeAction) {
        executeAction({
          triggerPropertyName: "onCheckChange",
          dynamicString: schemaItem.onCheckChange,
          event: {
            type: EventType.ON_CHECK_CHANGE,
          },
          updateDependencyType: ActionUpdateDependency.ZFORM_DATA,
        });
      }
    },
    [schemaItem.onCheckChange, onChange, executeAction],
  );

  const fieldComponent = useMemo(
    () => (
      <StyledCheckboxWrapper>
        <Checkbox
          checked={value}
          disabled={schemaItem.isDisabled}
          dsOnChange={onCheckChange}
          e2e={" "}
          ref={(e) => (inputRef.current = e)}
          required={schemaItem.isRequired}
          size={"small"}
        />
      </StyledCheckboxWrapper>
    ),
    [schemaItem, inputRef, value, isDirty, isValueValid, onCheckChange],
  );

  return (
    <Field
      accessor={schemaItem.accessor}
      alignField={schemaItem.alignWidget}
      alignment={Alignment.CENTER}
      defaultValue={passedDefaultValue ?? schemaItem.defaultValue}
      fieldClassName={fieldClassName}
      inlineLabel
      isRequiredField={schemaItem.isRequired}
      label={schemaItem.label}
      labelStyle={schemaItem.labelStyle}
      labelTextColor={schemaItem.labelTextColor}
      labelTextSize={schemaItem.labelTextSize}
      name={name}
      tooltip={schemaItem.tooltip}
    >
      {fieldComponent}
    </Field>
  );
}

CheckboxField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default CheckboxField;
