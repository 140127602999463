import React from "react";
import { connect } from "react-redux";
import type { ControlData, ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
// import DynamicActionCreator from "components/editorComponents/DynamicActionCreator";
import ActionCreator from "components/editorComponents/ActionCreator";
import type { DSEventDetail } from "utils/AppsmithUtils";
import {
  DSEventTypes,
  DS_EVENT,
  emitInteractionAnalyticsEvent,
} from "utils/AppsmithUtils";
import {
  codeToAction,
  getCodeFromMoustache,
} from "components/editorComponents/ActionCreator/utils";
import { canTranslateToUI, getActionBlocks } from "@shared/ast";
import {
  getActions,
  getAllJSCollections,
  getWFCollections,
  getJSModuleInstancesData,
  getModuleInstances,
  getPlugins,
} from "ee/selectors/entitiesSelector";
import store from "store";
import cloneDeep from "lodash/cloneDeep";
import { getCurrentPageId } from "selectors/editorSelectors";
import { getApiQueriesAndJSActionOptionsWithChildren } from "components/editorComponents/ActionCreator/helpers";
import { selectEvaluationVersion } from "ee/selectors/applicationSelectors";
import type {
  ModuleInstance,
  ModuleInstanceDataState,
} from "ee/constants/ModuleInstanceConstants";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { JSAction } from "entities/JSCollection";
import { getAllModules } from "ee/selectors/modulesSelector";
import type { TWFActionProps } from "components/editorComponents/ActionCreator/viewComponents/Action/ActionTree";
import type { WFCollectionData } from "ee/reducers/entityReducers/wfActionsReducer";
import type { AppState } from "ee/reducers";

class ActionSelectorControl extends BaseControl<ControlProps> {
  componentRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.componentRef.current?.addEventListener(
      DS_EVENT,
      this.handleAdsEvent as (arg0: Event) => void,
    );
  }

  componentWillUnmount() {
    this.componentRef.current?.removeEventListener(
      DS_EVENT,
      this.handleAdsEvent as (arg0: Event) => void,
    );
  }

  handleAdsEvent = (e: CustomEvent<DSEventDetail>) => {
    if (
      e.detail.component === "TreeDropdown" &&
      e.detail.event === DSEventTypes.KEYPRESS
    ) {
      emitInteractionAnalyticsEvent(this.componentRef.current, {
        key: e.detail.meta.key,
      });
      e.stopPropagation();
    }
  };

  handleValueUpdate = (newValue: string, isUpdatedViaKeyboard = false) => {
    const { propertyName, propertyValue } = this.props;

    if (!propertyValue && !newValue) return;

    this.updateProperty(propertyName, newValue, isUpdatedViaKeyboard);
    // adding/removing an onClick Workflow action
    if (propertyName === "onClick") {
      const onClickWFActionCalls = newValue
        .substring(2, newValue.length - 2)
        .split("\n")
        .filter((action) => action.endsWith(".runZuoraWorkflow();"))
        .map((action) => action.replace(".runZuoraWorkflow();", ""));
      if (onClickWFActionCalls.length) {
        // retrieve existing params for each action and spread where diffs detected
        const { canvasWidgets, wfActions, widgetProperties } = this.props;
        const wfActionsWithParams = onClickWFActionCalls.map(
          (onClickWFActionCall) => {
            if (
              canvasWidgets &&
              canvasWidgets[widgetProperties.widgetId].onClickWFParams
            ) {
              const currStoredParameters = canvasWidgets[
                widgetProperties.widgetId
              ].onClickWFParams.find(
                (onClickWFParam: TWFActionProps) =>
                  onClickWFActionCall ===
                    `${onClickWFParam.name.replace(/[^A-Z0-9]/gi, "_")}` &&
                  onClickWFParam.parameters.length,
              );
              if (currStoredParameters) {
                // set of params already exists, so we take the stored params
                return currStoredParameters;
              }
            }
            // this is a new wf Action that has just been added, so we take the default params
            return this.findWFActionInEntity(wfActions, onClickWFActionCall);
          },
        );
        const filteredWFActionsWithParams = wfActionsWithParams.filter(
          (item) => item !== undefined,
        );
        this.updateProperty(
          "onClickWFParams",
          filteredWFActionsWithParams.length
            ? filteredWFActionsWithParams
            : undefined,
          isUpdatedViaKeyboard,
        );
      } else {
        // if no onClick workflows selected at all, set to undefined
        this.updateProperty("onClickWFParams", undefined, isUpdatedViaKeyboard);
      }
    }
  };

  findWFActionInEntity = (
    wfActions: WFCollectionData[] | undefined,
    onClickWFActionCall: string,
  ) => {
    if (wfActions) {
      const currDefaultParameters = wfActions.find(
        (wfAction: WFCollectionData) =>
          onClickWFActionCall ===
            `${wfAction.config.name.replace(/[^A-Z0-9]/gi, "_")}` &&
          wfAction.config.active_version.parameters.length,
      );
      return (
        currDefaultParameters && {
          datatypeOptions:
            currDefaultParameters.config.active_version.datatypeOptions,
          definitionId: currDefaultParameters.config.id,
          name: currDefaultParameters.config.name,
          parameters: currDefaultParameters.config.active_version.parameters,
          useLegacyBooleanInput:
            currDefaultParameters.config.active_version.useLegacyBooleanInput,
        }
      );
    }
    return {
      datatypeOptions: {},
      // definitionId: parseInt(
      //   onClickWFActionCall.substring(idStartIndex, idEndIndex),
      // ),
      name: onClickWFActionCall,
      parameters: [],
      useLegacyBooleanInput: false,
    };
  };

  saveWFActionParams = (
    params: TWFActionProps | undefined,
    isUpdatedViaKeyboard: boolean,
  ) => {
    const { canvasWidgets, widgetProperties } = this.props;
    if (canvasWidgets) {
      const onClickWFParams = cloneDeep(
        canvasWidgets[widgetProperties.widgetId].onClickWFParams,
      );
      onClickWFParams.forEach(
        (onClickWFParam: TWFActionProps, index: number) => {
          if (onClickWFParam.definitionId === params?.definitionId) {
            onClickWFParams[index] = params.parameters.length
              ? params
              : undefined;
          }
        },
      );
      const filteredWFActionsWithParams = onClickWFParams.filter(
        (item: TWFActionProps) => item !== undefined,
      );
      this.updateProperty(
        "onClickWFParams",
        filteredWFActionsWithParams.length
          ? filteredWFActionsWithParams
          : undefined,
        isUpdatedViaKeyboard,
      );
    }
  };

  render() {
    const {
      dataTreePath,
      label,
      propertyName,
      propertyValue,
      widgetProperties,
    } = this.props;

    return (
      <ActionCreator
        action={label}
        additionalAutoComplete={this.props.additionalAutoComplete}
        additionalControlData={
          // TODO: Fix this the next time the file is edited
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.props.additionalControlData as Record<string, any>
        }
        dataTreePath={dataTreePath}
        onValueChange={this.handleValueUpdate}
        propertyName={propertyName}
        ref={this.componentRef}
        saveWFActionParams={this.saveWFActionParams}
        value={propertyValue}
        widgetId={widgetProperties.widgetId}
        widgetName={widgetProperties.widgetName}
        widgetType={widgetProperties.type}
      />
    );
  }

  static getControlType() {
    return "ACTION_SELECTOR";
  }

  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static canDisplayValueInUI(_: ControlData, value: any): boolean {
    const state = store.getState();
    const actions = getActions(state);
    const jsCollections = getAllJSCollections(state);
    const wfCollections = getWFCollections(state);
    const codeFromProperty = getCodeFromMoustache(value?.trim() || "");
    const evaluationVersion = selectEvaluationVersion(state);
    const moduleInstances = getModuleInstances(state);
    const queryModuleInstances = [] as ModuleInstanceDataState;
    const jsModuleInstances = getJSModuleInstancesData(state);
    const modules = getAllModules(state);

    if (!!moduleInstances) {
      for (const moduleInstance of Object.values(moduleInstances)) {
        const instance = moduleInstance as ModuleInstance;

        if (instance.type === MODULE_TYPE.QUERY) {
          queryModuleInstances.push({
            config: instance,
            data: undefined,
            isLoading: false,
          });
        }
      }
    }

    const actionsArray: string[] = [];
    const jsActionsArray: string[] = [];
    // const wfActionsArray: string[] = [];
    const queryModuleInstanceArray: string[] = [];

    actions.forEach((action) => {
      actionsArray.push(action.config.name + ".run");
      actionsArray.push(action.config.name + ".clear");
    });

    jsCollections.forEach((jsCollection) =>
      jsCollection.config.actions.forEach((action: JSAction) => {
        jsActionsArray.push(jsCollection.config.name + "." + action.name);
      }),
    );

    // wfCollections.forEach((wfCollection) => {
    //   wfActionsArray.push(wfCollection.config.name + ".run");
    //   wfActionsArray.push(wfCollection.config.name + ".clear");
    // });

    queryModuleInstances.forEach((instance) => {
      queryModuleInstanceArray.push(instance.config.name + ".run");
      queryModuleInstanceArray.push(instance.config.name + ".clear");
    });

    const canTranslate = canTranslateToUI(codeFromProperty, evaluationVersion);

    if (codeFromProperty.trim() && !canTranslate) {
      return false;
    }

    const pageId = getCurrentPageId(state);
    const plugins = getPlugins(state);

    // this function gets all the Queries/API's/JS Objects and attaches it to actionList
    const fieldOptions = getApiQueriesAndJSActionOptionsWithChildren(
      pageId,
      plugins,
      actions,
      jsCollections,
      wfCollections,
      () => {
        return;
      },
      () => {
        return;
      },
      queryModuleInstances,
      jsModuleInstances,
      modules,
    );

    try {
      const blocks = getActionBlocks(codeFromProperty, evaluationVersion);

      for (const codeBlock of blocks) {
        codeToAction(codeBlock, fieldOptions, true, true);
      }
    } catch (e) {
      return false;
    }

    return true;
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    canvasWidgets: state.entities.canvasWidgets,
    wfActions: state.entities.wfActions,
  };
};

export default connect(mapStateToProps)(ActionSelectorControl);
