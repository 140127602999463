import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import styled from "styled-components";
import { useController } from "react-hook-form";

import Field from "widgets/JSONFormWidget/component/Field";
import FormContext from "../FormContext";
import useRegisterFieldValidity from "./useRegisterFieldValidity";
import useUpdateInternalMetaState from "./useUpdateInternalMetaState";
import type {
  BaseFieldComponentProps,
  FieldComponentBaseProps,
} from "../constants";
import { ActionUpdateDependency } from "../constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { DropdownOption } from "widgets/SelectWidget/constants";
import { isPrimitive } from "../helper";
import { isNil } from "lodash";
import { Colors } from "constants/Colors";
import { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import { Select, SelectItem } from "zds";
import type { SelectChangeEvent } from "@mui/material";

interface MetaProps {
  filterText?: string;
}

type DefaultValue = string | number | DropdownOption | null | undefined;

type SelectComponentProps = FieldComponentBaseProps &
  MetaProps & {
    borderRadius?: string;
    boxShadow?: string;
    isFilterable: boolean;
    onFilterUpdate?: string;
    onOptionChange?: string;
    options: DropdownOption[];
    placeholderText?: string;
    accentColor?: string;
    serverSideFiltering: boolean;
  };

const DEFAULT_PRIMARY_COLOR = Colors.GREEN;
const DEFAULT_BORDER_RADIUS = "0";

const COMPONENT_DEFAULT_VALUES: SelectComponentProps = {
  isDisabled: false,
  isFilterable: false,
  isRequired: false,
  isVisible: true,
  label: "",
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  serverSideFiltering: false,
  options: [
    { label: "Blue", value: "BLUE" },
    { label: "Green", value: "GREEN" },
    { label: "Red", value: "RED" },
  ],
};

export type SelectFieldProps = BaseFieldComponentProps<SelectComponentProps>;

const StyledSelectWrapper = styled.div`
  width: 100%;
`;

export const isValid = (
  schemaItem: SelectFieldProps["schemaItem"],
  value?: unknown,
) => !schemaItem.isRequired || (value !== "" && !isNil(value));

const composeDefaultValue = (
  schemaItemDefaultValue: DefaultValue,
  passedDefaultValue: DefaultValue,
) => {
  if (isPrimitive(passedDefaultValue)) return passedDefaultValue;
  if (isPrimitive(schemaItemDefaultValue)) return schemaItemDefaultValue;

  return schemaItemDefaultValue?.value ?? passedDefaultValue?.value;
};

function SelectField({
  fieldClassName,
  name,
  passedDefaultValue,
  schemaItem,
}: SelectFieldProps) {
  const [selectValue, setSelectValue] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isDirtyRef = useRef<boolean>(false);
  const { executeAction } = useContext(FormContext);
  const {
    field: { onChange, value },
  } = useController({
    name,
  });

  // Sync selectValue with form value
  useEffect(() => {
    setSelectValue(value?.toString() ?? "");
  }, [value]);

  const isValueValid = isValid(schemaItem, value);
  const options = Array.isArray(schemaItem.options) ? schemaItem.options : [];

  const defaultValue = composeDefaultValue(
    schemaItem.defaultValue,
    passedDefaultValue as DefaultValue,
  );

  useRegisterFieldValidity({
    isValid: isValueValid,
    fieldName: name,
    fieldType: schemaItem.fieldType,
  });

  const [updateFilterText] = useUpdateInternalMetaState({
    propertyName: `${name}.filterText`,
  });

  const onFilterChange = useCallback(
    (value: string) => {
      if (!schemaItem.onFilterUpdate) {
        updateFilterText(value);
      } else {
        updateFilterText(value, {
          triggerPropertyName: "onFilterUpdate",
          dynamicString: schemaItem.onFilterUpdate,
          event: {
            type: EventType.ON_FILTER_UPDATE,
          },
        });
      }
    },
    [executeAction, schemaItem.onFilterUpdate],
  );

  const selectedOptionIndex = options.findIndex(
    (option) => option.value === value,
  );
  const selectedIndex =
    selectedOptionIndex > -1 ? selectedOptionIndex : undefined;

  const onOptionSelected = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value;
      const selectedOption = options.find((option) => option.value === value);

      if (selectedOption) {
        // Update the selected value in the state, ensuring it's always a string
        setSelectValue(selectedOption.value?.toString() ?? "");

        // Call the original function logic
        onChange(selectedOption.value);

        if (!isDirtyRef.current) {
          isDirtyRef.current = true;
        }

        if (schemaItem.onOptionChange && executeAction) {
          executeAction({
            triggerPropertyName: "onOptionChange",
            dynamicString: schemaItem.onOptionChange,
            event: {
              type: EventType.ON_OPTION_CHANGE,
            },
            updateDependencyType: ActionUpdateDependency.ZFORM_DATA,
          });
        }
      }
    },
    [onChange, schemaItem.onOptionChange, executeAction, options],
  );

  const dropdownWidth = wrapperRef.current?.clientWidth;
  const fieldComponent = useMemo(
    () => (
      <StyledSelectWrapper ref={wrapperRef}>
        <Select
          a11yLabel="number"
          disabled={schemaItem.isDisabled}
          dsOnChange={onOptionSelected}
          error={isDirtyRef.current ? !isValueValid : false}
          filterable={schemaItem.isFilterable}
          fullWidth
          placeholder={schemaItem.placeholderText || "-- Select --"}
          sx={{
            color: schemaItem.accentColor || DEFAULT_PRIMARY_COLOR,
            borderRadius: schemaItem.borderRadius || DEFAULT_BORDER_RADIUS,
            boxShadow: schemaItem.boxShadow,
          }}
          value={selectValue}
        >
          {options.map((option, index) => (
            <SelectItem key={option.id || index} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
      </StyledSelectWrapper>
    ),
    [
      selectedOptionIndex,
      schemaItem.serverSideFiltering,
      schemaItem.placeholderText,
      schemaItem.accentColor,
      schemaItem.boxShadow,
      schemaItem.borderRadius,
      options,
      onFilterChange,
      schemaItem.isFilterable,
      schemaItem.isDisabled,
      isDirtyRef,
      wrapperRef,
      isValueValid,
      onOptionSelected,
      selectedIndex,
      dropdownWidth,
      fieldClassName,
      selectValue,
    ],
  );

  return (
    <Field
      accessor={schemaItem.accessor}
      defaultValue={defaultValue}
      fieldClassName={fieldClassName}
      isRequiredField={schemaItem.isRequired}
      label={schemaItem.label}
      labelStyle={schemaItem.labelStyle}
      labelTextColor={schemaItem.labelTextColor}
      labelTextSize={schemaItem.labelTextSize}
      name={name}
      tooltip={schemaItem.tooltip}
    >
      {fieldComponent}
    </Field>
  );
}

SelectField.componentDefaultValues = {
  ...COMPONENT_DEFAULT_VALUES,
  options: [
    { label: "Blue", value: "BLUE" },
    { label: "Green", value: "GREEN" },
    { label: "Red", value: "RED" },
  ],
};

export default SelectField;
