import styled from "styled-components";
import { Icon, Tab, Grid } from "zds";
import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
} from "@mui/lab";

const StyledFieldValue = styled.div`
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 0.15px;
  line-height: 21px;
  margin-bottom: 4px;
  font-size: 14px;
  word-break: break-word;
  padding-right: 25px;
`;

/*
  The icon with the suitable size to display in tabel field
*/
const StyledFieldIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  font-size: 14px !important;
  margin-right: 5px;
`;

/*
  The tab without outline style when focus
*/
const StyledTab = styled(Tab)`
  color: rgba(0, 0, 0, 0.4);
  &:focus {
    outline: none;
  }
`;

const StyledExternalLink = styled.a`
  cursor: pointer;
  color: #0d4ac3;
  font-size: 14px;
`;

const StyledDataRow = styled(Grid)`
  border-bottom: 1px solid #ccc;
  &:last-child {
    border-bottom: none !important;
  }
  padding: 20px 0;
`;

const StyledTimeline = styled(Timeline)`
  padding-top: 12px;
  width: 300px;
  max-height: 480px;
  li {
    &:last-child {
      min-height: 55px !important;
    }
  }
`;

const StyledTimeLineItem = styled(TimelineItem)`
  min-height: 57px !important;
  &::before {
    display: none;
  }
`;

const StyledTimelineContent = styled(TimelineContent)`
  padding-top: 0;
  font-size: 14px;
  /* font-family: Mulish; */
  color: rgba(0, 0, 0, 0.4);
  line-height: 1;
  margin-top: -5px;
  & p {
    margin-bottom: 5px !important;
  }
`;

const StyledTimeLineDot = styled(TimelineDot)<any>`
  border: 1px solid;
  margin: 0;
  padding: 0;
  width: 9px;
  height: 9px;
  box-shadow: none;
  background: ${({ statusinterval }) =>
    statusinterval?.isCurrent ? "rgba(0, 0, 0, 0.6);" : "#ffffff"};
`;

const StyledTimelineConnector = styled(TimelineConnector)<any>`
  border-left-width: 1px;
  background-color: unset !important;
  width: 0;
  border-left-style: inset;
  border-color: rgba(0, 0, 0, 0.12);
`;

export {
  StyledFieldValue,
  StyledFieldIcon,
  StyledTab,
  StyledDataRow,
  StyledExternalLink,
  StyledTimelineContent,
  StyledTimeLineItem,
  StyledTimeline,
  StyledTimeLineDot,
  StyledTimelineConnector,
};
