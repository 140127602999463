import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import type { Alignment } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";
import type { ButtonPlacement, ButtonVariant } from "components/constants";
import { ButtonPlacementTypes, ButtonVariantTypes } from "components/constants";
import {
  type AutoLayoutConfig,
  BlueprintOperationTypes,
} from "WidgetProvider/constants";
import type { AutocompletionDefinitions } from "WidgetProvider/constants";
import { clone, get } from "lodash";
import { ButtonType } from "widgets/ButtonWidget/component";
import SSOLoginComponent from "../component";
import styled from "styled-components";
import { Icon, Text } from "@appsmith/ads";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import type { SetterConfig } from "entities/AppTheming";

export const ErrorTextContainer = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 4px;
  > p {
    color: var(--ads-v2-color-fg-error);
  }

  svg {
    path {
      fill: var(--ads-v2-color-fg-error);
    }
  }
`;

class SSOLoginWidget extends BaseWidget<SSOLoginWidgetProps, WidgetState> {
  static type = "SSOLOGIN_WIDGET";

  static getConfig() {
    return {
      name: "SSO Login",
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      tags: [WIDGET_TAGS.ZUORA],
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getDefaults() {
    return {
      widgetName: "SSOLogin",
      rows: 4,
      columns: 16,
      version: 1,
      isVisible: true,
      loginButtons: {
        loginButton1: {
          label: "Login with Google",
          id: "loginButton1",
          widgetId: "",
          enabledLoginType: "GOOGLE",
          buttonVariant: ButtonVariantTypes.PRIMARY,
          placement: "CENTER",
          isDisabled: false,
          isVisible: true,
          index: 0,
          borderRadius: "0.375rem",
          boxShadow: "none",
          marginTop: 0,
        },
      },
      blueprint: {
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              const loginButtons = clone(widget.loginButtons);
              const dynamicBindingPathList: any[] = get(
                widget,
                "dynamicBindingPathList",
                [],
              );

              Object.keys(loginButtons).map((groupButtonKey) => {
                loginButtons[groupButtonKey].buttonColor = get(
                  widget,
                  "childStylesheet.button.buttonColor",
                  "{{appsmith.theme.colors.primaryColor}}",
                );

                dynamicBindingPathList.push({
                  key: `loginButtons.${groupButtonKey}.buttonColor`,
                });
              });

              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "dynamicBindingPathList",
                  propertyValue: dynamicBindingPathList,
                },
                {
                  widgetId: widget.widgetId,
                  propertyName: "loginButtons",
                  propertyValue: loginButtons,
                },
              ];

              return updatePropertyMap;
            },
          },
        ],
      },
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setDisabled: {
          path: "isDisabled",
          type: "boolean",
        },
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "loginButtons",
            controlType: "SSO_GROUP_BUTTONS",
            label: "Login buttons",
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              contentChildren: [
                {
                  sectionName: "Data",
                  children: [
                    {
                      propertyName: "enabledLoginType",
                      label: "SSO provider",
                      controlType: "DROP_DOWN",
                      helpText: "Sets the available login methods",
                      options: [
                        {
                          label: "Google",
                          value: "GOOGLE",
                        },
                        {
                          label: "LinkedIn",
                          value: "LINKED_IN",
                        },
                        {
                          label: "Microsoft",
                          value: "MICROSOFT",
                        },
                      ],
                      isJSConvertible: false,
                      isBindProperty: true,
                      isTriggerProperty: false,
                    },
                  ],
                },
                {
                  sectionName: "Label",
                  children: [
                    {
                      propertyName: "label",
                      helpText: "Sets the text of the login button",
                      label: "Text",
                      controlType: "INPUT_TEXT",
                      placeholderText: "Enter label",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                  ],
                },
                {
                  sectionName: "General",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "Visible",
                      helpText: "Controls the visibility of the widget",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                    {
                      propertyName: "isDisabled",
                      helpText: "Disables input to the widget",
                      label: "Disabled",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                  ],
                },
              ],
              styleChildren: [
                {
                  sectionName: "Variant",
                  children: [
                    {
                      propertyName: "buttonVariant",
                      label: "Button variant",
                      controlType: "ICON_TABS",
                      fullWidth: true,
                      helpText: "Sets the variant of the button",
                      options: [
                        {
                          label: "Primary",
                          value: ButtonVariantTypes.PRIMARY,
                        },
                        {
                          label: "Secondary",
                          value: ButtonVariantTypes.SECONDARY,
                        },
                        {
                          label: "Tertiary",
                          value: ButtonVariantTypes.TERTIARY,
                        },
                      ],
                      defaultValue: ButtonVariantTypes.PRIMARY,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: [
                            ButtonVariantTypes.PRIMARY,
                            ButtonVariantTypes.SECONDARY,
                            ButtonVariantTypes.TERTIARY,
                          ],
                          default: ButtonVariantTypes.PRIMARY,
                        },
                      },
                    },
                  ],
                },
                {
                  sectionName: "Icon",
                  children: [
                    {
                      propertyName: "iconName",
                      label: "Icon",
                      helpText: "Sets the icon to be used for a button",
                      controlType: "ICON_SELECT",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    {
                      propertyName: "iconAlign",
                      label: "Position",
                      helpText: "Sets the icon alignment of a button",
                      controlType: "ICON_TABS",
                      fullWidth: false,
                      options: [
                        {
                          startIcon: "skip-left-line",
                          value: "left",
                        },
                        {
                          startIcon: "skip-right-line",
                          value: "right",
                        },
                      ],
                      defaultValue: "left",
                      isBindProperty: false,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    {
                      propertyName: "placement",
                      label: "Placement",
                      controlType: "DROP_DOWN",
                      helpText: "Sets the space between items",
                      options: [
                        {
                          label: "Start",
                          value: ButtonPlacementTypes.START,
                        },
                        {
                          label: "Between",
                          value: ButtonPlacementTypes.BETWEEN,
                        },
                        {
                          label: "Center",
                          value: ButtonPlacementTypes.CENTER,
                        },
                      ],
                      defaultValue: ButtonPlacementTypes.CENTER,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: [
                            ButtonPlacementTypes.START,
                            ButtonPlacementTypes.BETWEEN,
                            ButtonPlacementTypes.CENTER,
                          ],
                          default: ButtonPlacementTypes.CENTER,
                        },
                      },
                    },
                  ],
                },
                {
                  sectionName: "Color",
                  children: [
                    {
                      // getStylesheetValue,
                      propertyName: "buttonColor",
                      helpText: "Changes the color of the button",
                      label: "Button color",
                      controlType: "COLOR_PICKER",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                  ],
                },
                {
                  sectionName: "Border and shadow",
                  children: [
                    {
                      propertyName: "borderRadius",
                      label: "Border radius",
                      helpText:
                        "Rounds the corners of the icon button's outer border edge",
                      controlType: "BORDER_RADIUS_OPTIONS",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    {
                      propertyName: "boxShadow",
                      label: "Box shadow",
                      helpText:
                        "Enables you to cast a drop shadow from the frame of the widget",
                      controlType: "BOX_SHADOW_OPTIONS",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                  ],
                },
              ],
            },
          },
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return () => ({
      "!doc":
        "Switch is a simple UI widget you can use when you want users to make a binary choice",
      "!url": "https://docs.appsmith.com/widget-reference/switch",
      responseData: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      isDisabled: "bool",
    });
  }

  getWidgetView() {
    const queryParams = new URLSearchParams(location.search);
    let showError = false;
    let errorMessage = "";
    if (queryParams.get("error")) {
      errorMessage =
        queryParams.get("message") || queryParams.get("error") || "";
      showError = true;
    }
    return (
      <div>
        {Object.values(this.props.loginButtons).map(
          (button) =>
            button.isVisible && (
              <div
                key={button.widgetId}
                style={{ marginTop: `${button.marginTop}px` }}
              >
                <SSOLoginComponent
                  borderRadius={button.borderRadius}
                  boxShadow={button.boxShadow}
                  buttonColor={button.buttonColor}
                  buttonVariant={button.buttonVariant}
                  iconAlign={button.iconAlign}
                  iconName={button.iconName}
                  isDisabled={button.isDisabled}
                  isLoading={this.props.isLoading}
                  key={button.widgetId}
                  loginType={button.enabledLoginType}
                  maxWidth={this.props.maxWidth}
                  minHeight={this.props.minHeight}
                  minWidth={this.props.minWidth}
                  placement={button.placement}
                  shouldFitContent={this.isAutoLayoutMode}
                  text={button.label}
                  type={ButtonType.BUTTON}
                  widgetId={button.widgetId}
                />
              </div>
            ),
        )}
        {showError && (
          <ErrorTextContainer>
            <Icon name="alert-line" size="sm" />
            <Text kind="body-s" renderAs="p">
              {errorMessage}
            </Text>
          </ErrorTextContainer>
        )}
      </div>
    );
  }
}

export interface SSOLoginWidgetProps extends WidgetProps {
  isVisible?: boolean;
  loginButtons: Record<
    string,
    {
      widgetId: string;
      id: string;
      index: number;
      isDisabled?: boolean;
      isVisible?: boolean;
      label?: string;
      buttonVariant: ButtonVariant;
      enabledLoginType?: string;
      buttonColor?: string;
      iconName?: IconName;
      iconAlign?: Alignment;
      placement?: ButtonPlacement;
      borderRadius?: string;
      boxShadow?: string;
      marginTop?: number;
      appId?: string;
    }
  >;
}

export default SSOLoginWidget;
