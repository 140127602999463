import type { Moment } from "moment-timezone";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import DateFormatMap from "./DateFormatMap";

export const convertToTenantTZ = (
  date: any,
  timezone: string,
): moment.Moment => {
  return moment(date)
    .utc()
    .tz(timezone || "America/Los_Angeles");
};

export const formatDateByLocale = (date: any, locale?: string): string => {
  return <string>formatDate(locale || "en_US")(date);
};

export const formatDate =
  (locale: string) =>
  (date: string | Moment | DateTime): string | undefined => {
    try {
      if (!date) return "-";
      if (typeof date === "string") date = moment(date);
      if (moment.isMoment(date)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return date.format(DateFormatMap[locale] || "MM/DD/YYYY");
      } else if (DateTime.isDateTime(date)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const luxonDateFormat = (DateFormatMap[locale] || "MM/DD/YYYY")
          .replace("YYYY", "yyyy")
          .replace("DD", "dd");
        return date.toFormat(luxonDateFormat);
      }
    } catch (e) {
      return "Invalid Date";
    }
  };
