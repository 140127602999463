import React from "react";
import type { BaseCellComponentProps } from "../Constants";
import { CellWrapper } from "../TableStyledWrappers";
import { PageHeaderActions } from "../../../PageHeaderWidget/component/PageHeaderActions";
import type { HeaderActionItem } from "../../../PageHeaderWidget/constants";

interface RenderIconButtonProps extends BaseCellComponentProps {
  isSelected: boolean;
  arrayItems: Record<string, HeaderActionItem>;
  maxActionDisplay?: number;
  rowIndex: number;
  onCommandClick: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
}

export function ActionArrayCell(props: RenderIconButtonProps) {
  const {
    allowCellWrapping,
    arrayItems,
    cellBackground,
    compactMode,
    fontStyle,
    horizontalAlignment,
    isCellDisabled,
    isCellVisible,
    isHidden,
    // isSelected,
    maxActionDisplay,
    onCommandClick,
    // rowIndex,
    textColor,
    textSize,
    verticalAlignment,
  } = props;

  return (
    <CellWrapper
      allowCellWrapping={allowCellWrapping}
      cellBackground={cellBackground}
      compactMode={compactMode}
      fontStyle={fontStyle}
      horizontalAlignment={horizontalAlignment}
      isCellDisabled={isCellDisabled}
      isCellVisible={isCellVisible}
      isHidden={isHidden}
      textColor={textColor}
      textSize={textSize}
      verticalAlignment={verticalAlignment}
    >
      {arrayItems && (
        <PageHeaderActions
          actions={arrayItems}
          buttonClickHandler={onCommandClick}
          handleOnchange={() => {}}
          isSmallSize
          maxHeaderActionDisplay={maxActionDisplay}
        />
      )}
    </CellWrapper>
  );
}
