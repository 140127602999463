import { ValidationTypes } from "constants/WidgetValidation";
import { get } from "lodash";
import { ButtonVariantTypes } from "widgets/ZButtonWidget/constants";
import type { PageHeaderWidgetProps } from "widgets/PageHeaderWidget/constants";
import type { WidgetProps } from "widgets/BaseWidget";
import { getBasePropertyPath } from "widgets/TableWidget/widget/propertyUtils";

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  updateHook: (props: any, propertyPath: string, propertyValue: string) => {
    return [
      {
        propertyPath,
        propertyValue,
      },
    ];
  },
  contentChildren: [
    {
      sectionName: "Label",
      children: [
        {
          propertyName: "label",
          helpText: "Sets the label of a menu item",
          label: "Text",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter label",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "icon",
          label: "Icon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "startIcon",
          label: "StartIcon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
        {
          propertyName: "endIcon",
          label: "EndIcon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
        },
      ],
    },
    {
      sectionName: "General",
      children: [
        {
          propertyName: "isVisible",
          helpText: "Controls the visibility of the widget",
          label: "Visible",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "isDisabled",
          helpText: "Disables input to the widget",
          label: "Disabled",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "divider",
          helpText: "Divider with next button in menu dropdown",
          label: "Divider",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.BOOLEAN,
          },
        },
      ],
    },
    {
      sectionName: "Events",
      children: [
        {
          propertyName: "onClick",
          label: "onClick",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
          hidden: (
            props: PageHeaderWidgetProps<WidgetProps>,
            propertyPath: string,
          ) => {
            const property = getBasePropertyPath(propertyPath);
            const actionType = get(props, `${property}.actionType`, "");
            return ["GROUP_BUTTONS"].includes(actionType);
          },
          dependencies: ["actionType"],
        },
      ],
    },
  ],
  styleChildren: [
    {
      sectionName: "General",
      children: [
        {
          propertyName: "variant",
          label: "Button variant",
          controlType: "ICON_TABS",
          fullWidth: true,
          helpText: "Sets the variant of the button",
          options: [
            {
              label: "Contained",
              value: ButtonVariantTypes.CONTAINED,
            },
            {
              label: "Outlined",
              value: ButtonVariantTypes.OUTLINED,
            },
            {
              label: "Text",
              value: ButtonVariantTypes.TEXT,
            },
          ],
          defaultValue: ButtonVariantTypes.CONTAINED,
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: [
                ButtonVariantTypes.CONTAINED,
                ButtonVariantTypes.TEXT,
                ButtonVariantTypes.TERTIARY,
                ButtonVariantTypes.OUTLINED,
              ],
              default: ButtonVariantTypes.CONTAINED,
            },
          },
        },
        {
          propertyName: "buttonColor",
          helpText: "Sets the buttonColor",
          label: "buttonColor",
          controlType: "COLOR_PICKER",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    {
      sectionName: "Border and shadow",
      children: [
        {
          propertyName: "borderRadius",
          label: "Border radius",
          helpText: "Rounds the corners of the icon button's outer border edge",
          controlType: "BORDER_RADIUS_OPTIONS",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          defaultValue: "none",
        },
        {
          propertyName: "boxShadow",
          label: "Box shadow",
          helpText:
            "Enables you to cast a drop shadow from the frame of the widget",
          controlType: "BOX_SHADOW_OPTIONS",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          defaultValue: "none",
        },
      ],
    },
  ],
};
