import { ValidationTypes } from "constants/WidgetValidation";
import { FieldType } from "widgets/ZJsonSummaryWidget/constants";
import type { HiddenFnParams } from "../helper";
import { getSchemaItem } from "../helper";
import { dateFormatOptions } from "WidgetProvider/constants";

const PROPERTIES = {
  content: {
    data: [
      {
        helpText: "Source date field format.",
        propertyName: "defaultFormat",
        label: "Default format",
        controlType: "DROP_DOWN",
        isJSConvertible: true,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        optionWidth: "340px",
        options: dateFormatOptions,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hideSubText: true,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATE),
        dependencies: ["schema"],
      },
      {
        helpText: "Format in which the date needs to be displayed.",
        propertyName: "dateFormat",
        label: "Date format",
        controlType: "DROP_DOWN",
        isJSConvertible: true,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        optionWidth: "340px",
        options: dateFormatOptions,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hideSubText: true,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATE),
        dependencies: ["schema"],
      },
    ],
    general: [
      {
        propertyName: "shortcuts",
        label: "Show Shortcuts",
        helpText: "Choose to show shortcut menu",
        controlType: "SWITCH",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.DATE),
        dependencies: ["schema"],
      },
    ],
  },
};

export default PROPERTIES;
