import React from "react";
import type { ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
import type {
  BaseItemProps as DroppableItem,
  RenderComponentProps,
} from "./DraggableListComponent";
import orderBy from "lodash/orderBy";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import includes from "lodash/includes";
import map from "lodash/map";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { DraggableListControl } from "pages/Editor/PropertyPane/DraggableListControl";
import { DraggableListCard } from "components/propertyControls/DraggableListCard";
import { Button, Tag } from "@appsmith/ads";

function AddStepButtonComponent({ widgetId }: any) {
  const dispatch = useDispatch();
  const addOption = () => {
    dispatch({
      type: ReduxActionTypes.WIDGET_ADD_NEW_STEP_CHILD,
      payload: {
        widgetId,
      },
    });
  };
  return (
    <Button
      className="self-end t--add-tab-btn"
      kind="tertiary"
      onClick={addOption}
      size="sm"
      startIcon="plus"
    >
      Add Step
    </Button>
  );
}

function StepperControlComponent(props: RenderComponentProps<DroppableItem>) {
  const { index, item } = props;
  const dispatch = useDispatch();
  const deleteOption = () => {
    dispatch({
      type: ReduxActionTypes.WIDGET_DELETE_STEP_CHILD,
      payload: { ...item, index },
    });
    if (props.deleteOption) props.deleteOption(index);
  };

  return (
    <DraggableListCard
      {...props}
      deleteOption={deleteOption}
      isDelete
      placeholder="Step title"
    />
  );
}

interface State {
  focusedIndex: number | null;
  duplicateStepIds: string[];
}

class StepperControl extends BaseControl<ControlProps, State> {
  constructor(props: ControlProps) {
    super(props);

    this.state = {
      focusedIndex: null,
      duplicateStepIds: this.getDuplicateStepIds(props.propertyValue),
    };
  }

  getDuplicateStepIds = (propertyValue: ControlProps["propertyValue"]) => {
    const duplicateStepIds = [];
    const stepIds = Object.keys(propertyValue);
    const stepNames = map(propertyValue, "label");

    for (let index = 0; index < stepNames.length; index++) {
      const currLabel = stepNames[index] as string;
      const duplicateValueIndex = stepNames.indexOf(currLabel);
      if (duplicateValueIndex !== index) {
        // get tab id from propertyValue index
        duplicateStepIds.push(propertyValue[stepIds[index]].id);
      }
    }

    return duplicateStepIds;
  };

  componentDidMount() {
    this.migrateStepData(this.props.propertyValue);
  }

  componentDidUpdate(prevProps: ControlProps): void {
    //on adding a new column last column should get focused
    if (
      Object.keys(prevProps.propertyValue).length + 1 ===
      Object.keys(this.props.propertyValue).length
    ) {
      this.updateFocus(Object.keys(this.props.propertyValue).length - 1, true);
    }
  }

  migrateStepData(
    stepData: Array<{
      id: string;
      label: string;
    }>,
  ) {
    // Added a migration script for older tab data that was strings
    // deprecate after enough tabs have moved to the new format
    if (isString(stepData)) {
      try {
        const parsedData: Array<{
          sid: string;
          label: string;
        }> = JSON.parse(stepData);
        this.updateProperty(this.props.propertyName, parsedData);
        return parsedData;
      } catch (error) {
        Sentry.captureException({
          message: "Step Migration Failed",
          oldData: this.props.propertyValue,
        });
      }
    } else {
      return this.props.propertyValue;
    }
  }

  getStepItems = () => {
    let menuItems: Array<{
      id: string;
      label: string;
      isVisible?: boolean;
      isDuplicateLabel?: boolean;
    }> =
      isString(this.props.propertyValue) ||
      isUndefined(this.props.propertyValue)
        ? []
        : Object.values(this.props.propertyValue);
    menuItems = orderBy(menuItems, ["index"], ["asc"]);
    menuItems = menuItems.map((step: DroppableItem) => ({
      ...step,
      isDuplicateLabel: includes(this.state.duplicateStepIds, step.id),
    }));
    return menuItems;
  };

  updateItems = (items: Array<Record<string, any>>) => {
    const stepperData = items.reduce((obj: any, each: any, index: number) => {
      obj[each.id] = {
        ...each,
        index,
      };
      return obj;
    }, {});
    this.updateProperty(this.props.propertyName, stepperData);
  };

  onEdit = (index: number) => {
    const steps = this.getStepItems();
    const stepToChange = steps[index];
    this.props.openNextPanel({
      index,
      ...stepToChange,
      propPaneId: this.props.widgetProperties.widgetId,
    });
  };
  render() {
    const steps = this.getStepItems();
    return (
      <div className="flex flex-col">
        <div className="t--number-of-tabs mb-1 ml-auto">
          <Tag isClosable={false}>{steps.length}</Tag>
        </div>
        <DraggableListControl
          deleteOption={this.deleteOption}
          fixedHeight={370}
          focusedIndex={this.state.focusedIndex}
          itemHeight={45}
          items={steps}
          onEdit={this.onEdit}
          propertyPath={this.props.dataTreePath}
          renderComponent={StepperControlComponent}
          toggleVisibility={this.toggleVisibility}
          updateFocus={this.updateFocus}
          updateItems={this.updateItems}
          updateOption={this.updateOption}
        />
        <AddStepButtonComponent
          widgetId={this.props.widgetProperties.widgetId}
        />
      </div>
    );
  }

  toggleVisibility = (index: number) => {
    const steps = this.getStepItems();
    const isVisible = steps[index].isVisible === true ? false : true;
    const updatedSteps = steps.map((step, stepIndex) => {
      if (index === stepIndex) {
        return {
          ...step,
          isVisible: isVisible,
        };
      }
      return step;
    });
    this.updateProperty(this.props.propertyName, updatedSteps);
  };

  deleteOption = (index: number) => {
    const stepIds = Object.keys(this.props.propertyValue);
    const newPropertyValue = { ...this.props.propertyValue };
    // detele current item from propertyValue
    delete newPropertyValue[stepIds[index]];
    const duplicateStepIds = this.getDuplicateStepIds(newPropertyValue);
    this.setState({ duplicateStepIds });
  };

  updateOption = (index: number, updatedLabel: string) => {
    const stepsArray = this.getStepItems();
    const { id: itemId } = stepsArray[index];
    this.updateProperty(
      `${this.props.propertyName}.${itemId}.label`,
      updatedLabel,
    );
    // check entered label is unique or duplicate
    const stepNames = map(stepsArray, "label");
    let duplicateStepIds = [...this.state.duplicateStepIds];
    // if duplicate, add into array
    if (includes(stepNames, updatedLabel)) {
      duplicateStepIds.push(itemId);
      this.setState({ duplicateStepIds });
    } else {
      duplicateStepIds = duplicateStepIds.filter((id) => id !== itemId);
      this.setState({ duplicateStepIds });
    }
  };

  updateFocus = (index: number, isFocused: boolean) => {
    this.setState({ focusedIndex: isFocused ? index : null });
  };

  static getControlType() {
    return "STEPPER_INPUT";
  }
}

export default StepperControl;
