import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import BaseWidget, {
  type WidgetProps,
  type WidgetState,
} from "widgets/BaseWidget";

import AppBarComponent from "../component";

import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "../../../constants/WidgetConstants";
import actionConfig from "./propertyConfig/actionConfig";
import type { ActionItem } from "../constants";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { sortBy } from "lodash";
import type { Stylesheet } from "../../../entities/AppTheming";
class AppBarWidget extends BaseWidget<AppBarWidgetProps, WidgetState> {
  static type = "APPBAR_WIDGET";

  static getConfig() {
    return {
      name: "App Bar",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.DISPLAY],
      needsMeta: false,
      isCanvas: false,
    };
  }

  // static getFeatures() {
  //   return {
  //     dynamicHeight: {
  //       sectionIndex: 0,
  //       active: false,
  //     },
  //   };
  // }

  static getDefaults() {
    return {
      widgetName: "AppBar",
      rows: 15,
      columns: 100,
      version: 1,
      title: "App Bar",
      backgroundColor: "{{theme.colors.primary}}",
      imageUrl:
        "https://companieslogo.com/img/orig/ZUO-b984fcf6.png?t=1720244494",
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "leftActions",
            label: "Left Actions",
            controlType: "APPBAR_ACTION",
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: actionConfig,
          },
          {
            propertyName: "rightActions",
            label: "Right Actions",
            controlType: "APPBAR_ACTION",
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: actionConfig,
          },
          {
            propertyName: "isVisible",
            helpText: "Controls the visibility of the widget",
            label: "Visible",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "Color",
        children: [
          {
            propertyName: "backgroundColor",
            label: "Background Color",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
        ],
      },
      {
        sectionName: "Border and shadow",
        children: [
          {
            propertyName: "borderRadius",
            label: "Border radius",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            defaultValue: "none",
          },
          {
            propertyName: "boxShadow",
            label: "Box shadow",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            defaultValue: "none",
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  onButtonClick = (onClick: string | undefined) => {
    if (onClick) {
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }
  };

  getVisibleActions = (actions: Record<string, ActionItem>) => {
    if (!actions) return {};

    let items = Object.keys(actions)
      .map((itemKey) => actions[itemKey])
      .filter((item) => item.isVisible === true);
    // sort btns by index
    items = sortBy(items, ["index"]);
    return items;
  };

  getWidgetView() {
    return (
      <AppBarComponent
        {...this.props}
        height={this.props.componentHeight}
        leftActions={this.getVisibleActions(this.props.leftActions)}
        onButtonClick={this.onButtonClick}
        rightActions={this.getVisibleActions(this.props.rightActions)}
      />
    );
  }
}

export interface AppBarWidgetProps extends WidgetProps {
  leftActions: Record<string, ActionItem>;
  rightActions: Record<string, ActionItem>;
  backgroundColor: string;
}

export default AppBarWidget;
