// Write your constant content here
import type { WidgetProps } from "../BaseWidget";
import { type AllIconsType, MaterialIcons, ZuoraIcons } from "zds";
const NONE = "(none)";
type IconType = AllIconsType | typeof NONE;

export const ZACCORDION_WIDGET_CONSTANT = "";

export enum MenuItemsSource {
  STATIC = "STATIC",
  DYNAMIC = "DYNAMIC",
}

export interface ConfigureMenuItems {
  label: string;
  id: string;
  config: MenuItem;
}

export interface MenuItem {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  divider?: boolean;
  label?: string;
  icon?: AllIconsType;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  onClick?: string;
  href?: string;
}

export interface HeaderActionItem {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  divider?: boolean;
  label: string;
  actionType?: string;
  variant?: string;
  buttonColor?: string;
  icon?: AllIconsType;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  onClick?: string;
  onChange?: string;
  value?: any;
  href?: string;
  options?: { [key: string]: any }[];
  defaultSelectedOption?: string | Array<string>;
  selectWidth?: number;
  menuItems: Record<string, MenuItem>;
  menuItemsSource?: MenuItemsSource;
  configureMenuItems?: ConfigureMenuItems;
  sourceData?: Array<Record<string, unknown>>;
  optionLabel?: string | number;
  optionValue?: string | number;
  borderRadius?: string;
  boxShadow?: string;
  labelTextSize?: string;
  labelStyle?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start";
}

export interface ZAccordionWidgetProps extends WidgetProps {
  children?: WidgetProps[];
  defaultExpanded: boolean;
  disabled: boolean;
  disableGutters: boolean;
  titleBar: boolean;
  headerAction?: Record<string, HeaderActionItem>;
  isExpanded: boolean;
  selectedTabWidgetId: string;
}
export const ICON_NAMES: IconType[] = [
  ...(ZuoraIcons as IconType[]),
  ...(MaterialIcons as IconType[]),
];
